import React, {useContext, useState, useEffect, useCallback} from 'react';
import {View, Pressable, Image, Platform} from 'react-native';
import {Text, HStack, ScrollView} from 'native-base';
import {Card, Avatar, Badge, DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import {
  nameToFio,
  formInitials,
  formatPhoneNumber,
} from '../../components/common/commonFunctions';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import {default as commonStylesMobile} from '../../styles/common_mobile';
import {default as commonStylesDesktop} from '../../styles/common';
import {default as listStylesF} from '../../styles/list';
import {default as invitationsStylesDesktop} from '../../styles/invitations';
import {default as invitationsStylesMobile} from '../../styles/invitations_mobile';

import Config from '../../config/config';
import IOSCustomPagination from '../../components/common/IOSCustomPagination';

const ReferalInfoCard = ({referal, orders}) => {
  const {t, theme, screenWidth} = useContext(AuthContext);
  const invitationsStyles =
    screenWidth < 1080
      ? invitationsStylesMobile(wp, hp, theme)
      : invitationsStylesDesktop(wp, hp, theme);
  if (!referal) {
    return;
  }
  const LeftContent = () => (
    <Avatar.Text
      label={formInitials(referal.name)}
      size={invitationsStyles.cardAvatar.width}
      style={invitationsStyles.cardAvatarStyle}
      theme={invitationsStyles.cardAvatarTheme}
    />
  );

  return (
    <Card mode={'contained'} style={invitationsStyles.card}>
      <Card.Title
        title={nameToFio(referal.name)}
        subtitle={
          referal.phone ? formatPhoneNumber(referal.phone) : referal.email
        }
        left={LeftContent}
        titleStyle={invitationsStyles.cardTitleStyle}
        subtitleStyle={invitationsStyles.cardSubtitleStyle}
      />
    </Card>
  );
};

const ReferalDetails = ({referalId, navigation, route}) => {
  const {t, user, logout, screenWidth, theme, addHistory, currency} = useContext(AuthContext);

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobile(wp, hp, theme)
      : commonStylesDesktop(wp, hp, theme);
  const listStyles = listStylesF(theme);
  const invitationsStyles =
    screenWidth < 1080
      ? invitationsStylesMobile(wp, hp, theme)
      : invitationsStylesDesktop(wp, hp, theme);

  // const ReferalInfo = screenWidth < 700 ? ReferalInfoCard : ReferalInfoTable;
  const ReferalInfo = ReferalInfoCard;

  const [orders, setOrders] = useState([]);
  const [referal, setReferal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);

  const dateSort = direction => {
    direction === 'desc' ? setSortDirection('asc') : setSortDirection('desc');
  };

  const getOrders = useCallback(async () => {
    setIsLoading(true);
    const api = AxiosInstance({user, logout});
    const url = `${Config.API_URL}/api/v1/trios/orders/${referalId}?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`;
    await api(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        let _from = 0;
        let _to = 0;
        setOrders(response.data.message.data);
        setPagination(response.data.message.pagination);
        _from =
          response.data.message.pagination.totalRows > 0
            ? (currentPage - 1) * response.data.message.pagination.perPage + 1
            : 0;
        _to =
          response.data.message.pagination.totalRows >
          currentPage * response.data.message.pagination.perPage
            ? currentPage * response.data.message.pagination.perPage
            : response.data.message.pagination.totalRows;
        setFrom(_from);
        setTo(_to);
      })
      .catch(error => {
        setIsLoading(false);
        setIsError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, user, referalId, logout, sortField, sortDirection]);

  const getReferal = useCallback(async () => {
    setIsLoading(true);
    const api = AxiosInstance({user, logout});
    const url = `${Config.API_URL}/api/v1/trios/referal/${referalId}`;
    await api(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        setReferal(response.data.message);
      })
      .catch(error => {
        setIsLoading(false);
        setIsError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [referalId, user, logout]);

  useEffect(() => {
    const waitReferal = async () => {
      await getReferal();
    };

    const waitOrders = async () => {
      await getOrders();
    };
    if (referalId !== undefined) {
      waitReferal();
      waitOrders();
    }
  }, [getReferal, getOrders, referalId]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory('referal_details');
    }
  }, [addHistory]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={invitationsStyles.headerContainer}>
            <Pressable
              style={invitationsStyles.headBackButton}
              onPress={() => navigation.navigate('Invitations')}>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={invitationsStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={invitationsStyles.arrowLeft} />
              )}
            </Pressable>
            <View style={invitationsStyles.contentHead}>
              <Text style={invitationsStyles.contentHeadText}>
                {t('invite_details')}
              </Text>
            </View>
          </View>

          <ReferalInfo referal={referal} orders={orders} />

          {/* <View style={invitationsStyles.ordersHeader}>
            <Text style={invitationsStyles.ordersText}>{t('orders_list')}</Text>
          </View> */}

          <DataTable style={{...listStyles.dataTable, maxWidth: 600}}>
            <DataTable.Header style={listStyles.itemsHeader}>
              <DataTable.Title
                sortDirection={
                  sortDirection === 'desc' ? 'descending' : 'ascending'
                }
                onPress={() => {
                  dateSort(sortDirection);
                }}>
                <HStack
                  style={{
                    ...listStyles.itemsHeaderText,
                    ...(Platform.OS === 'ios' ? {alignItems: 'center'} : {}),
                  }}>
                  <Text style={{...listStyles.itemsHeaderText, marginLeft: 5}}>
                    {t('date')}
                  </Text>
                </HStack>
              </DataTable.Title>
              <DataTable.Title>
                <Text style={listStyles.itemsHeaderText}>{t('sum')}</Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text style={listStyles.itemsHeaderText}>{t('trio')}</Text>
              </DataTable.Title>
            </DataTable.Header>

            {orders.map(item => (
              <DataTable.Row key={item.id} style={listStyles.itemRow}>
                <DataTable.Cell style={listStyles.itemCol}>
                  <Text style={listStyles.itemCol}>
                    {item.created_at.substr(0, 10)}
                  </Text>
                </DataTable.Cell>
                <DataTable.Cell style={listStyles.itemCol}>
                  <Text style={listStyles.itemCol}>
                    {item.total_sum} {t(currency)}
                  </Text>
                </DataTable.Cell>
                <DataTable.Cell style={listStyles.itemCol}>
                  <Text style={listStyles.itemCol}>{item.trio_id}</Text>
                </DataTable.Cell>
              </DataTable.Row>
            ))}

            {Platform.OS === 'ios' ? (
              // Кастомна пагінація і рендеринг даних для iOS
              <View style={listStyles.dataTable}>
                <IOSCustomPagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(pagination.totalPages)}
                  onPageChange={newPage => setCurrentPage(newPage)}
                  label={`${from}-${to} of ${pagination.totalRows}`}
                />
              </View>
            ) : (
              // Стандартна пагінація для інших платформ
              <DataTable.Pagination
                page={currentPage - 1}
                numberOfPages={Math.ceil(pagination.totalPages)}
                onPageChange={page => setCurrentPage(page + 1)}
                label={`${from}-${to} of ${pagination.totalRows}`}
                numberOfItemsPerPage={pagination.perPage}
                showFastPaginationControls
              />
            )}
          </DataTable>
        </View>
        <Footer navigation={navigation}/>
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </ScrollView>
    );
  }
};

export default ReferalDetails;
