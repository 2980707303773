import React, {useContext, useEffect, useState} from 'react';
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  ActivityIndicator,
} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';

import CurrencyPicker from '../../components/common/CurrencyPicker';
import Header from '../../components/common/AdminHeader';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';

import commonStylesF from '../../styles/common';

import Config from '../../config/config';
import theme from '../../config/theme_config';

const CommonSettings = ({navigation, route}) => {
  const {user, logout, t, theme} = useContext(AuthContext);
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState(null);

  const commonStyles = commonStylesF(wp, hp, theme);

  const setCurrency = value => {
    setSettings(prevValue => {
      return {...prevValue, currency: {...prevValue.currency, value: value}};
    });
  };

  const saveSettings = async () => {
    console.log('settings:', settings);
    for (let item in settings) {
      if (!settings[item].value) {
        setIsError(t('empty_item_value') + ' ' + t(item));
        return false;
      }
    }
    setIsLoading(true);
    try {
      const api = AxiosInstance({user, logout});
      const response = await api(`${Config.API_URL}/api/v1/settings`, {
        method: 'PUT',
        mode: 'cors',
        data: settings,
      });
      if (response.data.status === 'success') {
        console.log('settingssaving response: ', response.data.message);
        setSettings(response.data.message);
      } else {
        setIsError(JSON.stringify(response.data));
      }
    } catch (error) {
      setIsError(t('server_error'));
      console.log('ERROR', error);
    } finally {
      setIsLoading(false);
    }
  };

  // загрузка настроек
  useEffect(() => {
    const getSettings = async () => {
      setIsLoading(true);
      try {
        const api = AxiosInstance({user, logout});
        const response = await api(`${Config.API_URL}/api/v1/settings`, {
          method: 'GET',
          mode: 'cors',
        });
        if (response.data.status === 'success') {
          console.log('settings currency: ', response.data.message);
          setSettings(response.data.message);
        } else {
          setIsError(JSON.stringify(response.data));
        }
      } catch (error) {
        setIsError(t('server_error'));
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getSettings();
  }, [user, logout, t]);

  return (
    <View style={commonStyles.container}>
      <Header navigation={navigation} />
      <View style={commonStyles.contentContainer}>
        <View style={commonStyles.contentHead}>
          <Text style={commonStyles.contentHeadText}>
            {t('admin_settings')}
          </Text>
        </View>
        <View style={styles.itemContainer}>
          <Text style={styles.itemCaption}>{t('currency')}</Text>
          <CurrencyPicker
            selectedCurrency={
              settings.currency !== undefined ? settings.currency.value : 0
            }
            setSelectedCurrency={setCurrency}
          />
        </View>
        <View style={commonStyles.btnGroup}>
          <Pressable
            onPress={() => saveSettings()}
            style={{...commonStyles.btnCommonBig, width: 220}}>
            <Text style={commonStyles.btnCommonTxtBig}>{t('save')}</Text>
          </Pressable>
        </View>
      </View>
      {isLoading === true && (
        <ActivityIndicator
          style={styles.activityIndicator}
          color="#0C090D"
          size="large"
        />
      )}
      {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      {info && <InfoModal info={info} setInfo={setInfo} />}
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemCaption: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    fontSize: 18,
  },
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
});

export default CommonSettings;
