import React, {useContext, useState, useEffect} from "react";
import { View, Text, StyleSheet, Pressable, Platform, Image, ActivityIndicator } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {Picker} from '@react-native-picker/picker';

import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from "../../components/common/axiosInstance";
import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import Header from '../../components/common/AdminHeader';
import Footer from "../../components/common/Footer";
import ErrorModal from "../../components/common/ErrorModal";
import InfoModal from "../../components/common/InfoModal";
import ColorPickerWrapper from '../../components/color_picker/ColorPickerWrapper';
import {SegmentedButtons, DataTable} from 'react-native-paper';

import commonStylesF from '../../styles/common';
import adminStyles from '../../styles/admin';
import listStylesF from '../../styles/list';
import {default as footerStylesMobile} from '../../styles/footer_mobile';
import {default as footerStylesDesktop} from '../../styles/footer';

import {default as _theme} from '../../config/theme_config';
import Config from '../../config/config';

const TableHeader = (color) => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);

  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title>
        <Text style={{...listStyles.itemsHeaderText, color: color}}>{t('date')}</Text>
      </DataTable.Title>
      <DataTable.Title>
        <Text style={listStyles.itemsHeaderText}>{t('status')}</Text>
      </DataTable.Title>
      <DataTable.Title>
        <Text style={listStyles.itemsHeaderText}>{t('contact')}</Text>
      </DataTable.Title>
      <DataTable.Title>
        <Text style={listStyles.itemsHeaderText}>{t('status')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
}

const TableRow = ({item, color, backgroundColor1, backgroundColor2}) => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  return (
    <DataTable.Row
    key={item.id}
    style={{
      ...listStyles.itemRow,
      backgroundColor: item.referal_id > 0 ? backgroundColor1 : backgroundColor2,
    }}
    onPress={() => {
      console.log('press');
    }}>
    <DataTable.Cell
      style={{...listStyles.itemCol, color: color}}>
      <Text
        style={{...listStyles.itemCol, color: color}}>
        {item.created_at.substr(0, 10)}
      </Text>
    </DataTable.Cell>
    <DataTable.Cell style={{...listStyles.itemCol, color: color}}>
      <Text
        style={{...listStyles.itemCol, color: color}}>
        {item.name}
      </Text>
    </DataTable.Cell>
    <DataTable.Cell style={{...listStyles.itemCol, color: color}}>
      <Text
        style={{...listStyles.itemCol, color: color}}>
        {item.phone}
      </Text>
    </DataTable.Cell>
    <DataTable.Cell style={{...listStyles.itemCol, color: color}}>
      <Text
        style={{...listStyles.itemCol, color: color}}>
        {item.referal_id ? t('invite_status_1') : t('invite_status_0')}
      </Text>
    </DataTable.Cell>
  </DataTable.Row>
  );
}

const ThemeSettings = ({navigation, route}) => {
  const {screenWidth, t, theme, user, logout, setTheme, setConfig} = useContext(AuthContext);

  const [color1, setColor1] = useState(theme.WHITE);
  const [color2, setColor2] = useState(theme.LIGHT_BLUE);
  const [color3, setColor3] = useState(theme.BLUE);
  const [color4, setColor4] = useState(theme.BLACK);
  const [isError, setIsError] = useState(false);
  const [info, setInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const commonStyles = commonStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);
  const footerStyles =
  screenWidth < 1080
    ? footerStylesMobile(wp, theme)
    : footerStylesDesktop(theme);
  const footerLinksDirection = wp('100%') < 700 ? {flexDirection: 'column'} : {flexDirection: 'row'};

  const data = [
    {
      created_at: '2024.07.01',
      name: 'Петров Иван Петрович',
      phone: '380555555555',
      referal_id: 7,
    },
    {
      created_at: '2024.06.01',
      name: 'Иванов Петр Иванович',
      phone: '380556666666',
      referal_id: 0,
    },
  ];

  const saveData = async () => {
    const api = AxiosInstance({user, logout});
    let settings = {
      color1: {
        name: 'color1',
        value: color1
      },
      color2: {
        name: 'color2',
        value: color2
      },
      color3: {
        name: 'color3',
        value: color3
      },
      color4: {
        name: 'color4',
        value: color4
      },
    };
    setIsLoading(true);
    try {
      const api = AxiosInstance({user, logout});
      const response = await api(`${Config.API_URL}/api/v1/settings`, {
        method: 'PUT',
        mode: 'cors',
        data: settings,
      });
      if (response.data.status === 'success') {
        console.log('settingssaving response: ', response.data.message);
        setColor1(response.data.message.color1.value);
        setColor2(response.data.message.color2.value);
        setColor3(response.data.message.color3.value);
        setColor4(response.data.message.color4.value);

        setTheme(prevVal => {
          return {
            ...prevVal,
            WHITE: response.data.message.color1.value,
            LIGHT_BLUE: response.data.message.color2.value,
            BLUE: response.data.message.color3.value,
            BLACK: response.data.message.color4.value,
            FONT_COLOR_WHITE: response.data.message.color1.value,
            FONT_COLOR_LIGHT_BLUE: response.data.message.color2.value,
            FONT_COLOR_BLUE: response.data.message.color3.value,
            FONT_COLOR_BLACK: response.data.message.color4.value,
          }
        });

        setConfig(prevVal => {return {
          ...prevVal,
          MAX_SMS: response.data.message.MAX_SMS.value,
          MAX_EMAILS: response.data.message.MAX_EMAILS.value,
          CONTACTS_RELOAD_AFTER_DAYS: response.data.message.CONTACTS_RELOAD_AFTER_DAYS.value,
        }});
      } else {
        setIsError(JSON.stringify(response.data));
      }
    } catch (error) {
      setIsError(t('server_error'));
      console.log('ERROR', error);
    } finally {
      setIsLoading(false);
    }
  }

  const resetToDefaults = () => {
    setColor1(_theme.WHITE);
    setColor2(_theme.LIGHT_BLUE);
    setColor3(_theme.BLUE);
    setColor4(_theme.BLACK);
  }

  // загрузка настроек
  useEffect(() => {
    const getSettings = async () => {
      setIsLoading(true);
      try {
        const api = AxiosInstance({user, logout});
        const response = await api(`${Config.API_URL}/api/v1/settings`, {
          method: 'GET',
          mode: 'cors',
        });
        if (response.data.status === 'success') {
          console.log('settings currency: ', response.data.message);
          setColor1(response.data.message.color1.value);
          setColor2(response.data.message.color2.value);
          setColor3(response.data.message.color3.value);
          setColor4(response.data.message.color4.value);
        } else {
          setIsError(JSON.stringify(response.data));
        }
      } catch (error) {
        setIsError(t('server_error'));
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getSettings();
  }, [user, logout, t, theme]);

  return (
    <View style={commonStyles.container}>
      <Header navigation={navigation} />
      <View style={commonStyles.contentContainer}>
        <View style={commonStyles.contentHead}>
          <Text style={commonStyles.contentHeadText}>{t('admin_theme')}</Text>
        </View>
        <View style={styles.contentContainer}>
          {/** Background colors */}
          <View style={styles.pickers}>
            <View style={styles.pickerContainer}>
              <Text>{t('color')} 1</Text>
              <ColorPickerWrapper
                color={color1}
                onChangeComplete={color => setColor1(color.hex)}
              />
            </View>
            <View style={styles.pickerContainer}>
              <Text>{t('color')} 2</Text>
              <ColorPickerWrapper
                color={color2}
                onChangeComplete={color => setColor2(color.hex)}
              />
            </View>
            <View style={styles.pickerContainer}>
              <Text>{t('color')} 3</Text>
              <ColorPickerWrapper
                color={color3}
                onChangeComplete={color => setColor3(color.hex)}
              />
            </View>
            <View style={styles.pickerContainer}>
              <Text>{t('color')} 4</Text>
              <ColorPickerWrapper
                color={color4}
                onChangeComplete={color => setColor4(color.hex)}
              />
            </View>
          </View>
          <View style={styles.btnGroup}>
              <Pressable
                style={{...styles.btnProfile, backgroundColor: theme.BLACK}}
                onPress={() => resetToDefaults()}
              >
                <Text style={{...styles.btnProfileText, color: theme.FONT_COLOR_WHITE}}>{t('reset')}</Text>
              </Pressable>

              <Pressable
                style={{...styles.btnProfile, backgroundColor: theme.BLACK}}
                onPress={() => saveData()}
              >
                <Text style={{...styles.btnProfileText, color: theme.FONT_COLOR_WHITE}}>
                  {t('save')}
                </Text>
              </Pressable>
              <View style={styles.divider}>
                <Text></Text>
              </View>
            </View>
        </View>
        <View style={{height: 50, width: '100%', borderWidth: 1, borderColor: "FFF", alignItems: 'center', justifyContent: 'center'}}>
          <Text style={{fontSize: 24, fontFamily: theme.FONT_FAMILY_BOLD}}>{t('result')}</Text>
        </View>
        <View style={styles.elementsContainer}>
          <View style={styles.headerContainer}>
            <View style={styles.logoContainer}>
              <Text style={{...styles.headerLogoText, color: color3}}>{t('cashback')}</Text>
            </View>
            <View style={styles.headerLinksContainer}>
              <View>
                <Text style={{...styles.statLabel, color: color4}}>{t('invitations')}</Text>
              </View>
              <View>
                <Text style={{...styles.statLabel, color: color4}}>{t('cashback')}</Text>
              </View>
              <View>
                <Text style={{...styles.statLabel, color: color4}}>{t('balance')}</Text>
              </View>
              <View>
                <Text style={{...styles.statLabel, color: color4}}>{t('history')}</Text>
              </View>
            </View>
            <View style={styles.langButtons}>
              <Picker
                selectedValue={"ua"}
                style={{
                  height: 35,
                  width: 60,
                  borderWidth: 1,
                  borderColor: '#000000',
                  backgroundColor: '#FFF',
                }}
                onValueChange={(itemValue, itemIndex) =>
                  console.log(itemValue)
                }>
                <Picker.Item label="UA" value="ua" />
                <Picker.Item label="RU" value="ru" />
                <Picker.Item label="EN" value="en" />
              </Picker>
            </View>
            <View style={styles.navigationBox}>
              <Text style={{...styles.myProfileButton, color: color1, backgroundColor: color4}}>
                {t('my_profile_text')}
              </Text>
            </View>
          </View>

          <View style={styles.headContainer}>
            <Pressable
              style={{...commonStyles.headBackButton, backgroundColor: color4}}
              onPress={() => navigation.navigate('Profile')}>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={commonStyles.arrowLeft} />
              )}
            </Pressable>
            <View style={{...commonStyles.contentHead, backgroundColor: color3}}>
              <Text style={{...commonStyles.contentHeadText, color: color1}}>{t('invitations')}</Text>
            </View>
          </View>

          <SegmentedButtons
            style={{
              width: wp('90%'),
              maxWidth: 600,
              alignSelf: 'center',
              margin: 15,
            }}
            theme={{
              colors: {
                secondaryContainer: color4,
                onSecondaryContainer: color1,
              },
            }}
            value={0}
            onValueChange={value => {console.log(value)}}
            buttons={[
              {
                value: 1,
                label: t('invite_status_1'),
              },
              {
                value: 0,
                label: t('invite_status_0'),
              },
              {
                value: null,
                label: t('all'),
              },
            ]}
          />

          <DataTable style={listStyles.dataTable}>
            <TableHeader color={color3} />

            {data.map(item => (
              <TableRow item={item} key={item.id} color={color1} backgroundColor1={color3} backgroundColor2={color2}/>
            ))}

            <DataTable.Pagination
              page={1}
              numberOfPages={1}
              onPageChange={page => console.log(page + 1)}
              label={`${0}-${1} of ${1}`}
              numberOfItemsPerPage={5}
              showFastPaginationControls
            />
          </DataTable>
          <View style={listStyles.itemsFooterContainer}>
            <View style={styles.btnGroup}>
              <Pressable
                style={{...styles.btnProfile, backgroundColor: color4}}
              >
                <Text style={{...styles.btnProfileText, color: color1}}>{t('add')}</Text>
              </Pressable>

              <Pressable
                style={{...styles.btnProfile, backgroundColor: color4}}
              >
                <Text style={{...styles.btnProfileText, color: color1}}>
                  {t('my_contacts')}
                </Text>
              </Pressable>
              <View style={styles.divider}>
                <Text></Text>
              </View>
            </View>
          </View>
          <View style={{...styles.footerContainer, ...footerLinksDirection, backgroundColor: color4}}>
            <View style={footerStyles.footerLogoContainer}>
              <Text style={footerStyles.logoText}>{t('cashback')}</Text>
            </View>

            <View
              style={{
                ...footerStyles.footerLinksContainer,
                ...footerLinksDirection
              }}>
              <View style={footerStyles.footerLinksBlock}>
                <Pressable>
                  <Text style={{...footerStyles.statLabel, color: color1}}>{t('policy')}</Text>
                </Pressable>
                <Pressable>
                  <Text style={{...footerStyles.statLabel, color: color1}}>{t('feedback')}</Text>
                </Pressable>
              </View>
              <View style={footerStyles.footerLinksBlock}>
                <Pressable>
                  <Text style={{...footerStyles.statLabel, color: color1}}>{t('contacts')}</Text>
                </Pressable>
                <Pressable>
                  <Text style={{...footerStyles.statLabel, color: color1}}>{t('about')}</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </View>

      </View>
      {isLoading === true && (
        <ActivityIndicator
          style={styles.activityIndicator}
          color="#0C090D"
          size="large"
        />
      )}
      {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      {info && <InfoModal info={info} setInfo={setInfo} />}
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
    width: wp('100%'),
    maxWidth: 1080,
    flexDirection: 'column',
  },
  pickers: {
    // flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 10,
  },
  pickerContainer: {
    flex: 1,
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center',
  },
  elementsContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
  },
  backgroundSample: {
    margin: 10,
    width: '100%',
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  textSample: {
    fontFamily: 'Mariupol',
    fontSize: 14,
  },
  textMediumSample: {
    fontFamily: 'Mariupol-medium',
    fontSize: 14,
  },
  textMediumBold: {
    fontFamily: 'Mariupol-bold',
    fontSize: 14,
  },
  headerContainer: {
    justifyContent: 'center',
    height: 70,
    maxHeight: 70,
    width: '100%',
    backgroundColor: '#FFF',
    flexShrink: 0,
    flexDirection: 'row'
  },
  logoContainer: {
    width: 140,
    height: 50,
    top: 10,
    left: 30,
    paddingTop: 15,
    paddingRight: 9,
    paddingBottom: 16,
    paddingLeft: 10,
    justifyContent: 'center',
  },
  headerLogoText: {
    fontFamily: 'Mariupol-bold',
    fontSize: 32,
  },
  headerLinksContainer: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 25,
    margin: 'auto',
    // paddingLeft: 80,
    flexDirection: 'row',
  },
  statLabel: {
    fontFamily: 'Mariupol',
    fontSize: 16,
    lineHeight: 19,
  },
  selected: {
    color: 'rgb(25, 36, 200)',
  },
  langButtons: {
    width: 70,
    alignSelf: 'center',
    gap: 3,
  },
  navigationBox: {
    // width: 110,
    height: 70,
    maxHeight: 70,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    marginLeft: 10,
  },
  myProfileButton: {
    fontFamily: 'Mariupol',
    fontSize: 16,
    width: 132,
    height: 35,
    borderRadius: 6,
    paddingHorizontal: 20,
    paddingVertical: 10,
    lineHeight: 16,
  },
  headContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
  },
  btnGroup: {
    // marginTop: 31,
    gap: 2,
    marginBottom: 32,
    // flex: 1,
    flexDirection: 'row',
  },
  btnProfile: {
    width: wp('25%'),
    maxWidth: 145,
    height: 39,
    paddingHorizontal: 5,
    paddingVertical: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 10,
    // borderColor: theme.BLACK,
    // backgroundColor: theme.BLACK,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnProfileText: {
    fontFamily: 'Mariupol-medium',
    // color: theme.FONT_COLOR_WHITE,
    fontSize: 16,
  },
  footerContainer: {
    // flex: 1,
    flexDirection: 'row',
    width: 1080,
    height: 210,
    marginTop: 75
  },
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
});

export default ThemeSettings;