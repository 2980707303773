import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  View,
  Pressable,
  Image,
  TextInput,
  KeyboardAvoidingView,
  Keyboard,
  Platform,
} from 'react-native';
import {Text, HStack, Radio, ScrollView} from 'native-base';
import {Card, Avatar, SegmentedButtons, DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import axios from 'axios';
import {TextInputMask} from 'react-native-masked-text';
import AxiosInstance from '../../components/common/axiosInstance';
import Contacts from 'react-native-contacts';
import {PERMISSIONS, request} from 'react-native-permissions';
import {useGoogleLogin} from '../../components/common/GoogleSignin';

import {
  nameToFio,
  replaceWithAsterisks,
  replaceNameWithAsterics,
  replaceEmailWithAsterics,
  formInitials,
  capitalizeFirstLetter,
} from '../../components/common/commonFunctions';
import ReferalDetails from './ReferalDetails';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
// import Config from '../../config/config';
import {AuthContext} from '../../components/AuthProvider';
import {getDataFromStorage, saveDataToStorage} from './ContactsCommon';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import {default as commonStylesDesktop} from '../../styles/common';
import {default as listStylesF} from '../../styles/list';
import {default as invitationsStylesDesktop} from '../../styles/invitations';
import {default as invitationsStylesMobile} from '../../styles/invitations_mobile';
import IOSCustomPagination from '../../components/common/IOSCustomPagination';

import Config from '../../config/config';

const InvitationsTableHeader = props => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);

  const TableTitleDate = props => {
    if (props.sortField === 'created_at') {
      return (
        <DataTable.Title
          sortDirection={
            props.sortDirection === 'desc' ? 'descending' : 'ascending'
          }>
          <Pressable
            onPress={() => {
              dateSort(props.sortField, props.sortDirection);
            }}>
            <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
              <Text style={{...listStyles.itemsHeaderText, marginLeft: 10}}>
                {t('date')}
              </Text>
            </HStack>
          </Pressable>
        </DataTable.Title>
      );
    } else {
      return (
        <DataTable.Title>
          <Pressable
            onPress={() => {
              dateSort(props.sortField, props.sortDirection);
            }}>
            <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
              <Text style={{...listStyles.itemsHeaderText, marginLeft: 10}}>
                {t('date')}
              </Text>
            </HStack>
          </Pressable>
        </DataTable.Title>
      );
    }
  };

  const TableTitleName = props => {
    if (props.sortField === 'name') {
      return (
        <DataTable.Title
          sortDirection={
            props.sortDirection === 'desc' ? 'descending' : 'ascending'
          }>
          <Pressable
            onPress={() => {
              nameSort(props.sortField, props.sortDirection);
            }}>
            <HStack>
              <Text style={listStyles.itemsHeaderText}>{t('name')}</Text>
            </HStack>
          </Pressable>
        </DataTable.Title>
      );
    } else {
      return (
        <DataTable.Title>
          <Pressable
            onPress={() => {
              nameSort(props.sortField, props.sortDirection);
            }}>
            <HStack>
              <Text style={listStyles.itemsHeaderText}>{t('name')}</Text>
            </HStack>
          </Pressable>
        </DataTable.Title>
      );
    }
  };

  const dateSort = (currSort, direction) => {
    if (currSort !== 'created_at') {
      props.setSortField('created_at');
    }
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };
  const nameSort = (currSort, direction) => {
    if (currSort !== 'name') {
      props.setSortField('name');
    }
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <TableTitleDate
        sortField={props.sortField}
        sortDirection={props.sortDirection}
      />
      <TableTitleName
        sortField={props.sortField}
        sortDirection={props.sortDirection}
      />
      <DataTable.Title>
        <Text style={listStyles.itemsHeaderText}>{t('contact')}</Text>
      </DataTable.Title>
      <DataTable.Title>
        <Text style={listStyles.itemsHeaderText}>{t('status')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const InvitationsCardHeader = props => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);

  const TableTitleDate = props => {
    if (props.sortField === 'created_at') {
      return (
        <DataTable.Title
          sortDirection={
            props.sortDirection === 'desc' ? 'descending' : 'ascending'
          }>
          <Pressable
            onPress={() => {
              dateSort(props.sortField, props.sortDirection);
            }}>
            <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
              <Text style={{...listStyles.itemsHeaderText, marginLeft: 10}}>
                {t('date')}
              </Text>
            </HStack>
          </Pressable>
        </DataTable.Title>
      );
    } else {
      return (
        <DataTable.Title>
          <Pressable
            onPress={() => {
              dateSort(props.sortField, props.sortDirection);
            }}>
            <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
              <Text style={{...listStyles.itemsHeaderText, marginLeft: 10}}>
                {t('date')}
              </Text>
            </HStack>
          </Pressable>
        </DataTable.Title>
      );
    }
  };

  const TableTitleName = props => {
    if (props.sortField === 'name') {
      return (
        <DataTable.Title
          sortDirection={
            props.sortDirection === 'desc' ? 'descending' : 'ascending'
          }>
          <Pressable
            onPress={() => {
              nameSort(props.sortField, props.sortDirection);
            }}>
            <HStack>
              <Text style={listStyles.itemsHeaderText}>{t('name')}</Text>
            </HStack>
          </Pressable>
        </DataTable.Title>
      );
    } else {
      return (
        <DataTable.Title>
          <Pressable
            onPress={() => {
              nameSort(props.sortField, props.sortDirection);
            }}>
            <HStack>
              <Text style={listStyles.itemsHeaderText}>{t('name')}</Text>
            </HStack>
          </Pressable>
        </DataTable.Title>
      );
    }
  };

  const dateSort = (currSort, direction) => {
    if (currSort !== 'created_at') {
      props.setSortField('created_at');
    }
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };
  const nameSort = (currSort, direction) => {
    if (currSort !== 'name') {
      props.setSortField('name');
    }
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  return (
    <DataTable.Header style={{...listStyles.itemsHeader, height: 40}}>
      <TableTitleDate
        sortField={props.sortField}
        sortDirection={props.sortDirection}
      />
      <TableTitleName
        sortField={props.sortField}
        sortDirection={props.sortDirection}
      />
    </DataTable.Header>
  );
};

const InvitationCard = ({item, navigation}) => {
  const {t, theme} = useContext(AuthContext);
  const [isError, setIsError] = useState(null);
  // console.log(item);
  const LeftContent = () => (
    <Avatar.Text
      label={formInitials(item.name)}
      size={55}
      style={{
        borderRadius: 5,
        justifyContent: 'center',
      }}
      labelStyle={{
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 24,
      }}
      color={theme.FONT_COLOR_BLACK}
      theme={{
        colors: {
          primary: '#D9D9D9',
        },
      }}
    />
  );

  const SubTitle = (
    <>
      {t('contact')}:{' '}
      {!item.shop_id
        ? (item.phone
          ? item.phone
          : item.email
        ) : (item.phone
          ? replaceWithAsterisks(item.phone)
          : replaceEmailWithAsterics(item.email)
        )
      }
    </>
  );

  return (
    <DataTable.Row
      key={item.id}
      style={{
        width: wp('90%'),
        alignItems: 'center',
        cursor: item.referal_id > 0 ? 'pointer' : 'default',
      }}>
      <Card
        mode={'contained'}
        style={{
          width: wp('90%'),
          margin: 2,
          maxWidth: 980,
          alignSelf: 'center',
          backgroundColor: item.referal_id > 0 ? theme.BLUE : theme.LIGHT_BLUE,
          borderRadius: 8,
          cursor: item.referal_id > 0 ? 'pointer' : 'default',
        }}
        onPress={() => {
          item.referal_id > 0 &&
            (Platform.OS !== 'web'
              ? navigation.navigate('Invitations', {id: item.referal_id})
              : navigation.push('Invitations', {id: item.referal_id}));
        }}>
        <Card.Title
          title={
            !item.shop_id
              ? nameToFio(item.name)
              : replaceNameWithAsterics(nameToFio(item.name))
          }
          subtitle={SubTitle}
          left={LeftContent}
          titleStyle={{
            fontFamily: theme.FONT_FAMILY_MEDIUM,
            color: theme.FONT_COLOR_WHITE,
            fontSize: 16,
            paddingLeft: 10,
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            cursor: item.referal_id > 0 ? 'pointer' : 'default',
          }}
          subtitleStyle={{
            fontFamily: theme.FONT_FAMILY_MEDIUM,
            color: theme.FONT_COLOR_WHITE,
            fontSize: 16,
            paddingLeft: 10,
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            cursor: item.referal_id > 0 ? 'pointer' : 'default',
          }}
        />
      </Card>
    </DataTable.Row>
  );
};

const InvitationTable = ({item, navigation}) => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);

  return (
    <DataTable.Row
      key={item.id}
      style={{
        ...listStyles.itemRow,
        cursor: item.referal_id > 0 ? 'pointer' : 'default',
        backgroundColor: item.referal_id > 0 ? theme.BLUE : theme.LIGHT_BLUE,
      }}
      onPress={() => {
        item.referal_id > 0 &&
          (Platform.OS !== 'web'
            ? navigation.navigate('Invitations', {id: item.referal_id})
            : navigation.push('Invitations', {id: item.referal_id}));
      }}>
      <DataTable.Cell
        style={{
          ...listStyles.itemCol,
          cursor: item.referal_id > 0 ? 'pointer' : 'default',
        }}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize: Platform.OS === 'ios' ? 12 : listStyles.itemCol.fontSize,
            cursor: item.referal_id > 0 ? 'pointer' : 'default',
          }}>
          {item.created_at.substr(0, 10)}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize: Platform.OS === 'ios' ? 12 : listStyles.itemCol.fontSize,
            cursor: item.referal_id > 0 ? 'pointer' : 'default',
          }}>
          {!item.shop_id
            ? nameToFio(item.name)
            : replaceNameWithAsterics(nameToFio(item.name))}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize: Platform.OS === 'ios' ? 12 : listStyles.itemCol.fontSize,
            cursor:!item.shop_id ? 'pointer' : 'default',
          }}>
          {!item.shop_id
            ? item.phone
              ? item.phone
              : item.email
            : item.phone
            ? replaceWithAsterisks(item.phone)
            : replaceEmailWithAsterics(item.email)}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize: Platform.OS === 'ios' ? 12 : listStyles.itemCol.fontSize,
            cursor: item.referal_id > 0 ? 'pointer' : 'default',
          }}>
          {item.referal_id ? t('invite_status_1') : t('invite_status_0')}
        </Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const InvitationsScreen = ({navigation, route}) => {
  const {user, logout, t, screenWidth, theme, addHistory, setScreenParams} =
    useContext(AuthContext);
  const listStyles = listStylesF(theme);

  let commonStyles = commonStylesDesktop(wp, hp, theme);
  let invitationsStyles =
    screenWidth < 1080
      ? invitationsStylesMobile(wp, hp, theme)
      : invitationsStylesDesktop(wp, hp, theme);

  let InvitationsHeader =
    screenWidth < 700 ? InvitationsCardHeader : InvitationsTableHeader;
  let Invitation = screenWidth < 700 ? InvitationCard : InvitationTable;

  const {id: referalId} = route.params || {};
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [invitations, setInvitations] = useState([]);
  const [newInvitation, setNewInvitation] = useState({
    name: '',
    phone: '',
    email: '',
    description: '',
    subject: t('invitation'),
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [addInvitation, setAddInvitation] = useState(false);
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [status, setStatus] = useState(null);
  const textInputName = useRef(null);
  const textInputPhone = useRef(null);
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  const [contacts, setContacts] = useState([]);
  // const [tokens, setTokens] = useState(false);
  const [contactType, setContactType] = useState('phone');
  const [showDetails, setShowDetails] = useState(false);
  const [prevWidth, setPrevWidth] = useState(screenWidth);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  // const [selectedReferal, setSelectedReferal] = useState(null);

  const keyboarHide = () => {
    setIsShowKeyboard(false);
    Keyboard.dismiss();
  };

  const handleAddInvitation = () => {
    setNewInvitation({
      name: '',
      phone: '',
      email: '',
      description: '',
      subject: t('invitation'),
    });
    setAddInvitation(true);
  };

  const handleCloseAddInvitation = () => {
    setAddInvitation(false);
  };

  const handleSendInvitation = async () => {
    if (!newInvitation.name) {
      setIsError(t('type_name'));
      return false;
    }
    if (!newInvitation.phone && !newInvitation.email) {
      setIsError(t('enter_phone_or_email'));
      return false;
    }

    setLoading(true);

    const api = AxiosInstance({user, logout});

    await api(`/api/v1/invitations`, {
      method: 'POST',
      mode: 'cors',
      data: newInvitation,
    })
      .then(response => {
        loadInvitations(user);
        setAddInvitation(false);
      })
      .catch(error => {
        setIsError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadInvitations = useCallback(async () => {
    setLoading(true);
    const api = AxiosInstance({user, logout});
    let url = `/api/v1/invitations?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`;
    if (status !== null) {
      url += `&status=${status}`;
    }
    await api(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        let _from = 0;
        let _to = 0;

        setInvitations(response.data.message.data);
        setPagination(response.data.message.pagination);
        _from =
          response.data.message.pagination.totalRows > 0
            ? (currentPage - 1) * response.data.message.pagination.perPage + 1
            : 0;
        _to =
          response.data.message.pagination.totalRows >
          currentPage * response.data.message.pagination.perPage
            ? currentPage * response.data.message.pagination.perPage
            : response.data.message.pagination.totalRows;
        setFrom(_from);
        setTo(_to);
      })
      .catch(error => {
        console.log('Load invitations error', error);
        setIsError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, currentPage, status, sortField, sortDirection, logout]);


  const showWebContactsPress = () => {
    navigation.navigate('WebContacts');
   };

  const showDeviceContacts = async () => {
    let _contactType = await getDataFromStorage('contactType');
    if (_contactType === 'other') {
      navigation.navigate('OtherContactsList');
    } else {
      navigation.navigate('ContactsList');
    }
  };

  useEffect(() => {
    // console.log('Invitatios useEffect ', referalId);

    if (!referalId || referalId === undefined) {
      setShowDetails(false);
      loadInvitations();
    } else if (referalId) {
      setShowDetails(true);
    }
  }, [loadInvitations, navigation, referalId]);

  useEffect(() => {
    // console.log('setPrevWidth useEffect ', screenWidth);
    setPrevWidth(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  if (loading) {
    return <Loader />;
  } else if (showDetails) {
    return (
      <ReferalDetails
        referalId={referalId}
        navigation={navigation}
        route={route}
      />
    );
  } else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={invitationsStyles.headerContainer}>
            <Pressable
              style={invitationsStyles.headBackButton}
              onPress={() => navigation.navigate('Profile')}>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={invitationsStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={invitationsStyles.arrowLeft} />
              )}
            </Pressable>
            <View style={invitationsStyles.contentHead}>
              <Text style={invitationsStyles.contentHeadText}>
                {t('mailing_to_contacts')}
              </Text>
            </View>
          </View>
          <SegmentedButtons
            style={{
              width: wp('90%'),
              maxWidth: 600,
            }}
            theme={{
              colors: {
                secondaryContainer: theme.BLACK,
                onSecondaryContainer: theme.FONT_COLOR_WHITE,
              },
            }}
            value={status}
            onValueChange={value => {
              setCurrentPage(1);
              setStatus(value);
            }}
            buttons={[
              {
                value: 1,
                label: t('invite_status_1'),
              },
              {
                value: 0,
                label: t('invite_status_0'),
              },
              {
                value: null,
                label: t('all'),
              },
            ]}
          />
          {Platform.OS === 'ios' ? (
            // Кастомна пагінація і рендеринг даних для iOS
            <View style={listStyles.dataTable}>
              <InvitationsHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
              {invitations.map(item => (
                <Invitation item={item} key={item.id} />
              ))}
              <IOSCustomPagination
                currentPage={currentPage}
                totalPages={Math.ceil(pagination.totalPages)}
                onPageChange={newPage => setCurrentPage(newPage)}
                label={`${from}-${to} of ${pagination.totalRows}`}
              />
            </View>
          ) : (
            // Стандартна пагінація для інших платформ
            <DataTable style={listStyles.dataTable}>
              <InvitationsHeader
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                sortField={sortField}
                setSortField={setSortField}
              />

              {invitations.map(item => (
                <Invitation item={item} navigation={navigation} key={item.id} />
              ))}

              <DataTable.Pagination
                page={currentPage - 1}
                numberOfPages={Math.ceil(pagination.totalPages)}
                onPageChange={page => setCurrentPage(page + 1)}
                label={`${from}-${to} of ${pagination.totalRows}`}
                numberOfItemsPerPage={pagination.perPage}
                showFastPaginationControls
              />
            </DataTable>
          )}

          <HStack style={listStyles.itemsFooterContainer}>
            <HStack style={invitationsStyles.btnGroup}>
              <Pressable
                style={invitationsStyles.btnProfile}
                onPress={handleAddInvitation}>
                <Text style={invitationsStyles.btnProfileText}>{t('add')}</Text>
              </Pressable>

              {Platform.OS === 'android' ? (
                <Pressable
                  style={invitationsStyles.btnProfile}
                  // onPress={() => setShowContacts(true)}
                  onPress={() => showDeviceContacts()}>
                  <Text style={invitationsStyles.btnProfileText}>
                    {t('my_contacts')}
                  </Text>
                </Pressable>
              ) : Platform.OS === 'ios' ? (
                <></>
              ) : (
                <Pressable
                  style={invitationsStyles.btnProfile}
                  onPress={() => {
                    showWebContactsPress();
                  }}>
                  <Text style={invitationsStyles.btnProfileText}>
                    {t('my_contacts')}
                  </Text>
                </Pressable>
              )}
              {/* {checkToken() && (
                <Pressable
                  style={invitationsStyles.btnProfile}
                  onPress={() => {
                    changeGoogleAccount();
                  }}>
                  <Text style={invitationsStyles.btnProfileText}>
                    {t('change_account')}
                  </Text>
                </Pressable>
              )} */}
              {Platform.OS === 'ios' && (
                <Pressable
                  style={invitationsStyles.btnProfile}
                  onPress={() => {
                    request(PERMISSIONS.IOS.CONTACTS)
                      .then(response => {
                        if (response === 'granted') {
                          Contacts.getAll()
                            .then(contacts => {
                              // console.log('Raw contacts:', contacts); //  всі отримані контакти

                              const processedContacts = contacts.map(
                                contact => ({
                                  name:
                                    contact.givenName ||
                                    contact.displayName ||
                                    t('no_name'),
                                  phone:
                                    contact.phoneNumbers[0]?.number ||
                                    t('phone_no_available'),
                                })
                              );
                              setContacts(processedContacts);
                              // setShowContacts(true);
                            })
                            .catch(error => {
                              console.error('Error fetching contacts', error);
                            });
                        } else {
                          alert(t('contacts_no_available'));
                        }
                      })
                      .catch(error => {
                        console.error('Permission error:', error);
                      });
                  }}>
                  <Text style={invitationsStyles.btnContactAndSupport}>
                    {t('contacts')}
                  </Text>
                </Pressable>
              )}
              <View style={invitationsStyles.divider}>
                <Text></Text>
              </View>
            </HStack>
          </HStack>
        </View>
        <Footer navigation={navigation} />
        {addInvitation && (
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={
              Platform.OS !== 'web'
                ? {
                    flex: 1,
                    position: 'absolute',
                    width: wp('100%'),
                    height: '100%',
                    justifyContent: 'center', // Центрування по вертикалі
                    alignItems: 'center', // Центрування по горизонталі
                  }
                : {}
            }>
            <View
              style={
                Platform.OS == 'ios'
                  ? {
                      ...invitationsStyles.modalContainer,
                      marginBottom: isShowKeyboard ? -100 : -100,
                    }
                  : {
                      ...invitationsStyles.modalContainer,
                      marginBottom: isShowKeyboard ? 20 : 150,
                    }
              }>
              <View style={invitationsStyles.modalContent}>
                <View style={invitationsStyles.modalHead}>
                  <Pressable onPress={handleCloseAddInvitation}>
                    <Image
                      style={invitationsStyles.iconClose}
                      source={require('../../assets/img/icon_close.png')}
                    />
                  </Pressable>
                </View>

                <View style={invitationsStyles.modalBody}>
                  <Text style={invitationsStyles.modalCaption}>
                    {capitalizeFirstLetter(t('invitation'))}
                  </Text>
                  <View style={invitationsStyles.inputContainer}>
                    <Text style={invitationsStyles.inputLabel}>
                      {t('name')}*
                    </Text>
                    <TextInput
                      ref={textInputName}
                      style={invitationsStyles.input}
                      value={newInvitation.name}
                      onChangeText={text =>
                        setNewInvitation({...newInvitation, name: text})
                      }
                      placeholder="Имя"
                      onFocus={() => setIsShowKeyboard(true)}
                    />
                  </View>
                  <SegmentedButtons
                    style={{
                      ...invitationsStyles.segmentedButtons,
                      maxWidth: 250,
                    }}
                    theme={{
                      colors: {
                        secondaryContainer: theme.BLACK,
                        onSecondaryContainer: theme.FONT_COLOR_WHITE,
                      },
                    }}
                    value={contactType}
                    onValueChange={value => {
                      setContactType(value);
                    }}
                    buttons={[
                      {
                        value: 'phone',
                        label: t('phone'),
                      },
                      {
                        value: 'email',
                        label: 'Email',
                      },
                    ]}
                  />
                  {contactType === 'phone' ? (
                    <View style={invitationsStyles.inputContainer}>
                      <Text style={invitationsStyles.inputLabel}>
                        {t('phone_number')}*
                      </Text>
                      <TextInputMask
                        ref={textInputPhone}
                        type={'custom'}
                        options={{
                          mask: '+99999 999 99 99',
                        }}
                        style={invitationsStyles.input}
                        placeholder="+38099 123 45 67"
                        value={newInvitation.phone}
                        onChangeText={text =>
                          setNewInvitation({...newInvitation, phone: text})
                        }
                        onFocus={() => setIsShowKeyboard(true)}
                      />
                    </View>
                  ) : (
                    <View style={invitationsStyles.inputContainer}>
                      <Text style={invitationsStyles.inputLabel}>Email</Text>
                      <TextInput
                        style={invitationsStyles.input}
                        placeholder="Email"
                        value={newInvitation.email}
                        onChangeText={text =>
                          setNewInvitation({
                            ...newInvitation,
                            email: text,
                          })
                        }
                        onFocus={() => setIsShowKeyboard(true)}
                      />
                    </View>
                  )}
                  <View style={invitationsStyles.inputContainer}>
                    <Text style={invitationsStyles.inputLabel}>
                      {t('description')}
                    </Text>
                    <TextInput
                      style={invitationsStyles.input}
                      value={newInvitation.description}
                      onChangeText={text =>
                        setNewInvitation({
                          ...newInvitation,
                          description: text,
                        })
                      }
                      onFocus={() => setIsShowKeyboard(true)}
                    />
                  </View>
                  <HStack style={invitationsStyles.modalBtnGroup}>
                    <Pressable
                      style={invitationsStyles.modalBtn}
                      onPress={() => {
                        handleCloseAddInvitation();
                        keyboarHide();
                      }}>
                      <Text style={invitationsStyles.btnTextWhite}>
                        {t('cancel')}
                      </Text>
                    </Pressable>
                    <Pressable
                      style={invitationsStyles.modalBtn}
                      onPress={() => {
                        handleSendInvitation();
                        keyboarHide();
                      }}>
                      <Text style={invitationsStyles.btnTextWhite}>
                        {t('send')}
                      </Text>
                    </Pressable>
                  </HStack>
                </View>
              </View>
            </View>
          </KeyboardAvoidingView>
        )}
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </ScrollView>
    );
  }
};

export default InvitationsScreen;
