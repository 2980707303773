import React, {useState, useContext, useEffect, useCallback} from 'react';
import {HStack} from 'native-base';
import {
  Text,
  View,
  ScrollView,
  Pressable,
  ActivityIndicator,
  Image,
  Platform,
  StyleSheet,
  KeyboardAvoidingView,
  Modal,
} from 'react-native';
import {DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {TextInputMask} from 'react-native-masked-text';
import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import {capitalizeFirstLetter} from '../../components/common/commonFunctions';
import { OrderHeaderTable, OrderHeaderCard, OrderTable, OrderCard } from './OrderItem';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import Config from '../../config/config';

import {default as commonStylesF} from '../../styles/common';
import {default as commonStylesMobileF} from '../../styles/common_mobile';
import {default as balanceStylesF} from '../../styles/balance';
import {default as balanceStylesMobileF} from '../../styles/balance_mobile';
import {default as listStylesF} from '../../styles/list';


const UserCashbackOrders = ({navigation, route}) => {
  const {
    user,
    logout,
    t,
    screenWidth,
    theme,
    i18n,
    screenParams,
    setScreenParams,
    addHistory
  } = useContext(AuthContext);
  const [refOrders, setRefOrders] = useState([]);
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [isLoading, setIsLoading] = useState();
  const [info, setInfo] = useState(null);
  const [isError, setIsError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  const [withdraw, setWithdraw] = useState('');
  const [userOrder, setUserOrder] = useState(screenParams?.userOrder);
  // console.log('userOrder', userOrder);
  const OrderHeader = screenWidth < 700 ? OrderHeaderCard : OrderHeaderTable;
  const Order = screenWidth < 700 ? OrderCard : OrderTable;

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);
  const balanceStyles =
    screenWidth < 1080
      ? balanceStylesMobileF(wp, hp, theme)
      : balanceStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  const doWithdraw = async () => {
    setIsLoading(true);
    const api = AxiosInstance({user, logout});
    await api('/api/v1/withdraw', {
      method: 'POST',
      mode: 'cors',
      data: {
        amount: parseFloat(withdraw),
        program: 'cashback',
        order_id: userOrder?.id,
      },
    })
      .then(response => {
        if (response.status === 201) {
          setWithdraw(0);
          setUserOrder((prevState) => {
            return {...prevState, cashback_status: 'paid'}
          });
          setInfo(t('withdraw_request_sended'));
        } else if (response.status === 'error') {
          setIsError(t('somthing_wrong'));
          console.log(response);
        }
      })
      .catch(error => {
        setIsError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setModalVisible(false);
      });
  };

  useEffect(() => {
    const getRefOrders = async () => {
      if (!userOrder?.id) {
        setIsError(t('empty_order_id'));
        return false;
      }
      setIsLoading(true);
      const api = AxiosInstance({user, logout});
      await api(`${Config.API_URL}/api/v1/cashback/${userOrder.id}/orders?order_field=${sortField}&order=${sortDirection}`, {
        method: 'GET',
        mode: 'cors',
      }).then(response => {
        if (response.status === 200) {
            setRefOrders(response.data.message);
        }
      }).catch(error => {
        setIsError(t('server_error'));
        console.log(error);
      }).finally(() =>{
        setIsLoading(false);
      });
    }

    getRefOrders();

  }, [user, logout, sortField, sortDirection, userOrder?.id, t]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  // if (isLoading) {
  //   return <Loader />
  // }
  if (!userOrder) {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.headerContainer}>
            <Pressable
              style={commonStyles.headBackButton}
              onPress={() => navigation.navigate('MyOrders')}>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={commonStyles.arrowLeft} />
              )}
            </Pressable>
          </View>
        </View>
        <Footer navigation={navigation}/>
      </ScrollView>
    );
  }
  return (
    <ScrollView contentContainerStyle={commonStyles.container}>
      <Header navigation={navigation} route={route} />
      <View style={commonStyles.contentContainer}>
        <View style={commonStyles.headerContainer}>
          <Pressable
            style={commonStyles.headBackButton}
            onPress={() => navigation.navigate('MyOrders')}>
            {Platform.OS === 'web' ? (
              <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
            ) : (
              <ArrowLeft style={commonStyles.arrowLeft} />
            )}
          </Pressable>
          <View style={{...commonStyles.contentHead, height: 'auto'}}>
            <Text style={commonStyles.contentHeadText}>{t('cashback_orders')}</Text>
          </View>
        </View>
        <View style={{...styles.refInfo, minHeight: 350}}>
          <Text style={styles.refName}>
            {t('your_order')}
          </Text>
          <DataTable style={listStyles.dataTable}>
            <OrderHeader indicator={true} expired_at={true}/>
            <Order item={userOrder} indicator={true}/>
          </DataTable>
          <Text style={{...styles.refName, lineHeight: 80}}>
            {t('referal_orders')}
          </Text>
        </View>

        <DataTable style={listStyles.dataTable}>
          <OrderHeader
            sortField={sortField}
            sortDirection={sortDirection}
            setSortField={setSortField}
            setSortDirection={setSortDirection}
            name={true}
          />
          {refOrders && refOrders.map(
            item => (<Order item={item} key={item.id} />)
          )}
        </DataTable>
        <HStack style={balanceStyles.balanceFooterContainer}>
          <HStack style={balanceStyles.btnGroup}>
            {(userOrder?.cashback_status === 'completed' || userOrder?.cashback_status === 'closed') && (
            <Pressable
              style={balanceStyles.balanceBtnWithdraw}
              onPress={() => {
                setWithdraw(userOrder.cashback_calculated);
                setModalVisible(true);
              }}>
              <Text style={balanceStyles.btnTextWhite}>
                {t('do_withdraw_request')}
              </Text>
            </Pressable>
            )}
          </HStack>
        </HStack>
      </View>
      <Footer navigation={navigation}/>
      {modalVisible && (
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : ''}>
          <Modal
            animationType="none"
            transparent={true}
            visible={true}
            onRequestClose={() => {
              setModalVisible(false);
            }}
            style={{alignItems: 'center', justifyContent: 'center'}}>
            <View
              style={{
                ...balanceStyles.modalContainer,
                marginBottom: isShowKeyboard ? 20 : 150,
              }}>
              <View style={balanceStyles.modalContent}>
                <View style={balanceStyles.modalHead}>
                  <Pressable onPress={() => setModalVisible(false)}>
                    <Image
                      style={balanceStyles.iconClose}
                      source={require('../../assets/img/icon_close.png')}
                    />
                  </Pressable>
                </View>

                <View style={balanceStyles.modalBody}>
                  <Text style={balanceStyles.modalCaption}>
                    {capitalizeFirstLetter(t('withdraw'))}
                  </Text>
                  <View style={balanceStyles.inputContainer}>
                    <Text style={balanceStyles.sumLabel}>{t('sum')}</Text>
                    <TextInputMask
                      type={'only-numbers'}
                      style={balanceStyles.sumInput}
                      onChangeText={text => setWithdraw(text)}
                      // value={balance.balance.curr_balance}
                      readOnly={true}
                      value={withdraw}
                      onFocus={() => setIsShowKeyboard(true)}
                      isFocused={true}
                    />
                  </View>
                  <HStack style={balanceStyles.modalBtnGroup}>
                    <Pressable
                      style={balanceStyles.modalBtn}
                      onPress={() => {
                        setModalVisible(false);
                        setIsShowKeyboard(false);
                      }}>
                      <Text style={balanceStyles.btnTextWhite}>
                        {t('cancel')}
                      </Text>
                    </Pressable>
                    <Pressable
                      style={balanceStyles.modalBtn}
                      onPress={() => {
                        doWithdraw();
                        setIsShowKeyboard(false);
                      }}>
                      <Text style={balanceStyles.btnTextWhite}>
                        {t('do_withdraw_request')}
                      </Text>
                    </Pressable>
                  </HStack>
                  {/* <Text style={styles.error}>Errors: {errors ? JSON.stringify(errors) : ''}</Text> */}
                </View>
              </View>
            </View>
          </Modal>
        </KeyboardAvoidingView>
      )}
      {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      {info && <InfoModal info={info} setInfo={setInfo} />}
      {isLoading === true && (
          <ActivityIndicator
            style={styles.activityIndicator}
            color={theme.BLUE}
            size="large"
          />
        )}

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
  refInfo: {
    flex: 1,
    marginTop: 47,
    width: '90%',
    height: 40,
    gap: 11,
  },
  refName: {
    fontFamily: 'Inter',
    fontSize: 40,
    fontWeight: 'normal',
    lineHeight: 48,
    textAlign: 'center',
    width: '100%',
  }
});

export default UserCashbackOrders;