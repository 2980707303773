import { StyleSheet } from "react-native";

const styles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  head: {

  },
  headText: {
    flexWrap: 'wrap',
    paddingTop: 10,
  },
  content: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: '80%',
    maxWidth: 600,
  },
  select: {
    width: '80%',
    maxWidth: 400,
    marginVertical: 20,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  textInput: {
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    fontSize: 16,
    lineHeight: 16,
    height: 40,
  },
  textArea: {
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    height: 80,
    marginVertical: 25,
    fontSize: 16,
    lineHeight: 16,
  },
  buttonsBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 25,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: theme.BLUE,
    width: 120,
    padding: 10,
  },
  buttonText: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.WHITE,
    fontSize: 16,
  }
});

export default styles;