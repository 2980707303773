import React, { useContext, useEffect, useState } from 'react';
import { Modal, View, TextInput, Pressable, Text, StyleSheet } from 'react-native';
import {Picker} from '@react-native-picker/picker';

import AxiosInstance from './axiosInstance';

import { AuthContext } from '../AuthProvider';

import {default as stylesF} from '../../styles/form_modal';

const FormModal = ({ visible, onClose, onSubmit, sendVia, msgType }) => {
  const {user, logout, i18n, t, theme} = useContext(AuthContext);
  const [formData, setFormData] = useState({id: 0});
  const [messages, setMessages] = useState({});

  const styles = stylesF(theme);

  const handleInputChange = (key, value) => {
    setFormData(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
    onClose();
  };

  useEffect(() => {
    const loadMessages = async () => {

      const api = AxiosInstance({user, logout});
      let url = `/api/v1/messages`;
      await api(url, {
        method: 'GET',
        mode: 'cors',
      })
      .then(response => {
        // console.log('Messages response', response);
        setMessages(response.data.message);
      })
      .catch(error => {
        // console.log('Messages error', error);
      })
    }

    loadMessages();

  }, [user, logout]);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={() => {
        handleInputChange('subject', '');
        handleInputChange('message', '');
        handleInputChange('button_name', '');
        onClose();
      }}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.head}>
            <Text style={styles.header}>
              {t('invitations_send_header')}
            </Text>
            <Text style={styles.headText}>
              {t('invitations_send_text')}
            </Text>
          </View>
          <Picker
            enabled={true}
            style={styles.select}
            selectedValue={formData.id}
            onValueChange={value =>  {
                let selectedTemplate = messages.filter(item => item.id === parseInt(value));
                handleInputChange('id', value);

                if (selectedTemplate.length) {
                  handleInputChange('subject', selectedTemplate[0].announce);
                  handleInputChange('message', selectedTemplate[0].message);
                  if (sendVia === 'sms') {
                    handleInputChange('button_name', selectedTemplate[0].button_name ? selectedTemplate[0].button_name : 'PDParis');
                  }
                } else {
                  handleInputChange('subject', '');
                  handleInputChange('message', '');
                  handleInputChange('button_name', '');
                }
              }
            }
          >
            {messages && Object.keys(messages).length && (
              messages.filter(item =>
                item.channel === sendVia
                && item.lang === i18n.language
                && item.type === msgType
              ).map((message, index) => (
                <Picker.Item key={index} label={`${t('template')}  #${index + 1}`} value={message.id} />
              ))
            )}
            <Picker.Item key={-1} label={t('my_variant')} value={0} />
          </Picker>
          <TextInput
            value={formData.subject}
            onChangeText={text => handleInputChange('subject', text)}
            style={styles.textInput}
          />
          <TextInput
            value={formData.message}
            onChangeText={text => handleInputChange('message', text)}
            multiline
            numberOfLines={5}
            style={styles.textArea}
          />
          <View style={styles.buttonsBox}>
            <Pressable
              onPress={() => {
                handleInputChange('id', 0);
                handleInputChange('subject', '');
                handleInputChange('message', '');
                handleInputChange('button_name', '');
                onClose();
              }}
              style={styles.button}
            >
              <Text style={styles.buttonText}>{t('cancel')}</Text>
            </Pressable>
            <Pressable
              onPress={handleSubmit}
              style={styles.button}
            >
              <Text style={styles.buttonText}>{t('send')}</Text>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default FormModal;
