import React, {useContext, useState, useEffect} from 'react';
import {
  View,
  Text,
  TextInput,
  Pressable,
  ActivityIndicator,
  Platform,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  TouchableWithoutFeedback,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import {HStack} from 'native-base';
import axios from 'axios';

import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {validateEmail} from '../../components/common/commonFunctions';

import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';


import {default as commonStylesMobile} from '../../styles/common_mobile';
import {default as stylesF} from '../../styles/reset_password';

import {t} from 'i18next';
import {AuthContext} from '../../components/AuthProvider';

const ResetPassword = ({navigation, route}) => {
  const {addHistory, inputedData, setInputedData, i18n, theme, Config} = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState('');
  const [tmpJwt, setTmpJwt] = useState('');
  const [userId, setUserId] = useState(0);
  const [isShowKeyboard, setIsShowKeyboard] = useState(true);

  const styles = stylesF(wp, theme);
  const commonStyles = commonStylesMobile(wp, hp, theme);

  const sendVerificationCode = async () => {
    if (!validateEmail(email)) {
      setError(t('error_incorrect'));
      return false;
    }
    setInputedData(prevState => {return {...prevState, email: email}});
    setLoading(true);

    // console.log('phone: ', phone);
    await axios
      .post(
        `${Config.API_URL}/api/users/send_code`,
        {
          email: email,
          phone: inputedData.phone,
          lang: i18n.language,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        // console.log('send code response', response);
        if (response.data && response.data.message.result === true) {
          navigation.navigate('ResetPasswordCodeVerification');
        } else if (response.data && response.data.message.result === false) {
          setError(response.data.message.error);
        }
      })
      .catch(error => {
        console.log('send code error', error);
        if (error.response !== undefined) {
          setError(error.response.data.message.error);
        } else {
          setError(t('server_error'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const keyboardHide = () => {
    setIsShowKeyboard(false);
    Keyboard.dismiss();
  };

  const keyboardDismiss = () => {
    if (Platform.OS === 'ios') {
      Keyboard.dismiss();
    }
    return false;
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory('ResetPassword');
    }
  }, [addHistory]);

  return (
    <TouchableWithoutFeedback onPress={() => keyboardDismiss()}>
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={false} route={false} />
        <View style={commonStyles.contentContainer}>
          <View
            style={
              Platform.OS === 'ios' ? styles.iosContentHead : styles.contentHead
            }>
            <Text
              style={
                Platform.OS === 'ios'
                  ? styles.iosContentHeadText
                  : commonStyles.contentHeadText
              }>
              {t('password_restoring')}
            </Text>
          </View>
          <KeyboardAvoidingView
            // behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={styles.content}>
            <View
              style={
                Platform.OS === 'ios' ? styles.iosContent : styles.content
              }>
              {Platform.OS !== 'ios' ? (
                <View style={styles.passwordBlock}>
                  <Text style={styles.infoText}>
                    {t('enter_email_for_password')}
                  </Text>
                  <Text style={styles.label}>{t('enter_email')}*</Text>
                  <TextInput
                    type={'text'}
                    placeholder="Email"
                    value={email}
                    onChangeText={text => setEmail(text)}
                    style={styles.phoneInput}
                  />
                  <HStack style={styles.btnGroup}>
                    <Pressable
                      onPress={() =>
                        Platform.OS === 'web'
                        ? navigation.push('PasswordVerifying', {email: email})
                        : navigation.navigate('PasswordVerifying', {email: email})
                      }
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('back')}</Text>
                    </Pressable>
                    <Pressable
                      onPress={sendVerificationCode}
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('send')}</Text>
                    </Pressable>
                  </HStack>
                </View>
              ) : (
                <View
                  style={{
                    ...styles.iosContent,
                    marginBottom: isShowKeyboard ? 250 : 170,
                  }}>
                  <Text style={styles.infoText}>
                    {t('enter_email_for_password')}
                  </Text>
                  <Text style={styles.label}>{t('enter_email')}*</Text>
                  <TextInput
                    type={'text'}
                    placeholder="Emai"
                    value={email}
                    onChangeText={text => setEmail(text)}
                    style={styles.phoneInput}
                    onFocus={() => setIsShowKeyboard(false)}
                  />
                  <View
                    style={
                      Platform.OS === 'ios'
                        ? styles.iosBtnGroup
                        : styles.btnGroup
                    }>
                    <Pressable
                      onPress={() => {
                        navigation.navigate('PasswordVerifying');
                        keyboardHide;
                      }}
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('back')}</Text>
                    </Pressable>
                    <Pressable
                      onPress={sendVerificationCode}
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('send')}</Text>
                    </Pressable>
                  </View>
                </View>
              )}
            </View>
          </KeyboardAvoidingView>
        </View>
        <Footer />
        {error ? <ErrorModal error={error} setIsError={setError} /> : <></>}
        {info ? <InfoModal info={info} setInfo={setInfo} /> : <></>}
        {loading === true && (
          <ActivityIndicator
            style={styles.activityIndicator}
            color="#0C090D"
            size="large"
          />
        )}
      </ScrollView>
    </TouchableWithoutFeedback>
  );
};

export default ResetPassword;
