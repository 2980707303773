import React, {useEffect, useState, useContext, useCallback} from 'react';
import {Pressable, View, ScrollView, Image, Platform} from 'react-native';
import {Text, HStack} from 'native-base';
import {SegmentedButtons, DataTable} from 'react-native-paper';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import {
  TrioTable,
  TriosHeaderTable,
  TrioCard,
  TriosHeaderCard,
} from './TrioItem';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import {default as commonStylesF} from '../../styles/common';
import {default as commonStylesMobileF} from '../../styles/common_mobile';
import {default as listStylesF} from '../../styles/list';
import {default as triosStylesF} from '../../styles/trios';
import IOSCustomPagination from '../../components/common/IOSCustomPagination';
import {default as trioStylesMobileF} from '../../styles/trios_mobile';

const TriosList = ({navigation, route, status, setStatus, trioId}) => {
  const {user, logout, breadcrumbs, setBreadcrumbs, t, screenWidth, theme, addHistory, currency} =
    useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [triosList, setTriosList] = useState({});
  const [triosStat, setTriosStat] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  // const [status, setStatus] = useState('waiting');
  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);
  const triosStyles =
    screenWidth < 1080
      ? trioStylesMobileF(wp, hp, theme)
      : triosStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  let TriosHeader = screenWidth < 700 ? TriosHeaderCard : TriosHeaderTable;
  let Trio = screenWidth < 700 ? TrioCard : TrioTable;

  const getTrios = useCallback(async () => {
    setIsLoading(true);
    const api = AxiosInstance({user, logout});
    let url = `/api/v1/trios?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`;
    if (status !== '') {
      url += `&status=${status}`;
    }
    await api(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        let _from = 0;
        let _to = 0;
        setTriosList(response.data.message.data);
        setPagination(response.data.message.pagination);
        setTriosStat(response.data.message.stat);
        _from =
          response.data.message.pagination.totalRows > 0
            ? (currentPage - 1) * response.data.message.pagination.perPage + 1
            : 0;
        _to =
          response.data.message.pagination.totalRows >
          currentPage * response.data.message.pagination.perPage
            ? currentPage * response.data.message.pagination.perPage
            : response.data.message.pagination.totalRows;
        setFrom(_from);
        setTo(_to);
      })
      .catch(error => {
        setIsError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, user, logout, sortField, sortDirection, status]);

  useEffect(() => {
    if (navigation.isFocused()) {
      if (breadcrumbs.length) {
        setBreadcrumbs([]);
      }
      getTrios();
    }
  }, [getTrios, navigation, breadcrumbs, setBreadcrumbs]);

  useEffect(() => {
    setPrevScreenWidth(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.headerContainer}>
            <Pressable
              style={commonStyles.headBackButton}
              onPress={() => navigation.navigate('Profile')}>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={commonStyles.arrowLeft} />
              )}
            </Pressable>
            <View style={commonStyles.contentHead}>
              <Text style={commonStyles.contentHeadText}>{t('trios')}</Text>
            </View>
          </View>
          <View style={triosStyles.trioStats}>
            <View style={triosStyles.trioStatBlack}>
              <Text style={triosStyles.trioStatTxt1}>{t('total_sum')}</Text>
              <Text style={triosStyles.trioStatTxt2}>
                {triosStat.completed
                  ? triosStat.completed.total_sum + triosStat.waiting.total_sum
                  : '?'}
              </Text>
              <Text style={triosStyles.trioStatTxt3}>{t(currency)}</Text>
            </View>
            <View style={triosStyles.trioStatBlue}>
              <Text style={triosStyles.trioStatTxt1}>{t('waiting_sum')}</Text>
              <Text style={triosStyles.trioStatTxt2}>
                {triosStat.waiting ? triosStat.waiting.total_sum : '?'}
              </Text>
              <Text style={triosStyles.trioStatTxt3}>{t(currency)}</Text>
            </View>
            <View
              style={
                screenWidth >= 480
                  ? triosStyles.trioStatBlack
                  : triosStyles.trioStatBlue
              }>
              <Text style={{...triosStyles.trioStatTxt1}}>
                {t('reward_sum')}
              </Text>
              <Text style={triosStyles.trioStatTxt2}>
                {triosStat.completed ? triosStat.completed.total_reward : '?'}
              </Text>
              <Text style={triosStyles.trioStatTxt3}>{t(currency)}</Text>
            </View>
            <View
              style={
                screenWidth >= 480
                  ? triosStyles.trioStatBlue
                  : triosStyles.trioStatBlack
              }>
              <Text style={triosStyles.trioStatTxt1}>{t('last_update')}</Text>
              <Text style={triosStyles.trioStatTxt2}>
                {triosStat.last_update ? triosStat.last_update : ''}
              </Text>
            </View>
          </View>

          <SegmentedButtons
            style={triosStyles.segmentButtons}
            theme={{
              colors: {
                secondaryContainer: theme.BLACK,
                onSecondaryContainer: theme.FONT_COLOR_WHITE,
              },
            }}
            value={status}
            onValueChange={value => {
              setCurrentPage(1);
              setStatus(value);
            }}
            buttons={[
              {
                value: 'completed',
                label: t('trio_completed'),
                // style: {paddingLeft: 0, paddingRight: 0},
              },
              {
                value: 'waiting',
                label: t('trio_waiting'),
                // style: {paddingLeft: 13, paddingRight: 13},
              },
              {
                value: '',
                label: t('trio_all'),
                // style: {paddingLeft: 13, paddingRight: 13},
              },
            ]}
          />
          {Platform.OS === 'ios' ? (
            // Кастомна пагінація і рендеринг даних для iOS
            <View style={listStyles.dataTable}>
              <TriosHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
              {triosList.map(item => (
                <Trio item={item} key={item.id} />
              ))}
              <IOSCustomPagination
                currentPage={currentPage}
                totalPages={Math.ceil(pagination.totalPages)}
                onPageChange={newPage => setCurrentPage(newPage)}
                label={`${from}-${to} of ${pagination.totalRows}`}
              />
            </View>
          ) : (
            // Стандартна пагінація для інших платформ
            <DataTable style={listStyles.dataTable}>
              <TriosHeader
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                sortField={sortField}
                setSortField={setSortField}
              />

              {triosList.map(item => (
                <Trio item={item} navigation={navigation} key={item.id}/>
              ))}

              <DataTable.Pagination
                page={currentPage - 1}
                numberOfPages={Math.ceil(pagination.totalPages)}
                onPageChange={page => setCurrentPage(page + 1)}
                label={`${from}-${to} of ${pagination.totalRows}`}
                numberOfItemsPerPage={pagination.perPage}
                showFastPaginationControls
              />
            </DataTable>
          )}
        </View>
        <Footer navigation={navigation}/>
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </ScrollView>
    );
  }
};

export default TriosList;
