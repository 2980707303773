import {StyleSheet} from 'react-native';

const styles = (wp, theme) =>
  StyleSheet.create({
    headerContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 45,
      width: wp('100%'),
      maxWidth: 1080,
      backgroundColor: theme.BLUE,
    },
    headerLinksContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      gap: 15,
      // position: 'absolute',
      zIndex: 1001,
    },
    headerLogoContainer: {
      // width: 100,
      height: 40,
      left: 15,
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    headerLogoText: {
      fontFamily: theme.FONT_FAMILY_BOLD,
      color: theme.FONT_COLOR_WHITE,
      fontSize: 22,
      // fontWeight: '600',
    },
    headerUser: {
      width: 35,
      height: 35,
    },
    statLabel: {
      color: '#000',
      fontFamily: 'Inter',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 19,
    },
    selected: {
      color: 'rgb(25, 36, 200)',
    },
    langButtons: {
      width: 120,
      height: 'auto',
      alignSelf: 'center',
      gap: 1,
    },
    langButton: {
      color: theme.FONT_COLOR_WHITE,
      backgroundColor: theme.BLUE,
      fontFamily: theme.FONT_FAMILY_MEDIUM,
      fontSize: 12,
      borderWidth: 2,
      borderColor: 'black',
    },
    activeLang: {
      borderWidth: 1,
      backgroundColor: '#8b93ff',
    },
    nagationBox: {
      width: 190,
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: 2,
    },
    menuItemText: {
      fontFamily: theme.FONT_FAMILY_MEDIUM,
      fontSize: 20,
      color: theme.FONT_COLOR_BLACK,
    },
  });

export default styles;
