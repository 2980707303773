import React, {useContext} from 'react';
import {Pressable, Platform} from 'react-native';
import {Text, HStack} from 'native-base';
import {Card, Avatar, DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {AuthContext} from '../../components/AuthProvider';
import {useNavigation} from '@react-navigation/native'; // Для використання спеціально на iOS

import {default as listStylesF} from '../../styles/list';
import {default as triosStylesF} from '../../styles/trios_mobile';

const TriosHeaderTable = props => {
  const {t, theme} = useContext(AuthContext);
  const triosStyles = triosStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };
  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title
        style={triosStyles.itemCell}
        sortDirection={
          props.sortDirection === 'desc' ? 'descending' : 'ascending'
        }>
        <Pressable
          onPress={() => {
            dateSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText}}>{t('date')}</Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('orders_sum')}</Text>
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('reward_sum')}</Text>
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('last_order')}</Text>
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('description')}</Text>
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('status')}</Text>
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('orders')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const TrioTable = ({item, navigation}) => {
  const {user, t, theme, currency} = useContext(AuthContext);
  // const { pushBreadcrumb } = useNavigation();
  const triosStyles = triosStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  const showTrioDetails = item_id => {
    if (Platform.OS !== 'web') {
      navigation.navigate('Trios', {id: item_id});
    } else {
      navigation.push('Trios', {id: item_id});
    }
  };
  return (
    <DataTable.Row
      key={item.id}
      style={listStyles.itemRow}
      onPress={() => {
        user.role !== 0
          ? showTrioDetails(item.id)
          : console.log('trio ' + item.id);
      }}>
      <DataTable.Cell style={triosStyles.itemCell}>
        {/* {Platform.OS === 'web' && (
          <Image
            source={require('../../assets/img/three_icons.png')}
            style={triosStyles.threeIcon}
          />
        )} */}
        <Text
          style={[
            listStyles.itemCol,
            Platform.OS === 'ios' ? {fontSize: 12} : {},
          ]}>
          {item.created_at.substr(0, 10)}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item.total_sum} {t(currency)}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item.reward_sum}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>----</Text>
      </DataTable.Cell>
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item.description}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text
          style={[
            listStyles.itemCol,
            Platform.OS === 'ios' ? {fontSize: 13} : {},
          ]}>
          {item.status === 'completed'
            ? t('trio_completed')
            : t('trio_waiting')}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item.orders}/3</Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const TriosHeaderCard = props => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const triosStyles = triosStylesF(wp, hp, theme);

  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title
        style={triosStyles.itemCell}
        sortDirection={
          props.sortDirection === 'desc' ? 'descending' : 'ascending'
        }>
        <Pressable
          onPress={() => {
            dateSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText}}>{t('date')}</Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const TrioCard = ({item, navigation: propNavigation}) => {
  const {t, user, theme, currency} = useContext(AuthContext);
  const iosNavigation = useNavigation();
  let triosStyles = triosStylesF(wp, hp, theme);

  const showTrioDetails = item_id => {
    const navigation = Platform.OS === 'ios' ? iosNavigation : propNavigation;
    if (Platform.OS !== 'web') {
      navigation.navigate('Trios', {id: item_id});
    } else {
      navigation.push('Trios', {id: item_id});
    }
  };

  const Right = () => (
    <Avatar.Text
      label={`${item.orders}/3`}
      size={55}
      style={{
        borderRadius: 5,
        justifyContent: 'center',
      }}
      labelStyle={{
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 24,
      }}
      color={theme.FONT_COLOR_WHITE}
      theme={{
        colors: {
          primary: theme.LIGHT_BLUE,
        },
      }}
    />
  );
  const title = `${t('orders_sum')}: ${item.total_sum} ${t(currency)}`;
  const subtitle = `${t('reward_sum')}: ${item.reward_sum} ${t(currency)}`;

  return (
    <DataTable.Row
      key={item.id}
      style={{width: wp('90%'), alignItems: 'center'}}>
      <Card
        mode={'outlined'}
        style={triosStyles.cardStyle}
        onPress={() => {
          user.role !== 0
            ? showTrioDetails(item.id)
            : console.log('trio ' + item.id);
        }}>
        <Card.Title
          title={title}
          subtitle={subtitle}
          right={Right}
          titleStyle={triosStyles.cardTitleStyle}
          subtitleStyle={triosStyles.cardSubtitleStyle}
        />
        <Card.Content>
          <Text style={triosStyles.cardContentStyle}>
            {t('date')} {item.created_at.substr(0, 10)}
          </Text>
          <Text style={triosStyles.cardContentStyle}>{item.description}</Text>
        </Card.Content>
      </Card>
    </DataTable.Row>
  );
};

export {TrioTable, TriosHeaderTable, TrioCard, TriosHeaderCard};
