import React, {useState, useContext, useEffect, useCallback} from 'react';
import {
  Text,
  View,
  ScrollView,
  Pressable,
  KeyboardAvoidingView,
  ActivityIndicator,
  Image,
  Platform,
  Modal,
  StyleSheet,
} from 'react-native';
import {Card, Badge, DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import { ReferalHeaderCard, ReferalHeaderTable, ReferalCard, ReferalTable } from './ReferalItem';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import {default as commonStylesF} from '../../styles/common';
import {default as commonStylesMobileF} from '../../styles/common_mobile';
import {default as listStylesF} from '../../styles/list';
import {default as balanceStylesF} from '../../styles/balance';
import {default as balanceStylesMobileF} from '../../styles/balance_mobile';
import IOSCustomPagination from '../../components/common/IOSCustomPagination';
import Config from '../../config/config';

const Cashback = ({navigation, route}) => {
  const {user, logout, t, screenWidth, theme, i18n, setScreenParams, addHistory, currency} = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState(null);
  const [referals, setReferals] = useState([]);
  const [ordersStat, setOrdersStat] = useState({});
  const [refOrdersStat, setRefOrdersStat] = useState({});
  const [stat, setStat] = useState({
    available: 0,
    available_withdraw: 0,
    waiting_withdraws: 0,
    completed_withdraws: 0,
    deficit: 0,
    proficit: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);
  const balanceStyles =
    screenWidth < 1080
      ? balanceStylesMobileF(wp, hp, theme)
      : balanceStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  let ReferalHeader = screenWidth < 700 ? ReferalHeaderCard : ReferalHeaderTable;
  let Referal = screenWidth < 700 ? ReferalCard : ReferalTable;

  useEffect(() => {
    console.log('useEffect');
    const getReferals = async () => {
      setIsLoading(true);
      const api = AxiosInstance({user, logout});
      await api(`${Config.API_URL}/api/v1/cashback?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`, {
        method: 'GET',
        mode: 'cors',
      }).then(response => {
        let _from = 0;
        let _to = 0;
        if (response.status === 200) {
          setReferals(response.data.message.referals.data);
          setOrdersStat(response.data.message.user_orders);
          setRefOrdersStat(response.data.message.ref_orders);
          setStat({
            available: response.data.message.available,
            available_withdraw: response.data.message.available_withdraw,
            waiting_withdraws: response.data.message.waiting_withdraws,
            completed_withdraws: response.data.message.completed_withdraws,
            deficit: response.data.message.deficit,
            proficit: response.data.message.proficit,
          });
          setPagination(response.data.message.referals.pagination);
          _from =
            response.data.message.referals.pagination.totalRows > 0
              ? (currentPage - 1) *
                  response.data.message.referals.pagination.perPage +
                1
              : 0;
          _to =
            response.data.message.referals.pagination.totalRows >
            currentPage * response.data.message.referals.pagination.perPage
              ? currentPage *
                response.data.message.referals.pagination.perPage
              : response.data.message.referals.pagination.totalRows;
          setFrom(_from);
          setTo(_to);
          // console.log('REFERALS', response.data.message.available);
        }
      }).catch(error => {
        console.log(error);
      }).finally(() =>{
        setIsLoading(false);
      });
    };

    getReferals();
  }, [user, logout, currentPage, sortField, sortDirection]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);
  if (isLoading) {
    return <Loader />
  } else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.headerContainer}>
            <Pressable
              style={commonStyles.headBackButton}
              onPress={() => navigation.navigate('Profile')}>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={commonStyles.arrowLeft} />
              )}
            </Pressable>
            <View style={commonStyles.contentHead}>
              <Text style={commonStyles.contentHeadText}>{t('cashback')}</Text>
            </View>
          </View>
          <View style={balanceStyles.cashbackStats}>
            <View style={balanceStyles.cashbackStatBlack}>
              <Text style={balanceStyles.cashbackStatTxt1}>{t('your_orders_sum')}</Text>
              <Text style={balanceStyles.cashbackStatTxt2}>
                {ordersStat.cash ? ordersStat.cash.total_sum : 0} {t(currency)}
              </Text>
              {/* <Text style={balanceStyles.cashbackStatTxt3}>{t(currency)}</Text> */}
              <Text style={balanceStyles.cashbackStatTxt3}>{t('referals_orders_sum')}</Text>
              <Text style={balanceStyles.cashbackStatTxt2}>
                {refOrdersStat.cash ? refOrdersStat.cash.total_sum : 0} {t(currency)}
              </Text>
              {/* <Text style={balanceStyles.cashbackStatTxt3}>{t(currency)}</Text> */}
            </View>
            <View style={balanceStyles.cashbackStatBlue}>
              <Text style={balanceStyles.cashbackStatTxt1}>{t('infobox_cashback')}</Text>
              <Text style={balanceStyles.cashbackStatTxt2}>
                {refOrdersStat.cash ? refOrdersStat.cash.total_sum : 0} {t(currency)}
              </Text>
              <Text style={balanceStyles.cashbackStatTxt3}>{t('admin_withdraw_requests')} / {t('withdraw_completed')}</Text>
              <Text style={balanceStyles.cashbackStatTxt2}>
                {stat.waiting_withdraws} / {stat.completed_withdraws} {t(currency)}
              </Text>
              {/* <Text style={balanceStyles.cashbackStatTxt3}>{t(currency)}</Text> */}
            </View>
            <View
              style={
                screenWidth >= 480
                  ? balanceStyles.cashbackStatBlack
                  : balanceStyles.cashbackStatBlue
              }>
              <Text style={balanceStyles.cashbackStatTxt1}>{t('available_shop_cashback')}</Text>
              <Text style={balanceStyles.cashbackStatTxt2}>
                {stat.available} {t(currency)}
              </Text>
              <Text style={balanceStyles.cashbackStatTxt3}>{t('available_withdraw_cashback')}</Text>
              <Text style={balanceStyles.cashbackStatTxt2}>
                {stat.available_withdraw} {t(currency)}
              </Text>
              {/* <Text style={balanceStyles.cashbackStatTxt3}>{t(currency)}</Text> */}
            </View>
            <View
              style={
                screenWidth >= 480
                  ? balanceStyles.cashbackStatBlue
                  : balanceStyles.cashbackStatBlack
              }>
              <Text style={balanceStyles.cashbackStatTxt1}>{t('rest_for_cashback100')}</Text>
              <Text style={balanceStyles.cashbackStatTxt2}>
                {stat.deficit} {t(currency)}
              </Text>
              <Text style={balanceStyles.cashbackStatTxt3}>{t('over_cashback')}</Text>
              <Text style={balanceStyles.cashbackStatTxt2}>
                {stat.proficit} {t(currency)}
              </Text>
            </View>
          </View>
          {Platform.OS === 'ios' ? (
            // Кастомна пагінація і рендеринг даних для iOS
            <View style={listStyles.dataTable}>
              <ReferalHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
              {referals.data.map(item => (
                <Referal item={item} key={item.id} />
              ))}
              <IOSCustomPagination
                currentPage={currentPage}
                totalPages={Math.ceil(pagination.totalPages)}
                onPageChange={newPage => setCurrentPage(newPage)}
                label={`${from}-${to} of ${pagination.totalRows}`}
              />
            </View>
          ) : (
            // Стандартна пагінація для інших платформ
            <DataTable style={listStyles.dataTable}>
              <ReferalHeader
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                sortField={sortField}
                setSortField={setSortField}
              />

              {referals && referals.map(item => (
                <Referal item={item} navigation={navigation} key={item.id}/>
              ))}

              <DataTable.Pagination
                page={currentPage - 1}
                numberOfPages={Math.ceil(pagination.totalPages)}
                onPageChange={page => setCurrentPage(page + 1)}
                label={`${from}-${to} of ${pagination.totalRows}`}
                numberOfItemsPerPage={pagination.perPage}
                showFastPaginationControls
              />
            </DataTable>
          )}
        </View>
        <Footer navigation={navigation} />
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
        {info && <InfoModal info={info} setInfo={setInfo} />}
        {isLoading === true && (
            <ActivityIndicator
              style={styles.activityIndicator}
              color={theme.BLUE}
              size="large"
            />
          )}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  }
});

export default Cashback;