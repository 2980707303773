const ENV = process.env.NODE_ENV;
const DEV_ENV = {
  ENV_NAME: 'dev',
  SHOP_MAIN_DOMAIN: 'pdparis.com',
  // API_URL: 'http://192.168.0.101:8082',
  API_URL: 'http://localhost:8082',
  // API_URL: 'https://pdparis.chronisto.com',
  APP_NAME: 'PDAPARIS_DEV',
  GOOGLE_CLIENT_ID: '281249292700-97h5dol9hn64uvut0tkr2gc3iahoitdl.apps.googleusercontent.com',
  // GOOGLE_API_REDIRECT_URL: 'http://localhost:3000',
  GOOGLE_API_REDIRECT_URL: 'https://pdparis.teramizu.com',
  GOOGLE_API_CLIENTSECRET: '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',
  MAX_SMS: 2,
  MAX_EMAILS: 10,
  CONTACTS_RELOAD_AFTER_DAYS: 7,
  CURRENCY: "UAH",
};
const PROD_ENV = {
  ENV_NAME: 'production',
  SHOP_MAIN_DOMAIN: 'pdparis.com',
  API_URL: 'https://pdparis.chronisto.com',
  APP_NAME: 'PDAPARIS_PROD',
  GOOGLE_CLIENT_ID: '281249292700-97h5dol9hn64uvut0tkr2gc3iahoitdl.apps.googleusercontent.com',
  GOOGLE_API_REDIRECT_URL: 'https://pdparis.chronisto.com',
  GOOGLE_API_CLIENTSECRET: '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',
  MAX_SMS: 5,
  MAX_EMAILS: 100,
  CONTACTS_RELOAD_AFTER_DAYS: 7,
  CURRENCY: "UAH",
};

const Config = ENV === 'production' ? PROD_ENV : DEV_ENV;

export default Config;
