import React, {useContext} from 'react';
import {Pressable, Platform} from 'react-native';
import {Text, HStack} from 'native-base';
import {Card, DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {AuthContext} from '../../components/AuthProvider';
import {useNavigation} from '@react-navigation/native'; // Для використання спеціально на iOS
import BatteryIndicator from '../../components/cashbackIndicator/CashbackIndicator';
import {default as triosStylesF} from '../../styles/trios_mobile';
import {default as listStylesF} from '../../styles/list';

const OrderHeaderTable = (props) => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const triosStyles = triosStylesF(wp, hp, theme);

  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  return(
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title
        style={triosStyles.itemCell}
        sortDirection={
          props?.sortDirection
            ? props.sortDirection === 'desc' ? 'descending' : 'ascending'
            : null
        }>
        {props?.sortDirection ? (
          <Pressable
            onPress={() => {
              dateSort(props.sortDirection);
            }}>
            <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
              <Text style={{...listStyles.itemsHeaderText}}>{t('date')}</Text>
            </HStack>
          </Pressable>
        ) : (
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText}}>{t('date')}</Text>
          </HStack>
        )}
      </DataTable.Title>
      {props?.name && (
        <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
          <Text style={listStyles.itemsHeaderText}>{t('name')}</Text>
        </DataTable.Title>
      )}
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('total_sum')}</Text>
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('cashback')}</Text>
      </DataTable.Title>
      {props?.indicator && (
        <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
          <Text style={listStyles.itemsHeaderText}>{t('status')}</Text>
        </DataTable.Title>
      )}
      {props?.expired_at && (
        <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
          <Text style={listStyles.itemsHeaderText}>{t('cashback_expired')}</Text>
        </DataTable.Title>
      )}
    </DataTable.Header>
  );
};

const OrderTable = ({item, indicator}) => {
  const {t, theme, currency} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const triosStyles = triosStylesF(wp, hp, theme);
  const percentage = Math.floor(((item?.cashback_calculated || 0) / item.total_sum) * 100);

  return (
  <DataTable.Row
    key={item.id}
    style={listStyles.itemRow}
  >
    <DataTable.Cell style={triosStyles.itemCell}>
      {/* {Platform.OS === 'web' && (
        <Image
          source={require('../../assets/img/three_icons.png')}
          style={triosStyles.threeIcon}
        />
      )} */}
      <Text
        style={[
          listStyles.itemCol,
          Platform.OS === 'ios' ? {fontSize: 12} : {},
        ]}>
        {item.created_at.substr(0, 10)}
      </Text>
    </DataTable.Cell>
    {item?.name && (
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item.name}</Text>
      </DataTable.Cell>
    )}
    <DataTable.Cell style={triosStyles.itemCell}>
      <Text style={listStyles.itemCol}>{item.total_sum} {t(currency)}</Text>
    </DataTable.Cell>
    <DataTable.Cell style={triosStyles.itemCell}>
      <Text style={listStyles.itemCol}>{item.cashback_calculated ? item.cashback_calculated : 0} {t(currency)}</Text>
    </DataTable.Cell>
    {indicator && (
      <DataTable.Cell style={triosStyles.itemCell}>
        <BatteryIndicator percentage={percentage} status={item.cashback_status}/>
      </DataTable.Cell>
    )}
    {item?.expired_at && (
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item?.expired_at.substr(0, 10)}</Text>
      </DataTable.Cell>
    )}
  </DataTable.Row>
  );
};

const OrderHeaderCard = (props) => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const triosStyles = triosStylesF(wp, hp, theme);

  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title
        style={triosStyles.itemCell}
        sortDirection={
          props?.sortDirection
          ? props.sortDirection === 'desc' ? 'descending' : 'ascending'
          : null
        }>
        {props?.sortDirection && (
          <Pressable
            onPress={() => {
              dateSort(props.sortDirection);
            }}>
            <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
              <Text style={{...listStyles.itemsHeaderText}}>{t('date')}</Text>
            </HStack>
          </Pressable>
        )}
      </DataTable.Title>
    </DataTable.Header>
  );
};

const OrderCard = ({item}) => {
  const {t,theme, currency} = useContext(AuthContext);
  const iosNavigation = useNavigation();
  let triosStyles = triosStylesF(wp, hp, theme);

  const title = `${t('orders_sum')}: ${item.total_sum} ${t(currency)}`;
  const subtitle = `${t('cashback')}: ${item.cashback_calculated ? item.cashback_calculated : 0} ${t(currency)}`;

  return (
    <DataTable.Row
      key={item.id}
      style={{width: wp('90%'), alignItems: 'center'}}>
      <Card
        mode={'outlined'}
        style={triosStyles.cardStyle}
      >
        <Card.Title
          title={title}
          subtitle={subtitle}
          // right={Right}
          titleStyle={triosStyles.cardTitleStyle}
          subtitleStyle={triosStyles.cardSubtitleStyle}
        />
        <Card.Content>
          <Text style={triosStyles.cardContentStyle}>
            {t('date')} {item.created_at.substr(0, 10)}
          </Text>
        </Card.Content>
      </Card>
    </DataTable.Row>
  );
};

export {OrderHeaderTable, OrderHeaderCard, OrderTable, OrderCard}
