import { StyleSheet } from "react-native";

const styles = (hp, theme) => StyleSheet.create({
  container: {
    flex: 1,
    minHeight: hp('100%'),
    alignItems: 'center',
    backgroundColor: theme.WHITE,
  },

  contentContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 1080,
    paddingHorizontal: '6%',
  },
  headContainer: {
    width: '100%',
    maxWidth: 500,
    heigh: 'auto',
    paddingVertical: '6%',
  },
  headText: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: 40,
    lineHeight: 40,
    color: theme.FONT_COLOR_BLACK,
  },
  paragraph: {
    marginTop: 15,
    display: 'inline-block',
  },
  firstBlock: {
    width: '100%',
    maxWidth: 540,
  },
  secondBlock: {
    width: '100%',
    maxWidth: 700,
    marginTop: 50,
  },
  secondBlockText: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: 24,
    color: theme.FONT_COLOR_BLACK,
  },
  thirdBlock: {
    width: 340,
    marginTop: 50,
  },
  blockHeadText: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 24,
    marginBottom: 35,
  },
  formBlock: {
    marginTop: 50,
    width: '100%',
    maxWidth: 800,
  },
  formInputs: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 30,
  },
  input: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 16,
    lineHeight: 16,
    width: 250,
    height: 42,
  },
  inputMessage: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 16,
    lineHeight: 16,
    width: '100%',
    maxWidth: 530,
    height: 90,
  },
  button: {
    margin: 10,
    width: 140,
    height: 40,
    backgroundColor: theme.BLACK,
    fontFamily: theme.FONT_FAMILY,
    fontSize: 16,
    color: theme.FONT_COLOR_WHITE,
    marginLeft: 0,
  },
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
  lastBlock: {
    marginTop: 100,
    width: '100%',
    maxWidth: 800,
    marginBottom: 100,
  },
  lastBlockText: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 40,
  },
});

export default styles;