import { StyleSheet } from "react-native";

const styles = (wp, theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  contentHead: {
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: wp('100%'),
    maxWidth: 360,
    marginTop: 10,
    alignSelf: 'center',
  },
  buttonText: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_WHITE,
  },
  spinner: {
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  inputStyle: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    textAlign: 'center',
  },
  initials: {
    fontSize: 32,
    fontWeight: 'bold',
    color: 'white',
  },
  avatar: {
    borderRadius: 30,
    width: 55,
    height: 55,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactDetails: {},
  contactCon: {
    flex: 1,
    flexDirection: 'row',
    padding: 5,
    borderBottomWidth: 0.5,
    borderBottomColor: '#d9d9d9',
  },
  imgCon: {},
  placeholder: {
    width: 55,
    height: 55,
    borderRadius: 30,
    overflow: 'hidden',
    backgroundColor: '#d9d9d9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contactDat: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 5,
  },
  txt: {
    fontSize: 18,
  },
  name: {
    fontSize: 16,
  },
  phoneNumber: {
    color: '#888',
  },
  checkedContacts: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  headerButtons: {
    marginTop: 10,
    flexDirection: 'row',
    heigth: 40,
    gap: 3,
    alignItems: 'center',
    justifyContent: 'center',
    wordWrap: '',
  },
  headerButton: {
    height: 40,
    padding: 2,
    width: '25%',
    maxWidth: 90,
    borderWidth: 1,
    borderColor: '#000000',
    // backgroundColor: 'rgb(242, 242, 242)',
    backgroundColor: theme.BLACK,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    textAlign: 'center',
  },
  headerButtonReload: {
    height: 40,
    padding: 3,
    width: 40,
    borderWidth: 1,
    borderColor: '#000000',
    backgroundColor: 'rgb(242, 242, 242)',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    textAlign: 'center',
  },
  headerCheckBox: {
    height: 40,
    padding: 3,
    width: '20%',
    // alignItems: 'left',
    justifyContent: 'center',
    backgroundColor: 'rgb(242, 242, 242)',
    alignSelf: 'flex-start',
  },
  searchBarContainer: {
    backgroundColor: 'rgb(242, 242, 242)',
    borderBottomColor: '#FFF',
    borderTopColor: '#FFF',
  },
  searchBarInput: {
    backgroundColor: '#FFF',
  },
  searchBarLeftIcon: {
    backgroundColor: '#FFF',
  },
  searchBarRightIcon: {
    backgroundColor: '#FFF',
  },
  loader: {
    marginTop: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iosContentHead: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityIndicator: {
    color: "#3E3EFFBF",
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  }
});

export default styles;