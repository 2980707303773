// CurrencyPicker.js
import React, {useContext, useState, useRef, useEffect} from 'react';
import {View, Text, TextInput, StyleSheet} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import {currencies} from './Currencies';
import {AuthContext} from '../AuthProvider';

const CurrencyPicker = ({selectedCurrency, setSelectedCurrency}) => {
  const {t} = useContext(AuthContext);
  const [searchText, setSearchText] = useState('');

  const pickerRef = useRef();

  const filteredCurrencies = currencies.filter(
    currency =>
      currency.code.toLowerCase().includes(searchText.toLowerCase()) ||
      currency.name.toLowerCase().includes(searchText.toLowerCase())
  );

  // useEffect(() => {
  //   if (pickerRef.current) {
  //     console.log(pickerRef.current);
  //   }
  // }, [filteredCurrencies]);

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.searchInput}
        placeholder={t('search')}
        value={searchText}
        onChangeText={text => {
          if (selectedCurrency) {
            setSelectedCurrency(0);
          }
          setSearchText(text);
        }}
      />
      <Picker
        ref={pickerRef}
        selectedValue={selectedCurrency}
        onValueChange={value => setSelectedCurrency(value)}>
        <Picker.Item label={t('select')} value={0} />
        {filteredCurrencies.map(currency => (
          <Picker.Item label={currency.name} value={currency.code} />
        ))}
      </Picker>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: 300,
    padding: 20,
    justifyContent: 'center',
  },
  searchInput: {
    height: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    marginBottom: 20,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  selectedCurrency: {
    marginTop: 20,
    alignItems: 'center',
  },
});

export default CurrencyPicker;
