// LoginScreen.js
import React, {useContext, useEffect, useState, useRef} from 'react';
import {
  Platform,
  StyleSheet,
  View,
  Pressable,
  ScrollView,
  Text,
} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';
import {AuthContext} from '../components/AuthProvider';
import {TextInputMask} from 'react-native-masked-text';
import Ionicons from 'react-native-vector-icons/Ionicons';

import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import ResetPassword from './Login/ResetPassword';
import ResetPasswordCodeVerification from './Login/ResetPasswordCodeVerification';
import ResetPasswordUpdate from './Login/ResetPasswordUpdate';
import PrivacyPolicy from '../components/common/PrivacyPolicy';
import FirstPageWeb from './Profile/FirstPageWeb';
import FirstPageMobile from './Profile/FirstPageMobile';
import CodeVerifying from './Login/CodeVerifying';
import PasswordVerifying from './Login/PasswordVerifying';
import EmailCreating from './Login/EmailCreating';
import Contacts from '../components/common/Contacts';
import About from '../components/common/About';
import ErrorModal from '../components/common/ErrorModal';
import InfoModal from '../components/common/InfoModal';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Loader from '../components/common/Loader';
// import Empty from '../components/common/Empty';

import {default as commonStylesDesktop} from '../styles/common';
import {default as commonStylesMobile} from '../styles/common_mobile';
import {default as profileStylesDesktop} from '../styles/profile';
import {default as profileStylesMobile} from '../styles/profile_mobile';
import stylesF from '../styles/login';
// import {default as theme} from '../config/theme_config';

const LoginStack = createStackNavigator();

const LoginScreen = ({navigation, route}) => {
  const {
    sendSms,
    errors,
    inputedData,
    t,
    screenWidth,
    addHistory,
    theme,
  } = useContext(AuthContext);

  let fontSizeBig =
    Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 14) : 40;
  let fontSizeMd =
    Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 16) : 24;

  let commonStyles =
    screenWidth < 1080
      ? commonStylesMobile(wp, hp, theme)
      : commonStylesDesktop(wp, hp, theme);
  let profileStyles =
    screenWidth < 1080
      ? profileStylesMobile(wp, hp, fontSizeBig, fontSizeMd, theme)
      : profileStylesDesktop(wp, hp, fontSizeBig, fontSizeMd, theme);
  const styles = stylesF(wp, theme);

  const [phone, setPhone] = useState(inputedData.phone);
  const [password, setPassword] = useState(inputedData.password);
  const [isError, setIsError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState('');
  const [prevWidth, setPrevWidth] = useState(screenWidth);

  if (Platform.OS === 'web') {
    document.title = t('signin');
  }

  const hideLogoutButton = Platform.OS === 'ios';

  const handleSendSms = async () => {
    if (phone === '') {
      setIsError(t('enter_phone'));
      return;
    }
    setIsLoading(true);
    let result = await sendSms(phone);
    console.log('After send sms', result);

    if (result.result === false) {
      setIsLoading(false);
      setIsError(t(result.response));
      return false;
    }

    if (
      result.response !== undefined &&
      result.response.data.message.verified_email === true
    ) {
      // проверен email, вход по паролю
      console.log('Go to PasswordVerifying');
      Platform.OS === 'web'
        ? navigation.push('PasswordVerifying')
        : navigation.navigate('PasswordVerifying');

    } else if (
      result.response !== undefined &&
      result.response.data.message.verified_email === false
    ) {
      // нет или не проверен email, проверяем код из смс
      setPassword('');
      console.log('Go to CodeVerifying');
      Platform.OS === 'web'
        ? navigation.push('CodeVerifying', {phone: phone})
        : navigation.navigate('CodeVerifying', {phone: phone});
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsError(errors);
    setPrevWidth(screenWidth);
  }, [errors, screenWidth]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory('Login');
    }
  }, [addHistory]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View style={commonStyles.container}>
        {/* <ScrollView contentContainerStyle={profileStyles.scrollableContainer}> */}
        <ScrollView>
          <Header
            navigation={navigation}
            route={route}
            hideLogoutButton={hideLogoutButton}
          />

          <View style={profileStyles.profileContainer}>
            <View style={styles.contentHead}>
              <Text style={commonStyles.contentHeadText}>{t('login')}</Text>
            </View>
            <View style={profileStyles.profileContainer}>
              <TextInputMask
                type={'custom'}
                options={{
                  mask: '+99999 999 99 99',
                }}
                value={phone}
                onChangeText={text => setPhone(text)}
                style={styles.phoneInput}
                keyboardType="numeric"
              />
              <Text style={styles.labelBlack}>
                {t('phone_number_format')}
              </Text>
              <Pressable
                onPress={async () => {
                  console.log('Login click');
                  await handleSendSms();
                }}
                style={styles.buttonBig}>
                <Text style={styles.buttonText}>{t('log_in')}</Text>
              </Pressable>
              <View style={profileStyles.welcomeContainer}>
                <View style={profileStyles.welcome}>
                  <Text style={profileStyles.welcomeText}>
                    {t('welcome')}
                  </Text>
                  <Text style={profileStyles.welcomeText}>
                    {t('main_text_1')}
                  </Text>
                  <Text style={profileStyles.welcomeText}>
                    {t('main_text_2')}
                  </Text>
                  <Text style={profileStyles.welcomeBlock1}>
                    {t('main_text_3')}
                  </Text>
                  <Text style={profileStyles.welcomeBlock2}>
                    {t('advantages')}
                  </Text>
                </View>
              </View>
              <View style={profileStyles.advantages}>
                <View style={profileStyles.advantagesRow}>
                  <View style={profileStyles.advantagesCol}>
                    <Text style={profileStyles.advantagesHead}>
                      {t('overview')}
                    </Text>
                    <Text style={profileStyles.advantagesContent}>
                      {t('advantages_text')}
                    </Text>
                  </View>
                  <View style={profileStyles.advantagesCol}>
                    <Text style={profileStyles.advantagesHead}>
                      {t('promo')}
                    </Text>
                    <Text style={profileStyles.advantagesContent}>
                      {t('promo_text')}
                    </Text>
                  </View>
                </View>
                <View style={profileStyles.advantagesRow}>
                  <View style={profileStyles.advantagesCol}>
                    <Text style={profileStyles.advantagesHead}>
                      {t('settings_and_profile')}
                    </Text>
                    <Text style={profileStyles.advantagesContent}>
                      {t('settings_and_profile_text')}
                    </Text>
                  </View>
                  <View style={profileStyles.advantagesCol}>
                    <Text style={profileStyles.advantagesHead}>
                      {t('support')}
                    </Text>
                    <Text style={profileStyles.advantagesContent}>
                      {t('support_text')}
                    </Text>
                  </View>
                </View>
                <View style={profileStyles.marketLinks}>
                  <Text style={profileStyles.advantagesHead}>
                    {t('personal_links')}
                  </Text>
                  <Text style={profileStyles.advantagesContent}>
                    {t('personal_links_text')}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <Footer navigation={navigation} />
        </ScrollView>
        {isError ? (
          <ErrorModal error={isError} setIsError={setIsError} />
        ) : (
          <></>
        )}
        {info ? <InfoModal info={info} setInfo={setInfo} /> : <></>}
        {/* {Platform.OS !== 'web' && <Empty />} */}
      </View>
    );
  }
};

const LoginView = () => {
  const {t, screenWidth} = useContext(AuthContext);
  const FirstPage = screenWidth < 1080 ? FirstPageMobile : FirstPageWeb;
  return (
    <LoginStack.Navigator
      screenOptions={{
        headerShown: false,
        // freezeOnBlur: true,
        // unmountOnBlur: true,
      }}>
      <LoginStack.Screen
        name="FirstPage"
        component={FirstPage}
        options={{title: t('welcome_to_trio')}}
      />
      <LoginStack.Screen
        name="Login"
        component={LoginScreen}
        options={{title: t('signin')}}
      />
      <LoginStack.Screen
        name="CodeVerifying"
        component={CodeVerifying}
        options={{title: t('code_verifying')}}
      />
      <LoginStack.Screen
        name="PasswordVerifying"
        component={PasswordVerifying}
        options={{title: t('password_verifying')}}
      />
      <LoginStack.Screen
        name="EmailCreating"
        component={EmailCreating}
        options={{title: t('email_creating')}}
      />
      <LoginStack.Screen
        name="ResetPassword"
        component={ResetPassword}
        options={{title: t('reset_password')}}
      />
      <LoginStack.Screen
        name="ResetPasswordCodeVerification"
        component={ResetPasswordCodeVerification}
        options={{title: t('reset_password')}}
      />
      <LoginStack.Screen
        name="ResetPasswordUpdate"
        component={ResetPasswordUpdate}
        options={{title: t('reset_password')}}
      />
      <LoginStack.Screen
        name="PrivacyPolicy"
        component={PrivacyPolicy}
        options={{title: t('privacy_policy')}}
      />
      <LoginStack.Screen
        name="Contacts"
        component={Contacts}
        options={{title: t('contacts')}}
      />
      <LoginStack.Screen
        name="About"
        component={About}
        options={{title: t('about')}}
      />
    </LoginStack.Navigator>
  );
};

export default LoginView;
