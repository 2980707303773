import React, { useContext } from 'react';
import { Pressable, Platform } from 'react-native';
import { Text, HStack, View } from 'native-base';
import { Card, DataTable } from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import BatteryIndicator from '../../components/cashbackIndicator/CashbackIndicator';
import { AuthContext } from '../../components/AuthProvider';
import { useNavigation } from '@react-navigation/native'; // Для використання спеціально на iOS

import { default as triosStylesF } from '../../styles/trios_mobile';
import { default as listStylesF } from '../../styles/list';

const OrderHeaderTable = (props) => {
  const { t, theme } = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const triosStyles = triosStylesF(wp, hp, theme);

  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title
        style={triosStyles.itemCell}
        sortDirection={
          props?.sortDirection
          ? props.sortDirection === 'desc' ? 'descending' : 'ascending'
          : null
        }>
        {props?.sortDirection ? (
          <Pressable
            onPress={() => {
              dateSort(props.sortDirection);
            }}>
            <HStack style={Platform.OS === 'ios' ? { alignItems: 'center' } : {}}>
              <Text style={{ ...listStyles.itemsHeaderText }}>{t('date')}</Text>
            </HStack>
          </Pressable>
        ) : (
          <HStack style={Platform.OS === 'ios' ? { alignItems: 'center' } : {}}>
            <Text style={{ ...listStyles.itemsHeaderText }}>{t('date')}</Text>
          </HStack>
        )}
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('total_sum')}</Text>
      </DataTable.Title>
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('cashback')}</Text>
      </DataTable.Title>
      {props?.indicator && (
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('status')}</Text>
      </DataTable.Title>
      )}
      <DataTable.Title style={triosStyles.itemCell} numberOfLines={2}>
        <Text style={listStyles.itemsHeaderText}>{t('cashback_expired')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const OrderTable = ({ item, indicator, navigation }) => {
  const { t, theme, currency, setScreenParams } = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const triosStyles = triosStylesF(wp, hp, theme);
  const percentage = Math.floor(((item?.cashback_calculated || 0) / item.total_sum) * 100);

  const showRefOrders = (item) => {
    setScreenParams({userOrder: item});
    if (Platform.OS !== 'web') {
      navigation.navigate('UserCashbackOrders');
    } else {
      navigation.push('UserCashbackOrders');
    }
  }

  return (
    <DataTable.Row
      key={item.id}
      style={listStyles.itemRow}
      onPress={() => showRefOrders(item)}
    >
      <DataTable.Cell style={triosStyles.itemCell}>
        {/* {Platform.OS === 'web' && (
        <Image
          source={require('../../assets/img/three_icons.png')}
          style={triosStyles.threeIcon}
        />
      )} */}
        <Text
          style={[
            listStyles.itemCol,
            Platform.OS === 'ios' ? { fontSize: 12 } : {},
          ]}>
          {item.created_at.substr(0, 10)}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item.total_sum} {t(currency)}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item?.cashback_calculated || 0} {t(currency)}</Text>
      </DataTable.Cell>
      {indicator && (
        <DataTable.Cell style={triosStyles.itemCell}>
          <BatteryIndicator percentage={percentage} status={item.cashback_status}/>
        </DataTable.Cell>
      )}
      <DataTable.Cell style={triosStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item?.expired_at.substr(0, 10)}</Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const OrderHeaderCard = (props) => {
  const { t, theme } = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const triosStyles = triosStylesF(wp, hp, theme);

  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title
        style={triosStyles.itemCell}
        sortDirection={
          props?.sortDirection
          ? props.sortDirection === 'desc' ? 'descending' : 'ascending'
          : null
        }>
        {props?.sortDirection ? (
          <Pressable
            onPress={() => {
              dateSort(props.sortDirection);
            }}>
            <HStack style={Platform.OS === 'ios' ? { alignItems: 'center' } : {}}>
              <Text style={{ ...listStyles.itemsHeaderText }}>{t('date')}</Text>
            </HStack>
          </Pressable>
        ) : (
          <HStack style={Platform.OS === 'ios' ? { alignItems: 'center' } : {}}>
            <Text style={{ ...listStyles.itemsHeaderText }}>{t('date')}</Text>
          </HStack>
        )}
      </DataTable.Title>
    </DataTable.Header>
  );
};

const OrderCard = ({ item, navigation }) => {
  const { t, theme, currency, setScreenParams } = useContext(AuthContext);
  const iosNavigation = useNavigation();
  let triosStyles = triosStylesF(wp, hp, theme);

  const title = `${t('total_sum')}: ${item.total_sum} ${t(currency)}`;
  const subtitle = `${t('cashback')}: ${item.cashback_calculated ? item.cashback_calculated : 0} ${t(currency)}`;
  const percentage = Math.floor(((item?.cashback_calculated || 0) / item.total_sum) * 100);

  const Right = ({item, percentage}) => {
    return (
      <BatteryIndicator percentage={percentage} status={item.cashback_status}/>
    );
  };

  const showRefOrders = (item) => {
    setScreenParams({userOrder: item});
    if (Platform.OS !== 'web') {
      navigation.navigate('UserCashbackOrders');
    } else {
      navigation.push('UserCashbackOrders');
    }
  }

  return (
    <DataTable.Row
      key={item.id}
      style={{ width: wp('90%'), alignItems: 'center' }}
      onPress={() => showRefOrders(item)}
    >
      <Card
        mode={'outlined'}
        style={triosStyles.cardStyle}
      >
        <Card.Title
          title={title}
          subtitle={subtitle}
          // right={(props) => <Right item={item} percentage={percentage}/>}
          titleStyle={triosStyles.cardTitleStyle}
          subtitleStyle={triosStyles.cardSubtitleStyle}
        />
        <Card.Content style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <View>
            <Text style={{...triosStyles.cardContentStyle, alignContent: 'center'}}>
              {t('date')} {item.created_at.substr(0, 10)}
            </Text>
            <Text style={{...triosStyles.cardContentStyle, alignContent: 'center'}}>
              {t('cashback_expired')} {item.expired_at.substr(0, 10)}
            </Text>
          </View>
          <Right item={item} percentage={percentage}/>
        </Card.Content>
      </Card>
    </DataTable.Row>
  );
};

export { OrderHeaderTable, OrderHeaderCard, OrderTable, OrderCard }
