import { StyleSheet } from "react-native";

const styles = (wp, theme) => StyleSheet.create({
  content: {
    flex: 1,
    marginTop: 28,
    width: 251,
    alignSelf: 'center',
    alignItems: 'center',
    // minHeight: '100%',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
    width: wp('100%'),
    maxWidth: 1080,
    flexDirection: 'column',
  },
  contentHead: {
    flex: 1,
    marginTop: 10,
    flexGrow: 1,
    flexShrink: 0,
    backgroundColor: theme.BLUE,
    borderRadius: 20,
    width: 250,
    maxHeight: 80,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    padding: 10,
  },
  passwordBlock: {
    flex: 1,
  },
  phoneInput: {
    width: 251,
    height: 42,
    padding: 10,
    borderRadius: 10,
    borderWidth: 2,
    gap: 10,
    borderColor: theme.BLACK,
    fontFamily: theme.FONT_FAMILY,
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'left',
  },
  label: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    fontSize: 12,
    lineHeight: 15,
    textAlign: 'left',
    color: theme.FONT_COLOR_BLUE,
  },
  btnGroup: {
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  button: {
    marginTop: 18,
    width: 120,
    height: 40,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    gap: 10,
    backgroundColor: theme.BLUE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_WHITE,
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
  },
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
  infoText: {
    paddingBottom: 20,
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 14,
    lineHeight: 16,
    textAlign: 'left',
  },
  eye: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -40,
  },
  eyeShow: {
    width: 20,
    height: 10,
    // alignItems: 'center',
    // justifyContent: 'center',
    // marginLeft: -40,
  },
  eyeHide: {
    width: 21,
    height: 15,
    // alignItems: 'center',
    // justifyContent: 'center',
    // marginLeft: -40,
  },
  inputContainer: {
    alignItems: 'center',
  },
  iosContent: {
    marginHorizontal: 40,
    // marginBottom: 50,
    // flex: 1,
    resizeMode: 'cover',
    // marginTop: 28,
    // width: 251,
    // alignSelf: 'center',
    alignItems: 'center',
    // justifyContent: 'flex-end',
    // backgroundColor: 'red',
  },
  iosPasswordBlock: {
    paddingTop: 60,
    // width: '100%', // Забезпечує, що блок займає всю ширину
  },
  iosContentHead: {
    height: 100,
    marginTop: 50,
    // marginBottom: 40,
    // backgroundColor: 'red',

    padding: 0,
  },
  iosBtnGroup: {
    display: 'flex',
    flexDirection: 'row', // Забезпечує розміщення кнопок горизонтально
    justifyContent: 'center', // Центрує кнопки у контейнері
    // backgroundColor: 'red',
    gap: 10,
  },
  iosContentHeadText: {
    fontFamily: 'Inter',
    fontSize: 40,
    fontWeight: 'normal',
    lineHeight: 48,
    textAlign: 'center',
    // height: 48,
  },
});

export default styles;