import {StyleSheet, Platform} from 'react-native';

const styles = {
  web: (theme) => StyleSheet.create({
    headerContainer: {
      justifyContent: 'center',
      height: 70,
      maxHeight: 70,
      width: 1080,
      backgroundColor: '#FFF',
      flexShrink: 0,
    },
    headerLinksContainer: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 15,
      margin: 'auto',
      paddingLeft: 80,
    },
    headerLogoContainer: {
      width: 140,
      height: 50,
      top: 10,
      left: 30,
      paddingTop: 15,
      paddingRight: 9,
      paddingBottom: 16,
      paddingLeft: 10,
      justifyContent: 'center',
    },
    headerLogo: {
      width: 114,
      height: 19,
    },
    headerLogoText: {
      fontFamily: theme.FONT_FAMILY_BOLD,
      color: theme.FONT_COLOR_BLUE,
      fontSize: 32,
      // fontWeight: '600',
    },
    headerUser: {
      width: 30,
      height: 30,
      // top: 23,
      // right: 33,
    },
    statLabel: {
      color: theme.FONT_COLOR_BLACK,
      fontFamily: theme.FONT_FAMILY,
      fontSize: 16,
      lineHeight: 19,
    },
    selected: {
      color: 'rgb(25, 36, 200)',
    },
    langButtons: {
      width: 70,
      alignSelf: 'center',
      gap: 3,
    },
    langButton: {
      width: 48,
      height: 35,
      borderWidth: 0,
      borderRadius: 10,
      backgroundColor: theme.WHITE,
      alignItems: 'center',
      justifyContent: 'center',
    },
    activeLang: {
      borderWidth: 1,
      backgroundColor: '#8b93ff',
    },
    nagationBox: {
      // width: 110,
      height: 70,
      maxHeight: 70,
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      marginLeft: 10,
    },
    myProfileButton: {
      fontFamily: theme.FONT_FAMILY,
      color: theme.FONT_COLOR_WHITE,
      backgroundColor: theme.BLACK,
      fontSize: 16,
      width: 132,
      height: 35,
      borderRadius: 6,
      paddingHorizontal: 20,
      paddingVertical: 10,
      lineHeight: 16,
    },
    menuItemText: {
      fontFamily: theme.FONT_FAMILY_MEDIUM,
      fontSize: 20,
      color: theme.FONT_COLOR_BLACK,
    },
  }),
  android: (theme) => StyleSheet.create({
    headerContainer: {
      // flex: 1,
      flexDirection: 'row',
      // allignItems: 'center',
      justifyContent: 'center',
      height: 70,
    },
    headerLogoContainer: {
      width: 140,
      height: 50,
      top: 10,
      paddingTop: 15,
      paddingRight: 9,
      paddingBottom: 16,
    },
    headerLogo: {
      width: 114,
      height: 19,
    },
    headerUser: {
      width: 48,
      height: 35,
      // top: 23,
      // right: 33,
    },
    langButtons: {
      width: 90,
      height: 48,
      // alignSelf: 'center',
      // alignItems: 'top',
      justifyContent: 'center',
      gap: 3,
    },
    langButton: {
      width: 48,
      heigth: 35,
      // borderWidth: 3,
      // borderColor: theme.WHITE,
      borderRadius: 10,
      backgroundColor: '#D9D9D9',
      alignItems: 'center',
      justifyContent: 'center',
    },
    activeLang: {
      borderWidth: 1,
      backgroundColor: '#8b93ff',
    },
    nagationBox: {
      width: 110,
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
    },
    menuItemText: {
      fontFamily: theme.FONT_FAMILY_MEDIUM,
      fontSize: 20,
      color: theme.FONT_COLOR_BLACK,
    },
  }),
  ios: (theme) => StyleSheet.create({
    headerContainer: {
      // flexDirection: 'row',
      // alignItems: 'center',
      height: 70,
      // paddingHorizontal: 16,
      marginTop: Platform.OS === 'ios' ? 50 : 0, // Додано відступ для iOS
      // backgroundColor: 'red',
      // paddingTop: 20,
    },
    headerLogoContainer: {
      width: 140,
      height: 50,
      top: 10,
      paddingTop: 5,
      paddingRight: 9,
      paddingBottom: 16,
      paddingLeft: 10,
    },
    headerLogo: {
      width: 114,
      height: 19,
    },
    langButtons: {
      paddingBottom: 213,
      // backgroundColor: 'red',
      // width: 70,
      height: 48,
      // alignSelf: 'center',
      // gap: 3,
    },
    menuItemText: {
      fontFamily: theme.FONT_FAMILY_MEDIUM,
      fontSize: 20,
      color: theme.FONT_COLOR_BLACK,
    },
  }),
};

export default styles[Platform.OS];
