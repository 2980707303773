import React, {useEffect, useState, useContext} from 'react';
import {ScrollView, View, Pressable, Platform, Image} from 'react-native';
import {Text, HStack, theme} from 'native-base';
import {Card, Avatar, DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import AxiosInstance from '../../components/common/axiosInstance';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import {AuthContext} from '../../components/AuthProvider';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';

import {
  replaceWithAsterisks,
  replaceNameWithAsterics,
  nameToFio,
} from '../../components/common/commonFunctions';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import {default as commonStylesF} from '../../styles/common';
import {default as commonStylesMobileF} from '../../styles/common_mobile';
import {default as listStylesF} from '../../styles/list';
import {default as trioDetailsStylesF} from '../../styles/trioDetails';
import {default as trioDetailsMobileF} from '../../styles/trioDetails_mobile';

const OrderHeaderTable = () => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);

  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title>
        <Text style={listStyles.itemsHeaderText}>{t('order_date')}</Text>
      </DataTable.Title>
      <DataTable.Title>
        <Text style={listStyles.itemsHeaderText}>{t('referal')}</Text>
      </DataTable.Title>
      <DataTable.Title>
        <Text style={listStyles.itemsHeaderText}>{t('phone')}</Text>
      </DataTable.Title>
      <DataTable.Title>
        <Text style={listStyles.itemsHeaderText}>{t('sum')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const OrderTable = ({item}) => {
  const {t, theme, currency} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  return (
    <DataTable.Row key={item.id} style={listStyles.itemRow}>
      <DataTable.Cell>
        <Text style={listStyles.itemCol}>{item.created_at.substr(0, 10)} </Text>
      </DataTable.Cell>
      <DataTable.Cell>
        <Text style={listStyles.itemCol}>{item.invitation_id ? nameToFio(item.ref_name) :replaceNameWithAsterics(nameToFio(item.ref_name)) }</Text>
      </DataTable.Cell>
      <DataTable.Cell>
        <Text style={listStyles.itemCol}>
          {item.invitation_id ? item.ref_phone : replaceWithAsterisks(item.ref_phone)}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell>
        <Text style={listStyles.itemCol}>
          {item.total_sum} {t(currency)}
        </Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const OrderHeaderCard = () => {
  return <></>;
};

const OrderCard = ({item}) => {
  const {t, theme, currency} = useContext(AuthContext);
  const listStyles = listStylesF(theme);

  const Label = (
    <View
      style={{
        width: 60,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Text
        style={{
          fontFamily: theme.FONT_FAMILY_MEDIUM,
          fontSize: 24,
          lineHeight: 24,
          color: theme.FONT_COLOR_WHITE,
        }}>
        {item.total_sum}
      </Text>
      <Text
        style={{
          fontFamily: theme.FONT_FAMILY,
          fontSize: 16,
          lineHeight: 16,
          color: theme.FONT_COLOR_WHITE,
        }}>
        {t(currency)}
      </Text>
    </View>
  );

  const Right = () => (
    <Avatar.Text
      label={Label}
      size={60}
      style={{
        borderRadius: 5,
        justifyContent: 'center',
      }}
      labelStyle={{
        lineHeight: 50,
      }}
      color={theme.FONT_COLOR_WHITE}
      theme={{
        colors: {
          primary: theme.LIGHT_BLUE,
        },
      }}
    />
  );
  return (
    <DataTable.Row style={listStyles.itemCardRow} key={item.id}>
      <Card
        mode={'outlined'}
        style={{
          width: wp('90%'),
          margin: 2,
          maxWidth: 980,
          alignSelf: 'center',
          backgroundColor: theme.BLUE,
        }}>
        <Card.Title
          title={`${nameToFio(item.ref_name)} ${replaceWithAsterisks(
            item.ref_phone
          )}`}
          subtitle={`${t('order_date')}: ${item.created_at.substr(0, 10)}`}
          right={Right}
          titleStyle={{
            fontFamily: theme.FONT_FAMILY_MEDIUM,
            color: theme.FONT_COLOR_WHITE,
            fontSize: 14,
          }}
          subtitleStyle={{
            fontFamily: theme.FONT_FAMILY,
            color: theme.FONT_COLOR_WHITE,
            fontSize: 14,
          }}
          rightStyle={{
            marginRight: 10,
          }}
        />
      </Card>
    </DataTable.Row>
  );
};

const TrioDetails = ({trioId, navigation, route}) => {
  const {user, logout, popBreadcrumb, t, screenWidth, theme, addHistory, currency} =
    useContext(AuthContext);

  const [trioDetails, setTrioDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);
  const trioDetailsStyles =
    screenWidth < 1080
      ? trioDetailsMobileF(wp, hp, theme)
      : trioDetailsStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  const OrderHeader = screenWidth < 700 ? OrderHeaderCard : OrderHeaderTable;
  const Order = screenWidth < 700 ? OrderCard : OrderTable;

  useEffect(() => {
    async function getTrioDetails() {
      setIsLoading(true);
      const api = AxiosInstance({user, logout});
      await api(`/api/v1/trios/orders_stat/${trioId}`, {
        method: 'GET',
        mode: 'cors',
      })
        .then(response => {
          setTrioDetails(response.data.message);
        })
        .catch(error => {
          setIsError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    getTrioDetails();
  }, [trioId, user, logout]);

  useEffect(() => {
    setPrevScreenWidth(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory('TrioDetails');
    }
  }, [addHistory, route]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.headerContainer}>
            <Pressable
              style={commonStyles.headBackButton}
              onPress={() => navigation.navigate('Trios')}>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={commonStyles.arrowLeft} />
              )}
            </Pressable>
            <View style={commonStyles.contentHead}>
              <Text style={commonStyles.contentHeadText}>
                {t('trio_referals')}
              </Text>
            </View>
          </View>
          <View style={trioDetailsStyles.trioInfo}>
            <Text style={trioDetailsStyles.trioName}>
              {t('trio')} №{' '}
              <Text style={{color: theme.FONT_COLOR_BLACK}}>{trioId}</Text>
            </Text>
            <Text style={trioDetailsStyles.trioBalance}>{t('balance')}</Text>
          </View>
          <View style={trioDetailsStyles.trioStats}>
            <View style={trioDetailsStyles.trioStatBlack}>
              <Text style={trioDetailsStyles.trioStatTxt1}>
                {t('total_sum')}
              </Text>
              <Text style={trioDetailsStyles.trioStatTxt2}>
                {trioDetails.total_sum}
                {/* <NumericFormat value={trioDetails.all_sum} displayType={'text'} thousandSeparator=" " /> */}
              </Text>
              <Text style={trioDetailsStyles.trioStatTxt3}>{t(currency)}</Text>
            </View>
            <View style={trioDetailsStyles.trioStatBlue}>
              <Text style={trioDetailsStyles.trioStatTxt1}>
                {t('your_reward')}
              </Text>
              <Text style={trioDetailsStyles.trioStatTxt2}>
                {trioDetails.reward_sum}
              </Text>
              <Text style={trioDetailsStyles.trioStatTxt3}>{t(currency)}</Text>
            </View>
          </View>
          <DataTable style={{...listStyles.dataTable, marginBottom: 20}}>
            <OrderHeader />
            {trioDetails.orders.map(item => (
              <Order item={item} key={item.id}/>
            ))}
          </DataTable>
        </View>
        <Footer navigation={navigation}/>
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </ScrollView>
    );
  }
};

export default TrioDetails;
