import React, { Component, useState } from 'react';
import { Platform } from 'react-native';
import MobileColorPicker from './ColorPickerComponent.mobile';
import WebColorPickerComponent from './ColorPickerComponent.web';

const ColorPickerWrapper = ({ color, onChangeComplete }) => {
  return Platform.OS === 'web' ? (
    <WebColorPickerComponent color={color} onChangeComplete={onChangeComplete} />
  ) : (
    <MobileColorPicker color={color} onChangeComplete={onChangeComplete} />
  );
};

export default ColorPickerWrapper;
