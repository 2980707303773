import React, { Component, useState } from 'react';
import { View, TouchableOpacity, Modal, Button, StyleSheet, Dimensions } from 'react-native';
import ColorPicker from './CustomColorPicker';
// import { ColorWheel } from 'react-native-color-wheel';
// import ColorPicker from 'react-native-wheel-color-picker';

const MobileColorPicker = ({ color, onChangeComplete }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <TouchableOpacity
        style={[styles.colorBlock, { backgroundColor: color }]}
        onPress={() => setModalVisible(true)}
      />
      <Modal
        visible={modalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <ColorPicker
            defaultColor={color}
            onColorChange={color => onChangeComplete({ hex: color })}
            style={{ flex: 1, width: 200, height: 200 }}
          />
          <Button title="Close" onPress={() => setModalVisible(false)} />
        </View>
      </Modal>
      {/* <View style={{flex: 1}}>
        <ColorWheel
          initialColor="#ee0000"
          onColorChange={color => console.log({color})}
          onColorChangeComplete={color => onChangeComplete(color)}
          style={{width: Dimensions.get('window').width}}
          thumbStyle={{ height: 30, width: 30, borderRadius: 30}}
        />
        <ColorWheel
          initialColor="#00ee00"
          style={{ marginLeft: 20, padding: 40, height: 200, width: 200 }}
        />
      </View> */}
    </>
  );
};

const styles = StyleSheet.create({
  colorBlock: {
    width: 100,
    height: 100,
    borderRadius: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});

export default MobileColorPicker;
