import React, { useContext } from 'react';
import { Modal, View, Text, Button, StyleSheet, Pressable } from 'react-native';
import {AuthContext} from '../../components/AuthProvider';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {default as profileStylesDesktop} from '../../styles/profile';
import {default as profileStylesMobile} from '../../styles/profile_mobile';

const ConfirmationModal = ({modalVisible, text, setConfirmed, setModalVisible}) => {
  const {
    screenWidth,
    theme,
    t
  } = useContext(AuthContext);
  let fontSizeBig =
    Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 12) : 40;
  let fontSizeMd =
    Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 14) : 24;
  let profileStyles =
  screenWidth < 1080
    ? profileStylesMobile(wp, hp, fontSizeBig, fontSizeMd, theme)
    : profileStylesDesktop(wp, hp, fontSizeBig, fontSizeMd, theme);

  const handleConfirm = () => {
    // Действие при подтверждении
    setModalVisible(false);
    setConfirmed(true);
  };

  const handleCancel = () => {
    // Действие при отмене
    setModalVisible(false);
    setConfirmed(false);
  };

  return (
    <View style={styles.container}>

      <Modal
        visible={modalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={() => setModalVisible(false)} // Закрытие при нажатии кнопки "назад" на Android
      >
        <View style={styles.modalBackground}>
          <View style={styles.modalContainer}>
            <Text style={styles.modalText}>{text}</Text>
            <View style={styles.buttonContainer}>
              <Pressable
                style={profileStyles.formBtn}
                onPress={handleCancel}
              >
                <Text style={profileStyles.frmButtonText}>{t('cancel')}</Text>
              </Pressable>
              <Pressable
                style={{...profileStyles.formBtn, backgroundColor: 'red'}}
                onPress={handleConfirm}
              >
                <Text style={profileStyles.frmButtonText}>{t('delete')}</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    width: 300,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
  },
  modalText: {
    marginBottom: 20,
    fontSize: 18,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export default ConfirmationModal;
