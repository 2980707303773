import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
  memo,
} from 'react';
import {
  View,
  Text,
  Image,
  Pressable,
  FlatList,
  SafeAreaView,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
  Platform,
  Alert,
} from 'react-native';
import {HStack, Radio, theme} from 'native-base';
import {
  SegmentedButtons,
} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {CheckBox, SearchBar} from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';
import iconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import axios from 'axios';
import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {useGoogleLogin} from '../../components/common/GoogleSignin';

import {default as themeG} from '../../config/theme_config';
import {default as commonStylesF} from '../../styles/common';
import {default as stylesF} from '../../styles/web_contacts';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import FormModal from '../../components/common/FormModal';
// import Config from '../../config/config';
import {
  getDataFromStorage,
  saveDataToStorage,
  saveContactsToDB,
  sendInviteToContacts,
} from './ContactsCommon';

if (Platform.OS === 'web') {
  const iconFontStyles = `@font-face {
    src: url(${iconFont});
    font-family: FontAwesome;
  }`;

  const style = document.createElement('style');

  // Append the iconFontStyles to the stylesheet
  if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }

  // Inject the stylesheet into the document head
  document.head.appendChild(style);
}

const checkSelectedStatus = (_contacts, _selectedItems, defaultSelected) => {
  // console.log('checkSelectedStatus', defaultSelected);
  let checkedItems = 0;
  let uncheckedItems = 0;
  let itemsCnt = 0;

  _contacts.map(item => {
    let key = item.id;
    if (_selectedItems[key] !== undefined) {
      if (_selectedItems[key].checked) {
        checkedItems++;
      } else {
        uncheckedItems++;
      }
      itemsCnt++;
    } else if (_selectedItems[key] === undefined) {
      if (defaultSelected) {
        checkedItems++;
      } else {
        uncheckedItems++;
      }
      itemsCnt++;
    }
  });

  if (checkedItems === itemsCnt) {
    // console.log(`allSelected: checked ${checkedItems} all ${itemsCnt}`);
    return true;
  } else if (uncheckedItems === itemsCnt) {
    // console.log(`allUnchecked unchecked ${uncheckedItems} all ${itemsCnt}`);
    return false;
  } else {
    // console.log(`partialSelected checked ${checkedItems} unchecked ${uncheckedItems} all ${itemsCnt}`);
    // return (defaultSelected === true || defaultSelected === 1) ? 1 : 2;
    return 1;
  }
};

const MemContactItem = memo(
  ({
    contact,
    allContacts,
    selectedItems,
    setSelectedItems,
    setNeedUpdate,
    isChecked,
    isSended,
    allSelected,
    setAllSelected,
  }) => {
    // console.log('RENDER CONTACT ', contact.id, contact.name, isSended);
    const {theme} = useContext(AuthContext);
    const styles = stylesF(wp, theme);
    const handleCheckboxChange = () => {
      let currentSelected;

      setSelectedItems(prevState => {
        let newState = prevState;
        let itemId = contact.id;
        newState[itemId] = contact;
        newState[itemId].checked = !isChecked;
        return newState;
      });
      currentSelected = checkSelectedStatus(
        allContacts,
        selectedItems,
        allSelected
      );
      setAllSelected(currentSelected);
      setNeedUpdate(prevState => !prevState);
    };

    // при отображении чекбокса использовать значение из selectedItems
    return (
      <View style={styles.contactCon}>
        <CheckBox
          checked={isChecked}
          onPress={() => {
            handleCheckboxChange();
          }}
        />
        <View>
          {contact.photo ? (
            <Image source={{uri: contact.photo}} style={styles.avatar} />
          ) : (
            <View style={styles.placeholder}>
              <Text style={styles.txt}>{contact.name}</Text>
            </View>
          )}
        </View>

        <View style={styles.contactDat}>
          <Text style={styles.name}>
            {contact.name}
            {isSended !== null &&
              (isSended === true ? (
                <Icon name="check" size={15} color="green" />
              ) : (
                <Icon name="check" size={15} color="orange" />
              ))}
          </Text>
          <Text style={styles.phoneNumber}>{contact.phone}</Text>
        </View>
      </View>
    );
  }
);

const batchSize = 20;
const pageSize = 500;
const minSearchStrLen = 2;

const WebContacts = ({navigation, route}) => {
  const {user, logout, t, screenWidth, theme, screenParams, addHistory, Config} = useContext(AuthContext);

  const [contacts, setContacts] = useState([]);
  const [cachedContacts, setCachedContacst] = useState([]);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleData, setVisibleData] = useState([]);
  const [selectedItems, setSelectedItems] = useState({}); // отмеченные контакты
  const [allSelected, setAllSelected] = useState(true);
  const [filteredSelected, setFilteredSelected] = useState(true);
  const [alreadySended, setAlreadySended] = useState({});
  const [needUpdate, setNeedUpdate] = useState(false);
  const [search, setSearch] = useState('');
  const [tokens, setTokens] = useState(prevState => {
    return {...prevState, ...screenParams}
  });
  const [contactType, setContactType] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [checkedContacts, setCheckedContacts] = useState(0);
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);
  const [modalVisible, setModalVisible] = useState(false);

  const styles = stylesF(wp, theme);
  let commonStyles = commonStylesF(wp, hp, theme);

  let contactsStorage =
    contactType === 'phone' ? 'webContacts' : 'webOtherContacts';

  const normalizePhone = phone => {
    if (!phone || phone === undefined) {
      return '***';
    }
    let normPhone = phone.replace(/\D/g, '');
    if (normPhone.length < 12) {
      normPhone = '38' + normPhone;
    }
    return normPhone;
  };

  const googleGetTokens = useGoogleLogin({
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly',
    onSuccess: async tokenResponse => {
      console.log('useGoogleLogin onSuccess tokens', tokenResponse);
      try {
        setIsLoading(true);
        let response = await axios.post(
          `${Config.API_URL}/oauth2.php`,
          {
            code: tokenResponse.code,
            user_id: user.id,
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        // console.log('RESPONSE FROM BACKEND', response);
        let now = new Date().getTime();
        if (response.data.token !== undefined) {
          response.data.token.expires_at =
            now + response.data.token.expires_in * 1000;
          await saveDataToStorage(response.data.token, 'tokens');
          setTokens(prevState => {
            return {...prevState, ...response.data.token}
          });
          console.log('setForceUpdate');
          setForceUpdate(true);
          // setShowWebContacts(true);
          // return response.data.token;
        } else {
          console.log('Не удалось получить access token от Google');
          setIsError(t('error_get_token'));
          // return false;
        }
      } catch (error) {
        console.log('Ошибка получения данных с сервера');
        setIsError(t('server_error'));
        // return false;
      } finally {
        setIsLoading(false);
      }
    },
    onError: errorResponse => {
      console.log('useGoogleLogin Login failed', errorResponse);
      setIsError(t('google_login_failed'));
      return false;
    },
    onNonOAuthError: onNonOAuthError => {
      console.log('useGoogleLogin onNonOAuthError', onNonOAuthError);
      setIsError(t('google_login_canceled'));
      return false;
    },
  });

  const refreshAccessToken = useCallback(async () => {
    let result = false;
    await axios
      .post(
        `${Config.API_URL}/oauth2.php`,
        {
          refresh_token: tokens.refresh_token,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then(response => {
        console.log('RESPONSE FROM BACKEND, REFRESHING TOKEN', response);
        if (response.data.token !== undefined) {
          console.log('TOKEN REFRESHING OK' );
          response.data.token.expires_at =
            new Date().getTime() + response.data.token.expires_in * 1000;
          saveDataToStorage(response.data.token, 'tokens');
          setTokens(prevState => {
            return {...prevState, ...response.data.token}
          });
          result = true;
        } else {
          console.log('TOKEN REFRESHING ERROR' );
          result = false;
          // setIsError(t('error_google_token'));
          // googleGetTokens();
        }
      })
      .catch(error => {
        console.log('ERROR RESPONSE FROM BACKEND, REFRESHING TOKEN', error);
        result = false;
        // googleGetTokens();
      });

      return result;
  }, [tokens, Config.API_URL]);

  const loadContacts = useCallback(
    async (nextPage = false) => {
      // https://developers.google.com/people/api/rest/v1/people.connections/list?hl=ru
      setSearch('');

      let apiUrl = `https://people.googleapis.com/v1/people/me/connections?personFields=names,phoneNumbers,emailAddresses,photos&sortOrder=FIRST_NAME_ASCENDING&pageSize=${pageSize}`;

      if (nextPage) {
        apiUrl += `&pageToken=${nextPage}`;
      }
      console.log('TRY LOAD GOOGLE CONTACTS WITH TOKEN', tokens);
      try {
        const response = await axios.get(apiUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokens.access_token}`,
          },
        });

        let _contacts = [];
        let _name = '';
        let _phone = '';
        let _photo = '';
        if (response.data.connections !== undefined) {
          response.data.connections.map(contact => {
            _name =
              contact.names !== undefined
                ? contact.names[0].displayName
                : t('no_name');
            _phone =
              contact.phoneNumbers !== undefined && contact.phoneNumbers.length > 0 && contact.phoneNumbers[0].canonicalForm
                ? normalizePhone(contact.phoneNumbers[0].canonicalForm)
                : contact.phoneNumbers[0].value
                ? contact.phoneNumbers[0].value : t('no_phone');
            _photo = contact.photos !== undefined ? contact.photos[0].url : '';

            _contacts.push({
              id: contact.resourceName.replace('people/', ''),
              name: _name,
              phone: _phone,
              photo: _photo,
            });
          });
        }
        return {
          contacts: _contacts,
          totalContacts: response.data.totalItems,
          nextPageToken: response.data.nextPageToken,
        };
      } catch (error) {
        if (error.response) {
          // Статус ответа выходит за пределы 2xx
          const {data, status, headers} = error.response;
          console.error('error.response', data, status);
          if (status === 401 || status === 400) {
            // setIsError('Нужна повторная авторизация');
            await refreshAccessToken();
          } else if (status === 403) {
            await refreshAccessToken();
            return {
              contacts:[],
              totalContacts: 0,
              error: 403,
            };
          } else {
            setIsError(t('server_error') + `${status}`);
          }
        } else if (error.request) {
          // Отсутствует тело ответа
          console.error(error.request);
          setIsError(t('error_google_empty_response'));
        } else {
          // Ошибка, связанная с неправильной настройкой запроса
          setIsError(t('error_google_bad_request'));
          console.error(t('error_google_bad_request'), error);
        }
        throw error;
      }
    },
    [tokens, t, refreshAccessToken]
  );

  const loadOtherContacts = useCallback(
    async (nextPage = false) => {
      // https://developers.google.com/people/api/rest/v1/otherContacts/list?hl=en
      setSearch('');
      let now = new Date();

      let apiUrl = `https://people.googleapis.com/v1/otherContacts?readMask=names,emailAddresses,photos&pageSize=${pageSize}`;
      if (nextPage) {
        apiUrl += `&pageToken=${nextPage}`;
      }
      try {
        const response = await axios.get(apiUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokens.access_token}`,
          },
        });
        // console.log('loadOtherContacts response', response.data.otherContacts);

        let _contacts = [];
        let _name = '';
        let _phone = '';
        let _photo = '';
        if (response.data.otherContacts !== undefined) {
          response.data.otherContacts.map(contact => {
            _name =
              contact.names !== undefined
                ? contact.names[0].displayName
                : t('no_name');
            _phone =
              contact.emailAddresses !== undefined
                ? contact.emailAddresses[0].value
                : t('no_contact');
            _photo = contact.photos !== undefined ? contact.photos[0].url : '';

            _contacts.push({
              id: contact.resourceName.replace('otherContacts/', ''),
              name: _name,
              phone: _phone,
              photo: _photo,
            });
          });
        }
        return {
          contacts: _contacts,
          totalContacts: response.data.totalItems,
          nextPageToken: response.data.nextPageToken,
        };
      } catch (error) {
        if (error.response) {
          // Статус ответа выходит за пределы 2xx
          const {data, status, headers} = error.response;
          console.error('error.response', data, status);
          if (status === 401) {
            setIsError(t('error_google_need_login'));
            await refreshAccessToken();
          } else if (status === 403) {
            setIsError(t('google_403'));
            setTimeout(() => {
              setIsError(false);
            }, 1500);
            await refreshAccessToken();
            return {
              contacts:[],
              totalContacts: 0,
              error: 403,
            };
          } else {
            setIsError(t('server_error') + `${status}`);
          }
        } else if (error.request) {
          // Отсутствует тело ответа
          console.error(error.request);
          setIsError(t('server_error'));
        } else {
          // Ошибка, связанная с неправильной настройкой запроса
          setIsError(t('server_error'));
          console.error(error.message);
        }
        // Другая ошибка
        // console.error(error.config);
        // Подробная информация об ошибке
        // console.error(error.toJSON());
        throw error;
      }
    },
    [tokens, t, refreshAccessToken]
  );

  const searchContacts = searchStr => {
    if (searchStr.length < minSearchStrLen) {
      return;
    }
    let filteredContacts = [];
    let currentSelected;
    searchStr = searchStr.toLowerCase();
    filteredContacts = cachedContacts.filter(item =>
      item.name.toLowerCase().includes(searchStr)
    );
    setContacts(filteredContacts);
    setVisibleData(filteredContacts.slice(0, batchSize));
    currentSelected = checkSelectedStatus(
      filteredContacts,
      selectedItems,
      filteredSelected
    );
    setFilteredSelected(currentSelected);
  };

  const loadAlreadySendedContacts = useCallback(async () => {
    const api = AxiosInstance({user, logout});
    await api('/api/v1/invitations/all', {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        if (response.data.code === 200) {
          let invitations = response.data.message;
          if (Object.keys(alreadySended).length < invitations.length) {
            let _alreadySended = {};
            invitations.map(item => {
              if (item.phone) {
                _alreadySended[normalizePhone(item.phone)] =
                  parseInt(item.status) === 1 ? true : false;
              } else if (item.email) {
                _alreadySended[item.email] =
                  parseInt(item.status) === 1 ? true : false;
              }
            });
            setAlreadySended(_alreadySended);
          }
        } else {
          setIsError(t('error_loading_already_sended'));
          console.log('User invitations list error:', response.data.message);
        }
      })
      .catch(error => {
        console.log('User invitations list error:', error);
        setIsError(t('error_loading_already_sended'));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, logout]);

  const loadMoreData = () => {
    // Вычислите следующую порцию данных на основе текущего состояния
    const startIndex = visibleData.length;
    const endIndex =
      startIndex + batchSize <= contacts.length
        ? startIndex + batchSize
        : contacts.length;
    // console.log(`loadMoreData from ${startIndex} to ${endIndex}`);
    const nextBatch = contacts.slice(startIndex, endIndex);
    setVisibleData(prevData => [...prevData, ...nextBatch]);
  };

  const toggleAll = async () => {
    if (search) {
      visibleData.map(item => {
        setSelectedItems(prevState => {
          const newState = prevState;
          newState[item.id] = item;
          newState[item.id].checked = !filteredSelected;
          return newState;
        });
      });
      setFilteredSelected(!filteredSelected);
    } else {
      contacts.map(item => {
        setSelectedItems(prevState => {
          const newState = prevState;
          newState[item.id] = item;
          newState[item.id].checked = !allSelected;
          return newState;
        });
      });
      await AsyncStorage.setItem('allSelected', JSON.stringify(!allSelected));
      setAllSelected(!allSelected);
    }
  };

  const checkSendRules = (
    _contacts,
    _selectedItems,
    defaultSelected
  ) => {
    let contactsToSend = [];
    // setIsLoading(true);
    _contacts.map(item => {
      let key = item.id;
      let sendedKey =
        contactType === 'phone' ? normalizePhone(item.phone) : item.phone;

      if (_selectedItems[key] !== undefined) {
        if (_selectedItems[key].checked) {
          if (contactType === 'phone') {
            contactsToSend.push({displayName: item.name, phone: item.phone});
          } else {
            contactsToSend.push({displayName: item.name, email: item.phone});
          }
        }
      } else if (_selectedItems[key] === undefined) {
        if (defaultSelected) {
          if (contactType === 'phone') {
            contactsToSend.push({displayName: item.name, phone: item.phone});
          } else {
            contactsToSend.push({displayName: item.name, email: item.phone});
          }
        }
      }
    });
    // console.log(contactsToSend);
    if (!contactsToSend.length) {
      setIsError(t('error_empty_choice'));
      setIsLoading(false);
      return false;
    }
    console.log('contacts to send: ', contactsToSend);

    let maxMessages =
      contactType === 'phone' ? Config.MAX_SMS : Config.MAX_EMAILS;
    if (contactsToSend.length > maxMessages) {
      setIsError(
        t('error_max_send') + ` ${maxMessages} ` + t('of_invitations')
      );
      setIsLoading(false);
      return false;
    }

    if (Config.ENV_NAME === 'dev') {
      contactsToSend = contactsToSend.slice(0, maxMessages);
    }

    return contactsToSend;
  }

  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = (formData) => {
    // Обработка данных формы
    console.log('Form submitted', formData);
    setIsLoading(true);

    let _contactsToSend = checkSendRules(
      contacts,
      selectedItems,
      currentAllSelected
    );

    if (_contactsToSend !== false){
      sendToSelectedContacts(_contactsToSend, formData);
    } else {
      setIsError(t('unknown_error'));
    }

    setIsLoading(false);
  };

  const sendToSelectedContacts = async (contactsToSend, msgFormData) => {
    if (msgFormData.message === undefined || !msgFormData.length) {
      await sendInviteToContacts(
        contactsToSend,
        setIsError,
        setInfo,
        setIsLoading,
        user,
        logout,
        t,
        msgFormData
      );

      let _sended = alreadySended;
      contactsToSend.map(item => {
        _sended[item.phone] = false;
      });
      setAlreadySended(_sended);
    } else {
      setIsError(t('empty_message'));
    }
  };

  const checkTokenExpired = useCallback(async () => {
    let now = new Date();

    console.log('Tokens in checkTokenExpired', tokens);
    if (tokens && tokens.expires_at && tokens.google_account_id) {
      console.log('check token expired for account', tokens.google_account_id);
      console.log('token expires less then ', (tokens.expires_at - now.getTime())/1000/60);
      if (tokens.expires_at - now.getTime() <= 1 * 60 * 1000) {
        // если до конца действия токена осталось меньше минуты
        console.log('start refreshToken');
        let refresh_res = await refreshAccessToken();
        console.log('end refresh token');
        return refresh_res;
      } else {
        console.log('token is valid');
        return true;
      }
    } else {
      console.log('Токен не найден');
      return false;
    }
  }, [tokens, refreshAccessToken]);

  // загружает все данные из Гугл. Выполняет столько запросов сколько необходимо для загрузки всех контактов
  const fullLoadContacts = useCallback(async () => {
    let allContacts = [];
    let myContacts;
    // let start = new Date();
    let i = 2;

    try {
      if (contactType === 'phone') {
        myContacts = await loadContacts();
      } else {
        myContacts = await loadOtherContacts();
      }
    } catch (error) {
      console.log('load contacts error', error);
      return [];
    }

    if (myContacts.contacts.length === 0 && myContacts.error !== undefined && myContacts.error === 403) {
      return 403;
    }
    // отображаем сразу из первой порции чтобы не ждать остальные запросы
    setVisibleData(myContacts.contacts.slice(0, batchSize));
    allContacts = allContacts.concat(myContacts.contacts);

    // пока в ответе присутствует nextPageToken есть еще данные
    while (myContacts.nextPageToken !== undefined) {
      if (contactType === 'phone') {
        myContacts = await loadContacts(myContacts.nextPageToken);
      } else {
        myContacts = await loadOtherContacts(myContacts.nextPageToken);
      }
      allContacts = allContacts.concat(myContacts.contacts);
      i++;
      // if (i > 10) {
      //   break;
      // }
    }

    return allContacts;
  }, [loadContacts, loadOtherContacts, contactType]);

  // загружает контакты пользователя из БД
  const loadDbContacts = useCallback(async (google_account_id) => {
    if (tokens && tokens.google_account_id) {
      const api = AxiosInstance({user, logout});
      await api(`${Config.API_URL}/api/v1/user_contacts/get/${google_account_id}`, {
        method: 'GET',
        mode: 'cors',
      }).then(response => {
        if (response.data && response.data.message) {
          console.log('DB Contacts', response.data.message);
          return response.data.message;
        } else {
          return [];
        }
      }).catch(error => {
        console.log('loadDbContacts error', error);
      });
    } else {
      return [];
    }
  }, [user, logout, tokens, Config.API_URL]);

  const getAllContacts = useCallback(
    async (defSelected = true) => {
      let _contacts = await fullLoadContacts();
      if (Number.isInteger(_contacts) && _contacts === 403) {
        return false;
      }
      // let _db_contacts = await loadDbContacts();
      let _defaultSelected = {};
      if (_contacts.length) {
        setContacts(_contacts);
        await saveDataToStorage(
          _contacts,
          `${contactsStorage}_${tokens.google_account_id}`
        );
        await saveDataToStorage(
          new Date(),
          `${contactsStorage}LastUpdate_${tokens.google_account_id}`
        );
        setCachedContacst(_contacts);
        _contacts.map(contact => {
          _defaultSelected[contact.id] = contact;
          _defaultSelected[contact.id].checked = defSelected;
        });
        setSelectedItems(_defaultSelected);
      } else {
        setContacts([]);
        setCachedContacst([]);
        await saveDataToStorage(
          [],
          `${contactsStorage}_${tokens.google_account_id}`
        );
        await saveDataToStorage(
          new Date(),
          `${contactsStorage}LastUpdate_${tokens.google_account_id}`
        );
        setSelectedItems({});
        // console.log('CONTACTS', _contacts);
        if (_contacts.error !== undefined && _contacts.error === 403) {
          console.log('load contacts 403');
        } else {
          setInfo(t('empty_phone_book'));
        }
      }
    },
    [contactsStorage, tokens.google_account_id, fullLoadContacts, t]
  );

  const handleGoogleReload = async () => {
    console.log('handleGoogleReload');
    let token_updated = await checkTokenExpired();
    console.log('token updated', token_updated);
    if (token_updated) {
      setForceUpdate(true);
    } else {
      googleGetTokens();
    }
  };

  const handleChangeAccount = async () => {
    await googleGetTokens();
  }

  // загружает тип контактов, тел. книга или другие
  useEffect(() => {
    const loadContactType = async () => {
      let _contactType = await getDataFromStorage('contactType');
      if (_contactType && _contactType !== undefined) {
        setContactType(_contactType);
      } else {
        setContactType('phone');
      }
    };

    const _setTokens = async () => {
      // проверяем токен в локальном хранилище
      let _tokens = await getDataFromStorage('tokens');

      if (_tokens) {
        console.log('setTokens');
        setTokens(_tokens);
        return _tokens;
      }
    }
    _setTokens();
    loadContactType();
  }, []);

  // этот хук работает в паре с handleGoogleReload для принудительного обновления при клике на синх.Google
  useEffect(() => {
    if (forceUpdate) {
      // console.log('useEffect for forceUpdate');
      getAllContacts();
      setForceUpdate(false);
    }
  }, [forceUpdate, getAllContacts]);

  // первоначальная загрузка данных
  useEffect(() => {
    // console.log('useEffect for loading data');
    if (contactType !== false) {
      setIsLoading(true);

      // получает дату последнего обновления контактов из Гугл
      const getLastUpdate = async () => {
        let lastUpdated = await getDataFromStorage(
          `${contactsStorage}LastUpdate_${tokens.google_account_id}`
        );
        return lastUpdated;
      };
      // загружает ранее сохраненные данные об отмеченных контактах из локального хранилища
      const loadSelectedItems = async () => {
        let _selectedItems = await getDataFromStorage(
          `${contactsStorage}SelectedItems_${tokens.google_account_id}`
        );

        if (_selectedItems) {
          // console.log(
          //   `SELECTED ITEMS ${contactsStorage}SelectedItems_${tokens.google_account_id}`,
          //   _selectedItems
          // );
          setSelectedItems(_selectedItems);
        }
      };

      const processLoading = async () => {
        // await _setTokens();
        console.log('checkTokenExpired started');
        let token_ok = await checkTokenExpired();
        console.log('checkTokenExpired finished', token_ok);

        if (!token_ok) {
          // нужно получить токен от Гугл
          googleGetTokens();
        } else {
          // await getAllContacts();
          setForceUpdate(true);
        }
      }

      // проверяет сохраненные контакты или загружает из Гугл если таковых нет или они старше 7 дней
      const checkAndLoadContacts = async () => {
        // проверяем сохраненные в локальном хранилище
        let contactsInStorage = false;
        if (tokens.google_account_id) {
          contactsInStorage = await getDataFromStorage(
            `${contactsStorage}_${tokens.google_account_id}`
          );
        }
        console.log('TEST', typeof contactsInStorage == 'object', Object.keys(contactsInStorage).length)
        // если сохраненных нет
        if (!contactsInStorage) {
          // нужно получить от Гугл
          // console.log('TEST 0');
          processLoading();
        } else if (
          contactsInStorage !== false
          && contactsInStorage !== undefined
          && (typeof contactsInStorage == 'object' && contactsInStorage.length !== 0)
        ) {
          // console.log('TEST 1');
          let lastUpdated = await getLastUpdate();

          if (lastUpdated !== false && lastUpdated !== undefined) {
            let now = new Date();
            let lastUpd = new Date(lastUpdated);
            let differenceInMilliseconds = Math.abs(
              now.getTime() - lastUpd.getTime()
            );
            let differenceInDays = Math.floor(
              differenceInMilliseconds / (1000 * 3600 * 24)
            );
            // console.log(`differenceInDays: ${differenceInDays}`);

            if (differenceInDays >= Config.CONTACTS_RELOAD_AFTER_DAYS) {
              // нужно получить от Гугл
              console.log('diff days >= 7', differenceInDays);
              processLoading();
            } else {
              console.log('set from storage');
              setContacts(contactsInStorage);
              setCachedContacst(contactsInStorage);
              setVisibleData(contactsInStorage.slice(0, batchSize));
            }
            // console.log(`last update ${lastUpdated} days ${differenceInDays}`);
          } else {
            // нужно получить от Гугл
            processLoading();
          }
        } else if (typeof contactsInStorage == 'object' && contactsInStorage.length === 0) {
          // console.log('set empty contacts');
          if (contactType === 'phone') {
            setInfo(t('empty_phone_book'));
          }
          setContacts([]);
          setForceUpdate(true);
        }
      };


      // получает список контактов которые уже есть в приглашениях
      loadAlreadySendedContacts();
      // список ранее отмеченных
      loadSelectedItems();
      // загрузка контактов
      checkAndLoadContacts();

      setIsLoading(false);
    }
  }, [
    // loadContacts,
    loadOtherContacts,
    // loadDbContacts,
    fullLoadContacts,
    loadAlreadySendedContacts,
    checkTokenExpired,
    getAllContacts,
    googleGetTokens,
    contactType,
    contactsStorage,
    tokens,
    Config.CONTACTS_RELOAD_AFTER_DAYS,
    t,
  ]);

  // проверка статуса отмеченности при изменении контактов
  useEffect(() => {
    // if (contactType !== false) {
    let currentSelected = allSelected;
    // console.log('setAllSelected', currentSelected);
    if (search.length >= minSearchStrLen) {
      currentSelected = checkSelectedStatus(
        contacts,
        selectedItems,
        filteredSelected
      );
      setFilteredSelected(currentSelected);
    } else {
      currentSelected = checkSelectedStatus(
        contacts,
        selectedItems,
        allSelected
      );
      setAllSelected(currentSelected);
    }
    // }
  }, [contacts, selectedItems, allSelected, filteredSelected, search]);

  // сохранение выбранных элементов при закрытии
  useEffect(() => {
    const saveData = async () => {
      console.log('before saving selectedItems', contactsStorage);
      if (Object.keys(selectedItems).length) {
        await saveDataToStorage(
          selectedItems,
          `${contactsStorage}SelectedItems_${tokens.google_account_id}`
        );
      }
      if (contactType) {
        await saveDataToStorage(contactType, 'contactType');
      }
    };

    return () => {
      saveData();
    };
  }, [selectedItems, contactsStorage, contactType, tokens.google_account_id]);

  useEffect(() => {
    const checkDBContacts = async _contacts => {
      if (tokens.google_account_id === undefined || !tokens.google_account_id) {
        return false;
      }

      let needSave = false;
      let contactsToSave = [];
      console.log(
        'checkDBContacts TEST',
        _contacts.length,
        contactType,
        tokens.google_account_id
      );

      const api = AxiosInstance({user, logout});
      let response = await api.get(
        `${Config.API_URL}/api/v1/user_contacts/stat`,
        {
          method: 'GET',
          mode: 'cors',
        }
      );

      if (
        response.data.message[tokens.google_account_id] !== undefined &&
        response.data.message[tokens.google_account_id][contactType] !==
          undefined
      ) {
        let last_modified = new Date(
          response.data.message[tokens.google_account_id][
            contactType
          ].last_modified
        );
        let now = new Date();
        let diff =
          (now.getTime() - last_modified.getTime()) / (1000 * 3600 * 24);
        if (
          diff > Config.CONTACTS_RELOAD_AFTER_DAYS ||
          _contacts.length >
            response.data.message[tokens.google_account_id][contactType]
              .total_cnt
        ) {
          console.log(`saveContactsToDB for ${contactType}`);
          // console.log(`diff: ${diff}`);
          console.log(
            response.data.message[tokens.google_account_id][contactType]
              .total_cnt,
            _contacts.length
          );
          needSave = true;
          // saveContactsToDB(_contacts, tokens.google_account_id, contactType, user, logout);
        }
      } else {
        if (_contacts.length) {
          console.log(
            `saveContactsToDB for ${contactType}, not founded in Stat`
          );
          needSave = true;
          // saveContactsToDB(_contacts, tokens.google_account_id, contactType, user, logout);
        }
      }

      if (needSave) {
        let phone = null;
        let email = null;

        _contacts.map(item => {
          phone = contactType === 'phone' ? item.phone : null;
          email = contactType === 'other' ? item.phone : null;
          contactsToSave.push({
            id: item.id,
            phone: phone,
            email: email,
            name: item.name,
          });
        });

        saveContactsToDB(
          contactsToSave,
          tokens.google_account_id,
          contactType,
          user,
          logout
        );
      }
    };

    if (contacts.length) {
      checkDBContacts(contacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts, tokens.google_account_id]);

  let currentAllSelected =
    search.length >= minSearchStrLen ? filteredSelected : allSelected;

  // расчет кол-ва отмеченных
  useEffect(() => {
    let checked = 0;
    contacts.map(item => {
      let key = item.id !== undefined ? item.id : false;

      if (key && selectedItems[key] !== undefined) {
        if (selectedItems[key].checked) {
          checked++;
        }
      } else if (key && selectedItems[key] === undefined) {
        if (currentAllSelected) {
          checked++;
        }
      }
      // console.log('key selectedItems[key]', key, selectedItems[key]);
    });
    setCheckedContacts(checked);
  }, [selectedItems, contacts, currentAllSelected, needUpdate]);

  useEffect(() => {
    // console.log('contacts useEffect', screenWidth);
    setPrevScreenWidth(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  return (
    <View style={commonStyles.container}>
      <Header navigation={navigation} route={route} />
      <View style={commonStyles.contentContainer}>
        <SegmentedButtons
          style={{
            width: wp('90%'),
            maxWidth: 600,
            marginTop: 20,
          }}
          theme={{
            colors: {
              secondaryContainer: theme.BLACK,
              onSecondaryContainer: theme.FONT_COLOR_WHITE,
            },
          }}
          value={contactType}
          onValueChange={value => {
            setContactType(value);
            contactsStorage =
              value === 'phone' ? 'WebContacts' : 'WebOtherContacts';
          }}
          buttons={[
            {
              value: 'phone',
              label: t('phone_book'),
            },
            {
              value: 'other',
              label: t('other_contacts'),
            },
          ]}
        />
        <View style={styles.headerButtons}>
          <View
            style={{
              ...styles.checkerGroup,
              flexDirection: screenWidth < 700 ? 'column' : 'row',
            }}>
            <CheckBox
              checked={
                search.length >= minSearchStrLen
                  ? filteredSelected === true || filteredSelected === 1
                  : allSelected === true || allSelected === 1
              }
              onPress={() => toggleAll()}
              title={t('all') + ` ${search}`}
              containerStyle={styles.headerCheckBox}
              checkedIcon={
                typeof currentAllSelected === 'boolean'
                  ? 'check-square-o'
                  : 'pencil-square-o'
              }
              uncheckedIcon={
                typeof currentAllSelected === 'boolean'
                  ? 'square-o'
                  : 'pencil-square-o'
              }
            />
            <Text style={styles.checkedContacts}>
              {t('selected')} {checkedContacts} {t('from')} {contacts.length}
            </Text>
          </View>
          <View
            style={{
              ...styles.btnGroup,
              flexDirection: screenWidth < 700 ? 'column' : 'row',
            }}>
            <Pressable
              onPress={() => {
                handleGoogleReload();
              }}
              style={styles.headerButton}>
              <Text style={styles.btnTextWhite}>{t('sync_google')}</Text>
            </Pressable>
            <Pressable
              onPress={() => {
                handleChangeAccount();
              }}
              style={styles.headerButton}>
              <Text style={{...styles.btnTextWhite, textAlign: 'center', width: '100%'}}>{t('change_account')}</Text>
            </Pressable>
            <Pressable
              onPress={() => {
                handleShowModal();
              }}
              style={styles.headerButton}>
              <Text style={styles.btnTextWhite}>{t('send')}</Text>
            </Pressable>
            <Pressable
              style={styles.headerButton}
              onPress={() => {
                // setScreenParams(null);
                navigation.navigate('Invitations');
              }}>
              <Text style={styles.btnTextWhite}>{t('close')}</Text>
            </Pressable>
          </View>
        </View>

        <SearchBar
          placeholder={t('start_type_name')}
          onChangeText={text => {
            setSearch(text);
            if (text.length) {
              searchContacts(text);
            } else {
              setContacts(cachedContacts);
              setVisibleData(cachedContacts.slice(0, batchSize));
            }
          }}
          onCancel={() => {
            setContacts(cachedContacts);
          }}
          lightTheme={true}
          value={search}
          containerStyle={styles.searchBarContainer}
          inputContainerStyle={styles.searchBarInput}
          leftIconContainerStyle={styles.searchBarLeftIcon}
          rightIconContainerStyle={styles.searchBarRightIcon}
        />

        <View style={styles.listContainer}>
          <FlatList
            // style={{flex: 1}}
            data={visibleData}
            renderItem={({item}) => (
              <MemContactItem
                contact={item}
                setSelectedItems={setSelectedItems}
                setNeedUpdate={setNeedUpdate}
                allContacts={contacts}
                selectedItems={selectedItems}
                allSelected={
                  search.length < minSearchStrLen
                    ? allSelected
                    : filteredSelected
                }
                setAllSelected={
                  search.length < minSearchStrLen
                    ? setAllSelected
                    : setFilteredSelected
                }
                isChecked={
                  selectedItems[item.id] !== undefined
                    ? selectedItems[item.id].checked
                    : currentAllSelected
                }
                isSended={
                  alreadySended[item.phone] !== undefined
                    ? alreadySended[item.phone]
                    : null
                }
              />
            )}
            ListEmptyComponent={<Text>{t('loading')} ...</Text>}
            keyExtractor={(item, index) => `${item.id}`}
            onEndReached={loadMoreData}
            onEndReachedThreshold={0.1}
          />
        </View>
      </View>
      <Footer navigation={navigation} />
      {isLoading === true && (
        <ActivityIndicator
          style={styles.activityIndicator}
          color="#0C090D"
          size="large"
        />
      )}
      {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      {info && <InfoModal info={info} setInfo={setInfo} />}
      <FormModal
        visible={modalVisible}
        onClose={handleCloseModal}
        onSubmit={handleFormSubmit}
        sendVia={contactType === 'phone' ? 'sms' : 'email'}
        msgType={'invite'}
      />
    </View>
  );
};

let screenHeight = Dimensions.get('window').height;



export default WebContacts;
