import React, {useContext, useEffect, useState} from 'react';
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  ActivityIndicator,
} from 'react-native';

import {TextInput, Button} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {AuthContext} from '../AuthProvider';

import Empty from './Empty';
import Header from './Header';
import Footer from './Footer';

import {default as commonStylesDesktop} from '../../styles/common';
import {default as commonStylesMobile} from '../../styles/common_mobile';
import {default as stylesF} from '../../styles/contacts_page';

const Contacts = ({navigation}) => {
  const {t, screenWidth, theme} = useContext(AuthContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);

  const styles = stylesF(hp, theme);
  let commonStyles =
  screenWidth < 1080
    ? commonStylesMobile(wp, hp, theme)
    : commonStylesDesktop(wp, hp, theme);

  const handleSubmit = () => {
    // Простая валидация
    if (!name || !email || !message) {
      alert('Please fill all the fields');
      return;
    }
    setIsLoading(true);
    // Здесь должен быть ваш код для отправки данных формы (например, через API)
    console.log({name, email, message});
    setIsLoading(false);
  };

  useEffect(() => {
    setPrevScreenWidth(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    AsyncStorage.getItem('userData').then(data => {
      if (data) {
        const userData = JSON.parse(data);
        setName(userData.name);
        setEmail(userData.email);
      }
    });
  }, []);

  return (
    <ScrollView contentContainerStyle={commonStyles.container}>
      <Header navigation={navigation} />

      <View style={styles.contentContainer}>
        <View style={styles.headContainer}>
          <Text style={styles.headText}>{t('contacts')}</Text>
        </View>
        <View style={styles.firstBlock}>
          <Text style={styles.paragraph}>{t('contacts_text_1')}</Text>
          <Text style={styles.paragraph}>{t('contacts_text_2')}</Text>
        </View>
        <View style={styles.secondBlock}>
          <Text style={styles.secondBlockText}>{t('contacts_text_3')}</Text>
        </View>
        <View style={styles.thirdBlock}>
          <Text style={styles.blockHeadText}>{t('email_text')}</Text>
          <Text style={styles.paragraph}>{t('contacts_text_4')}</Text>
        </View>
        <View style={styles.thirdBlock}>
          <Text style={styles.blockHeadText}>{t('feedback_form_text')}</Text>
          <Text style={styles.paragraph}>{t('contacts_text_5')}</Text>
        </View>
        <View style={styles.formBlock}>
          <View style={styles.formInputs}>
            <TextInput
              label={t('name')}
              value={name}
              onChangeText={setName}
              mode="outlined"
              style={styles.input}
            />
            <TextInput
              label="Email"
              value={email}
              onChangeText={setEmail}
              mode="outlined"
              keyboardType="email-address"
              style={styles.input}
            />
            <TextInput
              label={t('message')}
              value={message}
              onChangeText={setMessage}
              mode="outlined"
              multiline
              numberOfLines={5}
              style={styles.inputMessage}
            />
          </View>
          <Button
            mode="contained"
            onPress={handleSubmit}
            disabled={isLoading}
            style={styles.button}>
            {t('send')}
          </Button>
        </View>
        <View style={styles.thirdBlock}>
          <Text style={styles.blockHeadText}>Онлайн-чат</Text>
          <Text style={styles.paragraph}>
            Воспользуйтесь нашим онлайн-чатом, если вам требуется быстрая
            помощь. Наши операторы готовы ответить на ваши вопросы в режиме
            реального времени.
          </Text>
          <Text style={styles.paragraph}>
            Мы ценим ваше сотрудничество и стремимся предоставить
            высококачественную поддержку для каждого партнера. Будьте уверены,
            что мы приложим все усилия, чтобы помочь вам достичь успеха в рамках
            партнерской программы "Три".
          </Text>
        </View>
        <View style={styles.lastBlock}>
          <Text style={styles.lastBlockText}>
            Успехов вам и приятного заработка с партнерской программой "Три"!
          </Text>
        </View>
      </View>
      <Footer navigation={navigation} />
      {Platform.OS !== 'web' && <Empty />}
      {isLoading === true && (
        <ActivityIndicator
          style={styles.activityIndicator}
          color="#0C090D"
          size="large"
        />
      )}
    </ScrollView>
  );
};

export default Contacts;
