import { StyleSheet, Dimensions } from "react-native";

const screenHeight = Dimensions.get('window').height;

const styles = (wp, theme) => StyleSheet.create({
  listContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 1080,
    height: '80%',
    maxHeight: screenHeight,
  },
  content: {
    flex: 1,
  },
  spinner: {
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  inputStyle: {
    height: 40,
    borderColor: theme.BLACK,
    borderWidth: 1,
    textAlign: 'center',
  },
  initials: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    fontSize: 32,
    color: theme.FONT_COLOR_WHITE,
  },
  avatar: {
    borderRadius: 30,
    width: 55,
    height: 55,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactDetails: {},
  contactCon: {
    flex: 1,
    flexDirection: 'row',
    padding: 5,
    borderBottomWidth: 0.5,
    borderBottomColor: '#d9d9d9',
    width: 1080,
  },
  imgCon: {},
  placeholder: {
    width: 55,
    height: 55,
    borderRadius: 30,
    overflow: 'hidden',
    backgroundColor: '#d9d9d9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contactDat: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 5,
  },
  txt: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: 18,
  },
  name: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: 16,
  },
  phoneNumber: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_BLACK,
  },
  headerButtons: {
    marginTop: 10,
    flexDirection: 'row',
    heigth: 40,
    gap: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    wordWrap: '',
    width: '100%',
    maxWidth: 1080,
  },
  btnGroup: {
    gap: 5,
    marginRight: 5,
  },
  checkerGroup: {
    alignItems: 'center',
  },
  checkedContacts: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  headerButton: {
    height: 40,
    padding: 3,
    width: 110,
    borderWidth: 1,
    borderColor: '#000000',
    backgroundColor: theme.BLACK,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    textAlign: 'center',
    alignSelf: 'flex-end',
  },
  btnTextWhite: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_WHITE,
    fontSize: 16,
  },
  headerCheckBox: {
    height: 40,
    padding: 5,
    marginLeft: 20,
    width: 150,
    alignItems: 'left',
    justifyContent: 'center',
    // backgroundColor: 'rgb(242, 242, 242)',
    backgroundColor: 'white',
    alignSelf: 'flex-start',
  },
  searchBarContainer: {
    backgroundColor: 'rgb(242, 242, 242)',
    borderBottomColor: '#FFF',
    borderTopColor: '#FFF',
    width: '100%',
    maxWidth: 1080,
  },
  searchBarInput: {
    backgroundColor: '#FFF',
  },
  searchBarLeftIcon: {
    backgroundColor: '#FFF',
    fontFamily: 'font-awesome',
  },
  searchBarRightIcon: {
    backgroundColor: '#FFF',
  },
  loader: {
    marginTop: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iosContentHead: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
});

export default styles;