import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  memo,
} from 'react';
import {
  FlatList,
  Image,
  ActivityIndicator,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
} from 'react-native';
import {SegmentedButtons} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DeviceInfo from 'react-native-device-info';
import Contacts from 'react-native-contacts';
import {CheckBox, SearchBar} from 'react-native-elements';
import {PERMISSIONS, request} from 'react-native-permissions';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';
import {
  saveContactsToDB,
  saveDataToStorage,
  sendInviteToContacts,
} from './ContactsCommon';

import theme from '../../config/theme_config';

import FormModal from '../../components/common/FormModal';
import Header from '../../components/common/Header';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';

import {default as stylesF} from '../../styles/contacts';
// import Config from '../../config/config';

const saveContactsToStorage = async data => {
  try {
    await AsyncStorage.setItem('contacts', JSON.stringify(data));
    console.log('saveContactsToStorage');
  } catch (error) {
    console.log('saveContactsToStorage error: ', error);
  }
};

const getContactsFromStorage = async () => {
  try {
    let data = await AsyncStorage.getItem('contacts');
    // console.log(data);
    savedContacts = data !== null ? JSON.parse(data) : {};
    console.log('getContactsFromStorage ', Object.keys(savedContacts).length);
    return savedContacts;
  } catch (error) {
    console.log('getContactsFromStorage error: ', error);
    return {};
  }
};

const normalizePhone = phone => {
  let normPhone = phone.replace(/\D/g, '');
  if (normPhone.length < 12) {
    normPhone = '38' + normPhone;
  }
  return normPhone;
};

const checkSelectedStatus = (_contacts, _selectedItems, defaultSelected) => {
  console.log('checkSelectedStatus', defaultSelected);
  let checkedItems = 0;
  let uncheckedItems = 0;
  let itemsCnt = 0;

  _contacts.map(item => {
    let key = item.recordID !== undefined ? item.recordID : false;
    if (key && _selectedItems[key] !== undefined) {
      if (_selectedItems[key].checked) {
        checkedItems++;
      } else {
        uncheckedItems++;
      }
      itemsCnt++;
    } else if (key && _selectedItems[key] === undefined) {
      if (defaultSelected) {
        checkedItems++;
      } else {
        uncheckedItems++;
      }
      itemsCnt++;
    }
  });

  if (checkedItems === itemsCnt) {
    console.log(`allSelected: checked ${checkedItems} all ${itemsCnt}`);
    return true;
  } else if (uncheckedItems === itemsCnt) {
    console.log(`allUnchecked unchecked ${uncheckedItems} all ${itemsCnt}`);
    return false;
  } else {
    console.log(
      `partialSelected checked ${checkedItems} unchecked ${uncheckedItems} all ${itemsCnt}`
    );
    return defaultSelected === true || defaultSelected === 1 ? 1 : 2;
  }
};

const MemContactItem = memo(
  ({
    contact,
    allContacts,
    selectedItems,
    setSelectedItems,
    setNeedUpdate,
    isChecked,
    isSended,
    allSelected,
    setAllSelected,
  }) => {
    const {t, theme} = useContext(AuthContext);
    // console.log('RENDER CONTACT ', contact.recordID, isSended);
    // console.log('=======================XXXXX', isSended);
    const styles = stylesF(wp, theme);
    const formInitials = name => {
      if (!name) {
        return '';
      }
      let tmp = name.split(' ');
      if (tmp.length >= 2) {
        return tmp[0].substring(0, 1) + tmp[1].substring(0, 1);
      } else {
        return tmp[0].substring(0, 1);
      }
    };

    const handleCheckboxChange = (item, setSelectedItems, isChecked) => {
      let currentSelected;
      setSelectedItems(prevState => {
        let newState = prevState;
        newState[item.recordID] = item;
        newState[item.recordID].checked = !isChecked;
        return newState;
      });
      currentSelected = checkSelectedStatus(
        allContacts,
        selectedItems,
        allSelected
      );
      setAllSelected(currentSelected);
      setNeedUpdate(prevState => !prevState);
    };

    const IOSCheckbox = ({checked, onPress}) => (
      <TouchableOpacity
        onPress={onPress}
        style={{
          width: 24,
          height: 24,
          borderWidth: 1,
          borderColor: checked ? '#007AFF' : 'gray', // Змінено на синій колір, коли вибрано
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white', // Фон білий
          borderRadius: 4, // Закруглені кути
          ...Platform.select({
            ios: {
              marginHorizontal: 10,
            },
          }),
        }}>
        {checked ? (
          <Text style={{fontSize: 18, fontWeight: 'bold', color: '#007AFF'}}>
            ✓
          </Text> // Змінено колір на синій
        ) : null}
      </TouchableOpacity>
    );
    // при отображении чекбокса использовать значение из selectedItems
    return (
      <View
        style={{
          ...styles.contactCon,
          ...Platform.select({
            ios: {
              justifyContent: 'center',
              alignItems: 'center',
            },
          }),
        }}>
        {Platform.OS === 'ios' ? (
          <IOSCheckbox
            checked={isChecked}
            onPress={() => {
              handleCheckboxChange(contact, setSelectedItems, isChecked);
            }}
          />
        ) : (
          <CheckBox
            checked={isChecked}
            onPress={() => {
              handleCheckboxChange(contact, setSelectedItems, isChecked);
            }}
          />
        )}

        <View>
          {contact.hasThumbnail ? (
            <Image
              source={{uri: contact.thumbnailPath}}
              style={styles.avatar}
            />
          ) : (
            <View style={styles.placeholder}>
              <Text style={styles.txt}>
                {contact.displayName && formInitials(contact.displayName)}
              </Text>
            </View>
          )}
        </View>

        <View style={styles.contactDat}>
          <Text style={styles.name}>
            {Platform.OS === 'ios'
              ? contact.givenName || t('no_name')
              : contact.displayName}
            {isSended !== null &&
              (isSended ? (
                <Ionicons name="checkmark" size={15} color="green" />
              ) : (
                <Ionicons name="checkmark" size={15} color="yellow" />
              ))}
          </Text>
          <Text style={styles.phoneNumber}>{contact.phone}</Text>
        </View>
      </View>
    );
  }
);

const batchSize = 10; // Размер порции данных
let savedContacts = {};

const ContactList = ({
  navigation,
  route,
}) => {
  const {user, logout, t, addHistory, Config, theme} = useContext(AuthContext);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleData, setVisibleData] = useState([]);
  const [selectedItems, setSelectedItems] = useState({}); // отмеченные контакты
  const [allSelected, setAllSelected] = useState(true);
  const [filteredSelected, setFilteredSelected] = useState(false);
  const [alreadySended, setAlreadySended] = useState({});
  const [needUpdate, setNeedUpdate] = useState(false);
  const [search, setSearch] = useState('');
  const [checkedContacts, setCheckedContacts] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [contactType, setContactType] = useState('phone');
  const [deviceId, setDeviceId] = useState('');

  const styles = stylesF(wp, theme);

  const checkSendRules = (
    _contacts,
    _selectedItems,
    defaultSelected
  ) => {
    let contactsToSend = [];
    // setIsLoading(true);
    _contacts.map(item => {
      let key = item.recordID !== undefined ? item.recordID : false;

      if (key) {
        if (_selectedItems[key] !== undefined) {
          if (_selectedItems[key].checked) {
            if (contactType === 'phone') {
              contactsToSend.push({displayName: item.displayName, phone: item.phone});
            } else {
              contactsToSend.push({displayName: item.displayName, email: item.phone});
            }
          }
        } else if (_selectedItems[key] === undefined) {
          if (defaultSelected) {
            if (contactType === 'phone') {
              contactsToSend.push({displayName: item.displayName, phone: item.phone});
            } else {
              contactsToSend.push({displayName: item.displayName, email: item.phone});
            }
          }
        }
      }
    });
    // console.log(contactsToSend);
    if (!contactsToSend.length) {
      setIsError(t('error_empty_choice'));
      setIsLoading(false);
      return false;
    }
    console.log('contacts to send: ', contactsToSend);

    let maxMessages =
      contactType === 'phone' ? Config.MAX_SMS : Config.MAX_EMAILS;
    if (contactsToSend.length > maxMessages) {
      setIsError(
        t('error_max_send') + ` ${maxMessages} ` + t('of_invitations')
      );
      setIsLoading(false);
      return false;
    }

    if (Config.ENV_NAME === 'dev') {
      contactsToSend = contactsToSend.slice(0, maxMessages);
    }

    return contactsToSend;
  }

  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = (formData) => {
    // Обработка данных формы
    console.log('Form submitted', formData);
    setIsLoading(true);

    let _contactsToSend = checkSendRules(
      contacts,
      selectedItems,
      all_selected_check
    );

    if (_contactsToSend !== false){
      sendToSelectedContacts(_contactsToSend, formData);
    } else {
      setIsError(t('unknown_error'));
    }

    // setIsLoading(false);
  };

  const sendToSelectedContacts = async (contactsToSend, msgFormData) => {
    if (msgFormData.message === undefined || !msgFormData.length) {
      await sendInviteToContacts(
        contactsToSend,
        setIsError,
        setInfo,
        setIsLoading,
        user,
        logout,
        t,
        msgFormData
      );

      let _sended = alreadySended;
      contactsToSend.map(item => {
        _sended[item.phone] = false;
      });
      setAlreadySended(_sended);
    } else {
      setIsError(t('empty_message'));
    }
  };
  const toggleAll = async () => {
    if (search) {
      visibleData.map(item => {
        setSelectedItems(prevState => {
          const newState = prevState;
          newState[item.recordID] = item;
          newState[item.recordID].checked = !filteredSelected;
          return newState;
        });
      });
      setFilteredSelected(!filteredSelected);
    } else {
      contacts.map(item => {
        setSelectedItems(prevState => {
          const newState = prevState;
          newState[item.recordID] = item;
          newState[item.recordID].checked = !allSelected;
          return newState;
        });
      });
      await AsyncStorage.setItem('allSelected', JSON.stringify(!allSelected));
      setAllSelected(!allSelected);
    }
  };

  const loadMoreData = () => {
    // Вычислите следующую порцию данных на основе текущего состояния
    const startIndex = visibleData.length;
    const endIndex =
      startIndex + batchSize <= contacts.length
        ? startIndex + batchSize
        : contacts.length;
    console.log(`loadMoreData from ${startIndex} to ${endIndex}`);
    const nextBatch = contacts.slice(startIndex, endIndex);
    setVisibleData(prevData => [...prevData, ...nextBatch]);
  };

  const proccessContacts = (data) => {
    let filtered_contacts = [];
    let start = new Date();

    data.sort(function (a, b) {
      if (!a.displayName || !b.displayName) return -1;
      if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) return -1;
      if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) return 1;
      return 0;
    });
    console.log('finished sorting contacts', (new Date() - start) / 1000);
    /*
      alreadySent - признак отправки приглашения контакту.
      0 - не отправляли или не определено
      1 - отправляли, не подтверждено контактом
      2 - отправляли, подтверждено контактом
     */
    data
      .filter(
        cont =>
          cont.phoneNumbers.length > 0 &&
          cont.phoneNumbers[0].number.length >= 10
      )
      .map((cont, index) => {
        filtered_contacts.push({
          index: index,
          recordID: cont.recordID,
          givenName: cont.givenName,
          displayName: cont.displayName,
          phone: cont.phoneNumbers[0]?.number,
          hasThumbnail: cont.hasThumbnail,
          thumbnailPath: cont.thumbnailPath,
          // checked: checked,
          // alreadySent: alreadySent
        });
        // alreadySended[phone] = false;
      });
    console.log('finished proccessed contacts', (new Date() - start) / 1000);
    return filtered_contacts;
  };

  const filter = useCallback(
    async text => {
      setSearch(text);
      setVisibleData([]);
      setIsLoading(true);
      console.log('start');
      let start = new Date();
      let end = new Date();
      let _contacts = [];

      if (!text) {
        console.log('start filter data getAll');
        await Contacts.getAll()
          .then(data => {
            // work with contacts
            end = new Date() - start;
            console.log('DATA', data.length);

            _contacts = proccessContacts(data);

            if (Object.keys(savedContacts).length) {
              // есть сохраненные ранее данные про отметки контактов
              setSelectedItems(savedContacts);
            }
            setContacts(_contacts);
            setVisibleData(_contacts.slice(0, batchSize));
          })
          .catch(e => {
            console.log('Error', e);
          });
      }

      if (text.length >= 3) {
        start = new Date();
        // setFilteredSelected(allSelected);
        console.log('start filter data getContactsMatchingString');
        await Contacts.getContactsMatchingString(text)
          .then(data => {
            end = new Date() - start;
            console.log(
              `filter data getContactsMatchingString: ${data.length}, time: ${
                end / 1000
              } sec`
            );
            if (Platform.OS === 'ios') {
              const contactNames = data.map(
                contact => contact.givenName || t('no_name')
              );
            }
            _contacts = proccessContacts(data);
            setContacts(_contacts);
            setVisibleData(_contacts.slice(0, batchSize));
          })
          .catch(e => {
            console.log('Error', e);
          });
      }

      end = new Date() - start;
      console.log('end:', end / 1000);
      setIsLoading(false);
    },
    [t]
  );

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  useEffect(() => {
    const loadContactsFromStore = async () => {
      await getContactsFromStorage().then(data => {
        savedContacts = data;
      });
    };

    const loadAlreadySendedContacts = async () => {
      const api = AxiosInstance({user, logout});
      await api('/api/v1/invitations/all', {
        method: 'GET',
        mode: 'cors',
      })
        .then(response => {
          if (response.data.code === 200) {
            let invitations = response.data.message;
            if (Object.keys(alreadySended).length < invitations.length) {
              let _alreadySended = {};
              invitations.map(item => {
                if (item.phone) {
                  _alreadySended[normalizePhone(item.phone)] =
                    parseInt(item.status) === 1 ? true : false;
                }
              });
              setAlreadySended(_alreadySended);
            }
          } else {
            setIsError(t('server_error'));
            console.log('User invitations list error:', response.data.message);
          }
        })
        .catch(error => {
          console.log('User invitations list error:', error);
          setIsError(t('server_error'));
        });
    };

    const loadContacts = () => {
      setIsLoading(true);

      let permission;

      if (Platform.OS === 'android') {
        permission = PERMISSIONS.ANDROID.READ_CONTACTS;
      } else if (Platform.OS === 'ios') {
        permission = PERMISSIONS.IOS.CONTACTS;
      }

      const requestConfig = {
        title: 'Contacts',
        message: 'This app would like to view your contacts.',
        buttonPositive: 'Please accept',
      };

      request(permission, requestConfig)
        .then(res => {
          console.log('Permission: ', res);
          if (res === 'granted') {
            loadContactsFromStore();
            filter('');
          } else {
            console.log('Permissions not accepted', res);
            setIsError(t('contacts_no_available'));
          }
        })
        .catch(error => {
          setIsLoading(false);
          console.error('Permission error: ', error);
        });
    };

    loadAlreadySendedContacts();
    loadContacts();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, user, logout]);

  useEffect(() => {
    const saveSelectedItems = async () => {
      await saveContactsToStorage(selectedItems);
    };
    return () => {
      saveSelectedItems();
    };
  }, [selectedItems]);

  useEffect(() => {
    const saveContactType = async () => {
      await saveDataToStorage('phone', 'contactType');
      console.log('saving contactType phone');
    };

    // Получаем уникальный идентификатор устройства
    const fetchDeviceId = async () => {
      try {
        const uniqueId = await DeviceInfo.getUniqueId();
        console.log('DEVICE ID', uniqueId);
        setDeviceId(uniqueId);
      } catch (error) {
        console.error('Error getting unique device ID:', error);
      }
    };

    fetchDeviceId();;

    return () => {
      saveContactType();
    };
  }, []);

  useEffect(() => {
    const checkDBContacts = async _contacts => {
      let needSave = false;
      let contactsToSave = [];
      console.log('checkDBContacts ', _contacts.length, 'phone');

      const api = AxiosInstance({user, logout});
      let response = await api.get(
        `${Config.API_URL}/api/v1/user_contacts/stat`,
        {
          method: 'GET',
          mode: 'cors',
        }
      );

      console.log('RESPONSE FROM DB CONTACTS FOR ', `device_${deviceId}`);
      console.log(response.data.message);

      if (
        response.data.message[`device_${deviceId}`] !== undefined &&
        response.data.message[`device_${deviceId}`]['phone'] !== undefined
      ) {
        let last_modified = new Date(
          response.data.message[`device_${deviceId}`]['phone'].last_modified
        );
        let now = new Date();
        let diff =
          (now.getTime() - last_modified.getTime()) / (1000 * 3600 * 24);
        if (
          diff > 7 ||
          _contacts.length > response.data.message[`device_${deviceId}`]['phone'].total_cnt
        ) {
          console.log(`checkDBContacts for phone`);
          console.log(
            response.data.message[`device_${deviceId}`]['phone'].total_cnt,
            _contacts.length
          );
          needSave = true;
        }
      } else {
        if (_contacts.length) {
          console.log(`checkDBContacts for phone, not founded in Stat`);
          needSave = true;
        }
      }

      if (needSave) {
        _contacts.map(item => {
          contactsToSave.push({
            id: item.recordID,
            phone: item.phone,
            email: null,
            name: item.displayName,
          });
        });
        console.log('SAVE TO DB CONTACTS FOR ', `device_${deviceId}`);
        saveContactsToDB(contactsToSave, `device_${deviceId}`, 'phone', user, logout);
      }
    };

    if (contacts.length) {
      try {
        checkDBContacts(contacts);
      } catch (error) {
        console.log('saving contacts to db error:', error);
      }
    }
  }, [contacts, user, logout, deviceId, Config.API_URL]);

  useEffect(() => {
    let currentSelected;
    if (search.length >= 3) {
      currentSelected = checkSelectedStatus(
        contacts,
        selectedItems,
        filteredSelected
      );

      setFilteredSelected(currentSelected);
    } else {
      currentSelected = checkSelectedStatus(
        contacts,
        selectedItems,
        allSelected
      );

      setAllSelected(currentSelected);
    }
  }, [contacts, selectedItems, allSelected, filteredSelected, search]);

  let all_selected_check = search ? filteredSelected : allSelected;
  useEffect(() => {
    if (all_selected_check === true) {
      setCheckedContacts(contacts.length);
    } else if (all_selected_check === false) {
      setCheckedContacts(0);
    } else {
      let checked = 0;
      contacts.map(item => {
        let key = item.recordID;

        if (key && selectedItems[key] !== undefined) {
          if (selectedItems[key].checked) {
            checked++;
          }
        } else if (key && selectedItems[key] === undefined) {
          if (all_selected_check) {
            checked++;
          }
        }
      });
      setCheckedContacts(checked);
    }
  }, [selectedItems, contacts, all_selected_check, needUpdate]);

  return (
    <SafeAreaView style={styles.container}>
      <Header navigation={navigation} route={route} />
      <View
        style={[
          styles.contentHead,
          Platform.OS === 'ios' ? styles.iosContentHead : {},
        ]}>
        <SegmentedButtons
          style={{
            width: wp('90%'),
            maxWidth: 600,
          }}
          theme={{
            colors: {
              secondaryContainer: theme.BLACK,
              onSecondaryContainer: theme.FONT_COLOR_WHITE,
            },
          }}
          value={contactType}
          onValueChange={value => {
            if (value === 'other') {
              navigation.navigate('OtherContactsList');
            };
          }}
          buttons={[
            {
              value: 'phone',
              label: t('phone_book'),
            },
            {
              value: 'other',
              label: t('other_contacts'),
              // style: {paddingLeft: 13, paddingRight: 13},
            },
          ]}
        />
      </View>

      <View style={styles.headerButtons}>
        {Platform.OS === 'ios' ? (
          <View style={{marginRight: 25}}>
            <CheckBox
              checked={search ? filteredSelected : allSelected}
              onPress={() => toggleAll()}
              title={t('all') + `${search}`}
              checkedIcon={
                typeof all_selected_check === 'boolean' ? (
                  <Ionicons name="checkmark" size={24} color="#007aff" /> // Жирний блакитний колір для іконки галочки
                ) : (
                  <Ionicons name="create" size={24} color="#007aff" />
                ) // Жирний блакитний колір для іконки олівця
              }
              uncheckedIcon={
                typeof all_selected_check === 'boolean' ? (
                  <Ionicons name="square-outline" size={24} color="grey" /> // Колір для іконки квадрату
                ) : (
                  <Ionicons name="create" size={24} color="#007aff" />
                ) // Жирний блакитний колір для іконки олівця
              }
            />
          </View>
        ) : (
          <CheckBox
            checked={search ? filteredSelected : allSelected}
            onPress={() => toggleAll()}
            title={t('all') + ` ${search}`}
            containerStyle={styles.headerCheckBox}
            checkedIcon={
              typeof all_selected_check === 'boolean'
                ? 'check-square-o'
                : 'pencil-square-o'
            }
            uncheckedIcon={
              typeof all_selected_check === 'boolean'
                ? 'square-o'
                : 'pencil-square-o'
            }
          />
        )}
        <Text style={styles.checkedContacts}>
          {checkedContacts} {t('from')} {contacts.length}
        </Text>
        <Pressable
          onPress={() => {
            // sendToSelectedContacts(search ? filteredSelected : allSelected);
            handleShowModal();
          }}
          style={styles.headerButton}>
          <Text style={styles.buttonText}>{t('send')}</Text>
        </Pressable>
        <Pressable
          style={styles.headerButton}
          onPress={() => {
            navigation.navigate('Invitations');
          }}>
          <Text style={styles.buttonText}>{t('close')}</Text>
        </Pressable>
      </View>
      <View>
        <SearchBar
          placeholder={t('start_type_name')}
          onChangeText={text => {
            filter(text);
          }}
          onCancel={() => {
            filter('');
          }}
          lightTheme={true}
          value={search}
          containerStyle={styles.searchBarContainer}
          inputContainerStyle={styles.searchBarInput}
          leftIconContainerStyle={styles.searchBarLeftIcon}
          rightIconContainerStyle={styles.searchBarRightIcon}
        />
      </View>
      <View style={styles.container}>
        <FlatList
          data={visibleData}
          renderItem={({item}) => (
            <MemContactItem
              contact={item}
              setSelectedItems={setSelectedItems}
              setNeedUpdate={setNeedUpdate}
              // extraData={selectedItems}
              // updateContactsInStorage={updateContactsInStorage}
              allContacts={contacts}
              selectedItems={selectedItems}
              allSelected={search.length < 3 ? allSelected : filteredSelected}
              setAllSelected={
                search.length < 3 ? setAllSelected : setFilteredSelected
              }
              isChecked={
                selectedItems[item.recordID] !== undefined
                  ? selectedItems[item.recordID].checked
                  : allSelected
              }
              isSended={
                alreadySended[`${normalizePhone(item.phone)}`] !== undefined
                  ? alreadySended[`${normalizePhone(item.phone)}`]
                  : null
              }
              key={item.index}
            />
          )}
          ListEmptyComponent={<Text>{t('loading')} ...</Text>}
          keyExtractor={(item, index) => `${item.recordID}-${index}`}
          onEndReached={loadMoreData}
          onEndReachedThreshold={0.1}
          // ref={flatListRef}
        />
      </View>
      {isLoading === true && <ActivityIndicator size="medium" style={styles.activityIndicator}/>}
      {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      {info && <InfoModal info={info} setInfo={setInfo} />}
      <FormModal
        visible={modalVisible}
        onClose={handleCloseModal}
        onSubmit={handleFormSubmit}
        sendVia={'sms'}
        msgType={'invite'}
      />
    </SafeAreaView>
  );
};

export default ContactList;
