import { StyleSheet } from "react-native";

const styles = (hp, theme) => StyleSheet.create({
  container: {
    // flex: 1,
    height: hp('100%'),
    alignItems: 'center',
    justifyContent: 'center',
    // flexDirection: 'column',
    backgroundColor: theme.WHITE,
  },

  contentContainer: {
    // flex: 1,
    width: '100%',
    // height: hp('100%'),
    maxWidth: 1080,
    paddingHorizontal: '6%',
  },
  headContainer: {
    width: '100%',
    maxWidth: 500,
    // heigh: 'auto',
    paddingVertical: '6%',
  },
  headText: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: 40,
    lineHeight: 40,
    color: theme.FONT_COLOR_BLACK,
  },
  paragraph: {
    marginTop: 15,
    display: 'inline-block',
  },
  textBlockItem: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: 16,
    color: theme.FONT_COLOR_BLACK,
  },
  firstBlock: {
    marginTop: 50,
    width: '100%',
    maxWidth: 540,
  },
  secondBlock: {
    width: '100%',
    maxWidth: 1080,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 30,
    marginTop: 50,
  },
  secondBlockItem: {
    width: '80%',
    maxWidth: 330,
    marginTop: 40,
  },
  thirdBlock: {
    marginTop: 150,
    width: '100%',
    maxWidth: 540,
  },
  lastBlock: {
    marginTop: 100,
    width: '100%',
    maxWidth: 800,
    marginBottom: 100,
  },
  lastBlockText: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 40,
  },
  blockHeadText: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 24,
    marginBottom: 35,
  },
});

export default styles;