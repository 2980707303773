import * as React from 'react';
import {useEffect, useContext, useState, useCallback} from 'react';
import {View, StyleSheet} from 'react-native';
import {Text, HStack, Radio} from 'native-base';
import {AuthContext} from '../../components/AuthProvider';
import axios from 'axios';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {
  Card,
  Avatar,
  Badge,
  SegmentedButtons,
  DataTable,
} from 'react-native-paper';
import Header from '../../components/common/AdminHeader';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';

import commonStylesF from '../../styles/common';
import listStylesF from '../../styles/list';

const UsersHeader = () => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('id')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('name')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('phone')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('trios')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('sum')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('reward_sum')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const UserItem = ({item}) => {
  const {loginAsUser, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  return (
    <DataTable.Row
      key={item.id}
      onPress={() => {
        loginAsUser(item);
      }}
      style={{...listStyles.itemRow, backgroundColor: item.activated > 0 ? theme.BLUE : theme.LIGHT_BLUE}}
    >
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>{item.id}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>{item.name}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>{item.phone}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>
           {item.trios_cnt ? item.trios_cnt : 0}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>
          {item.trios_total_sum ? item.trios_total_sum : 0}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>
          {item.trios_reward_sum ? item.trios_reward_sum : 0}
        </Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const AdminUsers = ({navigation}) => {
  const {user, Config, theme, t} = useContext(AuthContext);
  const commonStyles = commonStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  const [isError, setIsError] = useState(false);
  const [active, setActive] = useState(1);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({currentPage: 1});
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);

  const getData = useCallback(async () => {
    setIsLoading(true);
    await axios(`${Config.API_URL}/api/v1/admin/users`, {
      method: 'GET',
      mode: 'cors',
      params: {
        page: currentPage,
        active: active,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.Authorization,
      },
    })
      .then(response => {
        if (response.status === 200) {
          let _from = 0;
          let _to = 0;

          setUsers(response.data.message.data);
          setPagination(response.data.message.pagination);
          _from =
            response.data.message.pagination.totalRows > 0
              ? (currentPage - 1) * response.data.message.pagination.perPage + 1
              : 0;
          _to =
            response.data.message.pagination.totalRows >
            currentPage * response.data.message.pagination.perPage
              ? currentPage * response.data.message.pagination.perPage
              : response.data.message.pagination.totalRows;
          setFrom(_from);
          setTo(_to);
        } else if (response.status === 'error') {
          console.log(response);
          setIsError(response.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('AdminUsers error.response', error.response);
          setIsError(error.response.data.message);
        } else if (error.request) {
          console.log('AdminUsers error.request', error.request);
          setIsError(JSON.stringify(error.request));
        } else {
          console.log('AdminUsers unknown error', error);
          setIsError(JSON.stringify(error));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, active, currentPage, Config]);


  useEffect(() => {
    getData();
  }, [getData]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View style={commonStyles.container}>
        <Header navigation={navigation} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.contentHead}>
            <Text style={commonStyles.contentHeadText}>{t('admin_users')}</Text>
          </View>

          <SegmentedButtons
            style={{
              width: wp('90%'),
              maxWidth: 600,
              marginTop: 20,
            }}
            theme={{
              colors: {
                secondaryContainer: theme.BLACK,
                onSecondaryContainer: theme.FONT_COLOR_WHITE,
              },
            }}
            value={active}
            onValueChange={value => {
              setCurrentPage(1);
              setActive(value);
            }}
            buttons={[
              {
                value: 1,
                label: t('admin_active_users'),
              },
              {
                value: 0,
                label: t('all'),
              },
            ]}
          />

          <DataTable style={listStyles.dataTable}>
            <UsersHeader />
            {users.map(item => (
              <UserItem item={item} />
            ))}
            <DataTable.Pagination
              page={currentPage - 1}
              numberOfPages={Math.ceil(pagination.totalPages)}
              onPageChange={page => setCurrentPage(page + 1)}
              label={`${from}-${to} of ${pagination.totalRows}`}
              numberOfItemsPerPage={pagination.perPage}
              showFastPaginationControls
            />
          </DataTable>

        </View>
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  row: {
    height: 40,
    padding: 10,
  },
  head_row: {
    height: 60,
    padding: 10,
  },
  error: {
    height: 30,
    color: 'red',
  },
  btn: {
    padding: 10,
    backgroundColor: 'rgb(8, 145, 178)',
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    padding: 10,
    backgroundColor: 'rgb(8, 145, 66)',
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnText: {
    color: 'white',
    fontSize: 15,
    textAlign: 'center',
  },
});

export default AdminUsers;
